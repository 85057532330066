import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';

import { MaterialProgressDialogComponent } from './components/material-progress-dialog/material-progress-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BackendService } from './services/backend.service';
import { WindowService } from './services/window.service';
import { LocalAuthService } from './services/local-auth.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DataService } from './services/data.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { FormBuilderService } from './services/form-builder.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialDesignModule } from '../app-material-design.module';
import { OrderItemDetailDialogComponent } from './components/order-item-detail-dialog/order-item-detail-dialog.component';
import {NgxPrintModule} from 'ngx-print';
import { QRCodeModule } from 'angularx-qrcode';
import { AssignDispatchDialogComponent } from './components/assign-dispatch-dialog/assign-dispatch-dialog.component';
import { FoodMenuDialogComponent } from './components/food-menu-dialog/food-menu-dialog.component';
import { DispatchRiderDialogComponent } from './components/dispatch-rider-dialog/dispatch-rider-dialog.component';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { ExpensesDialogComponent } from './components/expenses-dialog/expenses-dialog.component';
import { GraphComponent } from './components/graph/graph.component';
import { AccountDialogComponent } from './components/account-dialog/account-dialog.component';
import { CareersDialogComponent } from './components/careers-dialog/careers-dialog.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    MaterialProgressDialogComponent,
    ConfirmDialogComponent,
    InputDialogComponent,
    OrderItemDetailDialogComponent,
    AssignDispatchDialogComponent,
    FoodMenuDialogComponent,
    DispatchRiderDialogComponent,
    NotificationDialogComponent,
    ExpensesDialogComponent,
    AccountDialogComponent,
    CareersDialogComponent,
    // GraphComponent
    //TruncatePipe
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatButtonModule,
    AppMaterialDesignModule,
    NgxPrintModule,
    QRCodeModule,
    AngularEditorModule,
    // ChartsModule
  ],
  entryComponents:[
    MaterialProgressDialogComponent,
    ConfirmDialogComponent,
    InputDialogComponent,
    OrderItemDetailDialogComponent,
    AssignDispatchDialogComponent,
    FoodMenuDialogComponent,
  ],
  providers:[
    BackendService,
    WindowService,
    LocalAuthService,
    DataService,
    FormBuilderService,
  ]
})
export class SharedModule { }
