import { ReturnStatement } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { takeUntil, timestamp } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { ExpensesDialogComponent } from 'src/app/shared/components/expenses-dialog/expenses-dialog.component';
import { Sales } from 'src/app/shared/models/sales.interface';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { DialogButton } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit, OnDestroy{
  public isLoading = false;
  private unSubscriptioNotifier = new Subject();

  public itemList = new Array<IENS>();
  public itemList$: Observable<IENS[]> = new Observable<IENS[]>();
  public itemListBehaviour: BehaviorSubject<IENS[]>;



  public totalExpenses: number = 0;  
  public totalSales: number = 0;

  options: Option[] = [
    {value:"all-transactions", viewValue: "All transactions"},
    {value:"this-year", viewValue: "This year"},
    {value:"this-month", viewValue: "This month"},
    {value:"this-week", viewValue: "This week"},
    {value:"today", viewValue: "Today"}
  ]

  salesOptions: Option[] = [
    {value:"this-week", viewValue: "This week"},
    {value:"today", viewValue: "Today"}
  ]

  selected = 'all-transactions';
  selectedSales = 'this-week'
  selectedExpense = 'this-week'


  constructor( private router: Router,
    private backend: BackendService,
    private firebaseAuth: AngularFireAuth,
    private appMaterialComponent: AppMaterialDesignModule,
    public dataSource: DataService) {
      this.itemListBehaviour = new BehaviorSubject([{}]);
      this.itemList$ =this.itemListBehaviour.asObservable();
      
     }

  ngOnInit(): void {
    this.getExpensesAndSalesList()
  }

  
  public getExpensesAndSalesList() {
    this.isLoading = true
    let observers: Observable<any> = combineLatest([
      this.backend.getExpensesList(),
      this.backend.getSalesList()
    ])
    
    observers.pipe(takeUntil(this.unSubscriptioNotifier))
    .subscribe(actionArray => {
      this.isLoading = false
      let temp = [...actionArray[0], ...actionArray[1]]
      let tempSalesAndExpenseItems = temp.map((item: { payload: { doc: { id: any; data: () => IENS; }; }; }) => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data(),
        } as IENS
      }) 
        this.sortByDate(tempSalesAndExpenseItems)
        this.itemList = tempSalesAndExpenseItems;
        this.itemListBehaviour.next(this.itemList);

        let  tempExpensesItems= actionArray[0].map((item: { payload: { doc: { id: any; data: () => IENS; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as IENS
        })      
        this.totalExpenses = this.sum(tempExpensesItems)  

  
        let tempSalesItems = actionArray[1].map((item: { payload: { doc: { id: any; data: () => Sales; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as Sales
        })
        this.totalSales = this.sum(tempSalesItems)
  
        // this.sumByMonth(tempSalesItems)
      // console.log(tempSalesItems, "expenses and sales");
    })
  }

  public getExpensesAndSalesListByDuration(startAt: string, endAt: string) {
    this.isLoading = true
    let observers: Observable<any> = combineLatest([
      this.backend.getExpensesListByDuration(startAt, endAt),
      this.backend.getSalesListByDuration(startAt, endAt)
    ])
    
    observers.pipe(takeUntil(this.unSubscriptioNotifier))
    .subscribe(actionArray => {
      this.isLoading = false
      let temp = [...actionArray[0], ...actionArray[1]]
      let tempSalesAndExpenseItems = temp.map((item: { payload: { doc: { id: any; data: () => IENS; }; }; }) => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data(),
        } as IENS
      }) 
        this.sortByDate(tempSalesAndExpenseItems)
        this.itemList = tempSalesAndExpenseItems;
        this.itemListBehaviour.next(this.itemList);

        let  tempExpensesItems= actionArray[0].map((item: { payload: { doc: { id: any; data: () => IENS; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as IENS
        })      
  
        let tempSalesItems = actionArray[1].map((item: { payload: { doc: { id: any; data: () => Sales; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as Sales
        })

        console.log(tempSalesItems)
    })
  }



  public openExpensesDialog(expenses: Sales) {
    this.dataSource.setData(expenses)
    this.appMaterialComponent.openDialog(ExpensesDialogComponent, {
      width: '450px',
      title: 'Add Expenses',
      message: 'Add expenses',
      data: expenses
    }).pipe(takeUntil(this.unSubscriptioNotifier),
    ).subscribe({
      next: (result) => {
        if (result.button == DialogButton.ok) {
           this.getExpensesAndSalesList() 
        }
      },
      error:(err)  => {
        console.log(err)
      }
    })
  }

  public signOut() {
    localStorage.setItem('account', null)
    //this.firebaseAuth.signOut().then(r => { });
    this.router.navigate([`account/login`], { queryParams: {}, skipLocationChange: false });
  }

  public sum(list){
    let total = 0 
    for (let i=0; i<list.length; i++){   
        total +=  list[i].amount
    }
    return total;
  }

  public sumByMonth(ls:IENS[]){
    let arr: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] 
    for(let i=0; i < ls.length; i++){
      let m = new Date(ls[i].date).getMonth() 
      if (m !== undefined) { 
        arr[m] += ls[i].amount
      }
    }

    return arr

  }

  public filterHandler(event){
    console.log(event.value)
    this.selected = event.value
    switch(event.value){
      case "today":
        let startAt = new Date("Sun Jun 05 2022 00:00:00 GMT+0000 ").toDateString()
        let endAt = new Date().toString()
        this.getExpensesAndSalesListByDuration(startAt, endAt)
        break
      case "all-transactions":
      case "this-week":
      case "this-month":
      case "this-year":
        this.getExpensesAndSalesList()
        break
    }
  }


  public sortByDate = (arr: any[]) => {
    const sorter = (a, b) => {
       return new Date(a.date).getTime() - new Date(b.date).getTime();
    }
    arr.sort(sorter)
    arr.reverse()
 };

  formatDate(date){    
    let dt = new Date(date)
    return dt.toLocaleString().split(',').join(' at ')
  }

  formatAmount(amount): string {
    return this.dataSource.formatAmount(amount)
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }

}

interface IENS {
  id?:string
  reference?: string
  status?: string
  amount?: number
  method?: string,
  currency?: string
  transaction_fees?: number
  customer_email?: string
  date?:  string
  type?: string
  description?:string   
}

interface Option {
  value: string,
  viewValue: string
}