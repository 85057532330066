<div class="container" style="height: 100vh;">
    <div class="d-flex justify-content-between mt-3 mb-3">
        <h3 class="title-text">Dispatch Orders Delivered</h3>
        <!-- <h3>{{account?.name}}</h3> -->
        <div>
            <!-- <button (click)="openNofiticationDialog({})" style="font-size: 15px; font-family: avenir-roman; background-color: #FFD36A;" class="btn" mat-flat-button>Add new rider</button> -->
            <button (click)="signOut()" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Sign Out</button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md">
            <div class="table-responsive bg-white" style="max-height: 620px;">
                <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
                    <thead>
                        <tr>
                            <th lass="ml-2">
                                <!-- <input class="ml-2" type="checkbox" [checked]="isAllOrderItemReadyToDispatchListCheckBoxChecked()" (change)="checkAllOrderItemReadyToDispatchListCheckBox($event)"> -->
                            </th>
                            <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                                Order number
                            </th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Customer name</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Food name</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Quantity</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Amount</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Location</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Dispatch Rider</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="animate__animated animate__fadeIn" *ngFor="let orderItem of orderItemDispatchList$ | async; let i = index" style="border: .5px solid #EDEDED; font-size: 15px;">
                            <!-- <td class="align-middle">
                                <mat-checkbox class="ml-2" color="primary" style="font-family: avenir-roman;" value="{{orderItem.id}}" [(ngModel)]="orderItemReadyToDispatchList[i].checked">
                                </mat-checkbox>
                            </td> -->
                            <td class="ml-2"></td>
                            <td class="align-middle">{{orderItem.id}}</td>
                            <td class="align-middle">{{orderItem.username}}</td>
                            <td class="align-middle">{{orderItem.food_name}}</td>
                            <td class="align-middle">{{orderItem.quantity}}</td>
                            <td class="align-middle">{{orderItem.total_cost}}</td> 
                            <td class="align-middle">{{orderItem.delivery_location}}</td>
                            <td class="align-middle">{{orderItem.dispatch_rider}}</td>
                            
                            <td class="align-middle">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">                                   
                                    <button (click)="openOrderItemDetailDialog(orderItem.id);" mat-menu-item>
                                        <!-- <mat-icon>edit</mat-icon> -->
                                        <span>Order Details</span>
                                    </button>
                                </mat-menu>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>                               
        </div>
        <div class="col-md-12 d-flex text-center justify-content-center mt-5" *ngIf="orderItemDispatchList.length === 0">
            <p style="font-family: avenir-roman; font-weight: 300; font-size: 20px;">No order delivered yet</p>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
            </mat-spinner>
        </div>
    </div>
</div>