<div class="container" style="height: 100vh;">
    <div class="d-flex justify-content-between mt-3 mb-3">
        <h3 class="title-text">Food Menu</h3>
        <!-- <h3>{{account?.name}}</h3> -->
        <button (click)="signOut()" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Sign Out</button>
    </div>
    <button style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-stroked-button (click)="openFoodMenuDialog({})">Add food menu</button>
    <div class="row mt-2">
        <div class="col-12 d-flex align-content-start mt-3 mb-3" *ngFor="let food of foodList; let i = index">
            <div class="media">
                <img class="align-self-center mr-3" src="{{food.display_image}}" width="130px" height="130px" style="object-fit:cover; border-radius: 8px;">
                <div class="media-body">
                    <h4 class="mt-0 title-text">{{food.name}}</h4>
                    <p class="sub-text desc">{{food.description}}</p>
                    <p class="sub-text mb-0">{{'price GHC '+food.cost+' per pack'}}</p>
                </div>
            </div>
            <div class="ml-5">
                <p class="subtitle-text" id="example-radio-group-label">Availability</p>
                <mat-radio-group (change)="onChangeFoodAvailability(food)" aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="food.availability">
                    <mat-radio-button class="example-radio-button" [value]="true">
                        <div class="sub-text">
                            Available
                        </div>
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" [value]="false">
                        <div class="sub-text">
                            Not Available
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
                <button style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-stroked-button (click)="openFoodMenuDialog(food)">Edit this meal</button>
            </div>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
            </mat-spinner>
        </div>
    </div>
</div>