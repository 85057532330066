import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { OrderItemDetailDialogComponent } from 'src/app/shared/components/order-item-detail-dialog/order-item-detail-dialog.component';
import { Account } from 'src/app/shared/models/account.interface';
import { OrderItem } from 'src/app/shared/models/order-item';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';

@Component({
  selector: 'app-kitchen',
  templateUrl: './kitchen.component.html',
  styleUrls: ['./kitchen.component.scss']
})
export class KitchenComponent implements OnInit, OnDestroy {
  private unSubscriptioNotifier = new Subject();
  public events: string[] = [];
  public opened: boolean = true;
  public account: Account = {}

  constructor(private router: Router,
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private appMaterialComponent: AppMaterialDesignModule,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public dataSource: DataService) {

    this.matIconRegistry.addSvgIcon(
      'app_logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/app_logo.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'inactive-menu-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/inactive-menu-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'active-menu-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/active-menu-icon.svg')
    );


    this.matIconRegistry.addSvgIcon(
      'inactive-orders-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/inactive-orders-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'active-orders-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/active-orders-icon.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'inactive-riders-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/inactive-riders-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'active-riders-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/active-riders-icon.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'inactive-notifications-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/inactive-notifications-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'active-notifications-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/active-notifications-icon.svg')
    );


    this.matIconRegistry.addSvgIcon(
      'inactive-sales-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/inactive-sales-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'active-sales-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/active-sales-icon.svg')
    );


    this.matIconRegistry.addSvgIcon(
      'inactive-staff-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/inactive-staff-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'active-staff-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/active-staff-icon.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'delivery_man',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/delivery_man.svg')
    );
  }

  ngOnInit(): void {
    this.account = JSON.parse(localStorage.getItem('account'))
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }
}
