import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingComponents, CoreRoutingModule } from './core-routing.module';
import { AppMaterialDesignModule } from '../app-material-design.module';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { FoodItemDetailsComponent } from './pages/food-item-details/food-item-details.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { Angular4PaystackModule } from 'angular4-paystack';
import { OrderItemDetailsComponent } from './pages/order-item-details/order-item-details.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AccountComponent } from './pages/account/account.component';
import { DashboardComponent } from './pages/account/dashboard/dashboard.component';
import { LoginComponent } from './pages/account/login/login.component';
import { KitchenComponent } from './pages/account/dashboard/kitchen/kitchen.component';
import { DispatchComponent } from './pages/account/dashboard/dispatch/dispatch.component';
import { OrdersComponent } from './pages/account/dashboard/orders/orders.component';
import { FoodMenuComponent } from './pages/account/dashboard/food-menu/food-menu.component';
import { SalesComponent } from './pages/account/dashboard/sales/sales.component';
import { NotificationsComponent } from './pages/account/dashboard/notifications/notifications.component';
import { DispatchRidersComponent } from './pages/account/dashboard/dispatch-riders/dispatch-riders.component';
import { ChartComponent } from './pages/account/dashboard/sales/chart/chart.component';
import { ChartsModule } from 'ng2-charts';
import { ManageStaffComponent } from './pages/account/dashboard/manage-staff/manage-staff.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { CareersComponent } from './pages/account/dashboard/careers/careers.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  declarations: [
    CoreRoutingComponents,
    FoodItemDetailsComponent,
    CheckoutComponent,
    PaymentComponent,
    OrderItemDetailsComponent,
    AccountComponent,
    DashboardComponent,
    LoginComponent,
    KitchenComponent,
    DispatchComponent,
    OrdersComponent,
    FoodMenuComponent,
    SalesComponent,
    NotificationsComponent,
    DispatchRidersComponent,
    ChartComponent,
    ManageStaffComponent,
    NotificationComponent,
    CareersComponent,
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    AppMaterialDesignModule,
    SwiperModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    Angular4PaystackModule.forRoot(environment.paystack.token),
    NgxSkeletonLoaderModule.forRoot(),
    ChartsModule
  ],providers:[
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
  ],
})
export class CoreModule { }

