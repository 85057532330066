// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: "AIzaSyCDpYEObiFzytLUoimUXCaampjpI64sNsw",
    authDomain: "bite-89df9.firebaseapp.com",
    projectId: "bite-89df9",
    storageBucket: "bite-89df9.appspot.com",
    messagingSenderId: "1030069680043",
    appId: "1:1030069680043:web:cd4a3eb2780ad6477704ee",
    measurementId: "G-YER85GXZC5"
  },
  paystack: {
    token: 'pk_test_ff9023cf1f406b6f3d4cfc085b4fe764f0457762',
    cancel_action:'https://bite-web.herokuapp.com/233bite/order/checkout'
  }
};

