import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { Account } from 'src/app/shared/models/account.interface';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private unSubscriptioNotifier = new Subject();
  public account: Account = {}
  constructor(private router: Router,
    private backend: BackendService,
    private firebaseAuth: AngularFireAuth,
    private localAuthService: LocalAuthService,
    private appMaterialComponent: AppMaterialDesignModule,
    public dataSource: DataService) { }

  ngOnInit(): void {
    this.account = JSON.parse(localStorage.getItem('account'))
    // console.log(this.account)
    if (undefined == this.account) {
      this.signOut()
    }
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }

  public signOut() {
    localStorage.setItem('account', null)
    //this.firebaseAuth.signOut().then(r=>{});
    this.router.navigate([`account/login`], { queryParams: {}, skipLocationChange: false });
  }

}
