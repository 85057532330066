<div class="container-fluid p-0 m-0" style="height: 100vh; background-color: white; max-width: 100%; overflow-x: hidden;">
    <div class="row">
        <div class="pr-3 pl-3 col-md-4 offset-md-4">
            <div class="food-item-banner mt-0" style="height: 120px !important;">
                <img class="food-item-banner-mage img-fluid" src="{{orderItem?.display_image}}">
                <div style="position: absolute; top: 0;" class="col-4 mt-3 align-items-start">
                    <a routerLink="/home" mat-icon-button>
                        <mat-icon style="color:white">arrow_back_outline</mat-icon>
                    </a>
                </div>
            </div>
            <div *ngIf="orderItem.food_name" class="pl-3 pr-3 mt-3">
                <h5 class="title-text subtitle-text-color">Order Details</h5>
                <p class="mt-2 mb-0 sub-text">{{orderItem?.food_name}}</p>
                <!-- <ngx-skeleton-loader></ngx-skeleton-loader> -->

                <div class="mt-2 mb-1">
                    <p class="p-0 m-0 subtitle-text subtitle-text-color">Description</p>
                    <p class="sub-text">{{orderItem?.description}}</p>
                </div>
                <div class="mt-2 mb-1 d-flex justify-content-between">
                    <p class="p-0 m-0 subtitle-text subtitle-text-color">Date</p>
                    <p class="sub-text">{{orderItem?.created_date.seconds* 1000 | date}}</p>
                </div>
                <div class="mt-2 mb-1 d-flex justify-content-between">
                    <p class="p-0 m-0 subtitle-text subtitle-text-color">Order Number</p>
                    <p class="sub-text">{{orderItem?.id}}</p>
                </div>
                <div class="mt-2 mb-1 d-flex justify-content-between">
                    <p class="p-0 m-0 subtitle-text subtitle-text-color">Order Quantity</p>
                    <p class="sub-text">{{orderItem?.quantity}}</p>
                </div>

                <div class="mt-2 mb-1 d-flex justify-content-between">
                    <p class="p-0 m-0 subtitle-text subtitle-text-color">Order Status</p>
                    <p class="sub-text">{{orderItem?.status}}</p>
                </div>
                <!-- <mat-divider></mat-divider> -->
                <div class="p-3" style="background-color: #F4F4F4; border-radius: 5px;">
                    <h5 class="title-text subtitle-text-color">Payment Details & Receipt</h5>
                    <div class="mt-2 mb-1 d-flex justify-content-between">
                        <p class="p-0 m-0 subtitle-text subtitle-text-color">Total Amount</p>
                        <p class="sub-text">{{paymentInfo?.amount}}</p>
                    </div>
                    <div class="mt-2 mb-1 d-flex justify-content-between">
                        <p class="p-0 m-0 subtitle-text subtitle-text-color">Reference</p>
                        <p class="sub-text">{{paymentInfo?.reference}}</p>
                    </div>
                    <div class="mt-2 mb-1 d-flex justify-content-between">
                        <p class="p-0 m-0 subtitle-text subtitle-text-color">Transaction Fee</p>
                        <p class="sub-text">{{paymentInfo?.transaction_fees}}</p>
                    </div>
                    <div class="mt-2 mb-1 d-flex justify-content-between">
                        <p class="p-0 m-0 subtitle-text subtitle-text-color">Payment Method</p>
                        <p class="sub-text">{{paymentInfo?.method}}</p>
                    </div>
                    <div class="mt-2 mb-1 d-flex justify-content-between">
                        <p class="p-0 m-0 subtitle-text subtitle-text-color">Status</p>
                        <p class="sub-text">{{paymentInfo?.status}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 d-flex justify-content-center mt-4 mb-3">
                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                </mat-spinner>
            </div>
        </div>
    </div>
</div>