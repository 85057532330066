<mat-sidenav-container class="sidebar-container">
    <mat-sidenav class="sidebar-nav" #sidenav (keydown.escape)="sidenav.toggle()" mode="side" [(opened)]="opened" (opened)="events.push('open!')" (closed)="events.push('close!')">
        <mat-nav-list>
            <a class="mt-3 mb-3" mat-list-item routerLink="/account/dashboard/kitchen/orders">
                <mat-icon svgIcon="app_logo"></mat-icon>
                <span class="sub-text mt-2 ml-4">Dashboard</span>
            </a>

            <mat-divider></mat-divider>
            <a class="mt-3 mb-3" routerLinkActive="active-link" #order="routerLinkActive" mat-list-item routerLink="/account/dashboard/kitchen/orders">
                <mat-icon svgIcon="{{ order.isActive ? 'active' : 'inactive'}}-orders-icon"></mat-icon>
                <span class="sub-text mt-2 ml-4">Orders</span>
            </a>
            <a class="mt-3 mb-3" routerLinkActive="active-link" #menu="routerLinkActive" mat-list-item routerLink="/account/dashboard/kitchen/food-menu" >
                <mat-icon svgIcon="{{ menu.isActive ? 'active' : 'inactive'}}-menu-icon"></mat-icon>
                <span class="sub-text mt-2 ml-4">Menu</span>
            </a>
            <a class="mt-3 mb-3" mat-list-item routerLinkActive="active-link" #dispatch="routerLinkActive" routerLink="/account/dashboard/kitchen/dispatch">
                <mat-icon svgIcon="{{ dispatch.isActive ? 'active' : 'inactive'}}-riders-icon"></mat-icon>
                <span class="sub-text mt-2 ml-4">Dispatch</span>
            </a>

            <a class="mt-3 mb-3" mat-list-item routerLinkActive="active-link" #noti="routerLinkActive" routerLink="/account/dashboard/kitchen/notification">
                <mat-icon svgIcon="{{ noti.isActive ? 'active' : 'inactive'}}-notifications-icon"></mat-icon>
                <span class="sub-text mt-2 ml-4">Notification</span>
            </a>

            <a class="mt-3 mb-3" mat-list-item routerLinkActive="active-link" #sales="routerLinkActive" routerLink="/account/dashboard/kitchen/sales">
                <mat-icon svgIcon="{{ sales.isActive ? 'active' : 'inactive'}}-sales-icon"></mat-icon>
                <span class="sub-text mt-2 ml-4">Sales</span>
            </a>

            <a class="mt-3 mb-3" mat-list-item routerLinkActive="active-link" #jobs="routerLinkActive" routerLink="/account/dashboard/kitchen/careers">
                <mat-icon svgIcon="{{ sales.isActive ? 'active' : 'inactive'}}-sales-icon"></mat-icon>
                <span class="sub-text mt-2 ml-4">Careers</span>
            </a>

            <mat-divider></mat-divider>
            <a class="mt-3 mb-3" mat-list-item routerLinkActive="active-link" #manage="routerLinkActive" routerLink="/account/dashboard/kitchen/manage-staff">
                <mat-icon svgIcon="{{ manage.isActive ? 'active' : 'inactive'}}-staff-icon"></mat-icon>
                <span class="sub-text mt-2 ml-4">Manage Staff</span>
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <!-- <button mat-button (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
        </button> -->
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>