import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { slideInOutAnimation } from 'src/app/shared/animations/slide-inout.animation';
import { SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Router } from '@angular/router';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';
import { Cart } from '../food-item-details/food-item-details.component';
import { FormControl, Validators } from '@angular/forms';
import { OrderItem } from 'src/app/shared/models/order-item';
import { FormInput, InputDialogComponent } from 'src/app/shared/components/input-dialog/input-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogButton } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  //animations: [slideInOutAnimation],
  //host: { '[@slideInOutAnimation]': '' }
})
export class CheckoutComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public swiperConfig: SwiperConfigInterface = {};
  public cart: Cart = {}
  public mobileNumber = ''
  public delivery_location = ''
  public delivery_location_list = 'Accra|Tema|Ho|Techiman'
  public orderDescriptionFormControl: FormControl;
  public paymentMethodFormControl: FormControl;
  private unSubscriptioNotifier = new Subject();

  constructor(private router: Router,
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private appMaterialComponent: AppMaterialDesignModule,
    private dataSource: DataService) {
    this.orderDescriptionFormControl = new FormControl('', [Validators.required])
    this.paymentMethodFormControl = new FormControl('Mobile Money', [Validators.required])
  }

  @ViewChild(SwiperDirective, { static: false })
  swiperDirectiveRef!: SwiperDirective;

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
    dynamicBullets: true,
    type: 'bullets'
  };
  ngOnInit(): void {
    this.dataSource.currentdata
    .pipe(takeUntil(this.unSubscriptioNotifier))
    .subscribe(data => {
      this.cart = data;
      if (this.cart.foodItem == undefined) {
        //this.router.navigate(['home'], { queryParams: {}, skipLocationChange: false })
        //return
        this.cart = JSON.parse(localStorage.getItem('cart'))
      }
      this.mobileNumber = this.user.mobileNumber;
      this.delivery_location = this.cart.delivery_location.name
      this.getLocations()

    });


    this.swiperConfig = {
      a11y: true,
      direction: 'horizontal',
      slidesPerView: 1,
      observer: true,
      threshold: 10,
      speed: 500,
      spaceBetween: 30,
      centeredSlides: true,
      grabCursor: true,
      keyboard: false,
      allowTouchMove: false,
      longSwipes: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: this.pagination,
      roundLengths: true,
    };
  }

  public get user() {
    return this.localAuthService.getAuthUser();
  }

  public onPageIndexChange(index: number) {

  }

  public navigate(index: number) {
    this.swiperDirectiveRef.setIndex(index);
  }

  public changeDeliveryLocation() {

    let formInputs: FormInput[] = [
      {
        sequence: 1,
        title: 'Delivery Location',
        dataType: 'dropdown',
        name: 'deliveryLocation',
        value: '',
        required: true,
        listofValues: this.delivery_location_list
      }
    ];

    this.appMaterialComponent.openDialog(InputDialogComponent, {
      width: '400px',
      title: 'Select Delivery Location',
      message: 'Please select delivery location',
      data: formInputs
    }).pipe(takeUntil(this.unSubscriptioNotifier)).subscribe({
      next: (inputResult) => {
        if (inputResult.button === DialogButton.ok) {
          this.delivery_location = inputResult['formInput'][0]['value']
          this.cart.delivery_location.name = this.delivery_location
          
          this.unSubscriptioNotifier.next()
          this.unSubscriptioNotifier.complete()
        }
      }
    })
  }

  public changeDeliveryMobileNumber() {
    let formInputs: FormInput[] = [
      {
        sequence: 1,
        title: 'Mobile number',
        dataType: 'string',
        name: 'mobileNumber',
        value: '',
        required: true,
      }
    ];

    this.appMaterialComponent.openDialog(InputDialogComponent, {
      width: '400px',
      title: 'Enter Mobile Number',
      message: 'Please enter your mobile number',
      data: formInputs
    }).pipe(takeUntil(this.unSubscriptioNotifier)).subscribe({
      next: (inputResult) => {
        if (inputResult.button === DialogButton.ok) {
          this.mobileNumber = inputResult['formInput'][0]['value'];
          
          this.unSubscriptioNotifier.next()
          this.unSubscriptioNotifier.complete()
        }
      }
    })
  }

  public placeOrder() {
    if (this.orderDescriptionFormControl.valid && this.paymentMethodFormControl.valid) {
      let orderItem: OrderItem = {
        id: this.cart.reference,
        quantity: this.cart.quantity,
        food_name: this.cart.foodItem?.name,
        food_id: this.cart.foodItem?.id,
        food_image: this.cart.foodItem?.image,
        display_image: this.cart.foodItem.display_image,
        total_cost: this.cart.total_cost,
        status: 'In Progress',
        created_date: new Date(),
        user_id: this.user.id,
        username: this.user.fullname,
        delivery_location: this.delivery_location.trim(),//this.cart.delivery_location?.id,
        payment_method: this.paymentMethodFormControl.value.trim(),
        description: this.orderDescriptionFormControl.value.trim(),
        mobileNumber: this.mobileNumber.trim()
      }
      this.cart.orderItem = orderItem;
      this.isLoading = true;
      this.backend.createOrder(orderItem)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (response) => {
            this.isLoading = false
            this.dataSource.setData(this.cart);
            localStorage.setItem('cart', JSON.stringify(this.cart));
            this.router.navigate([`order/payment`], { queryParams: {}, skipLocationChange: false });
          }, error: (err: any) => {
            console.log('An error occurred:', err.error.message);
            this.isLoading = false
          }, complete: () => {
            this.isLoading = false;
            console.log('on complete createOrder');
          }
        })
    }
  }

  getLocations() {
    this.backend.getDeliveryLocations()
    .pipe(takeUntil(this.unSubscriptioNotifier))
    .subscribe(actionArray => {
      let temp = actionArray.map((item) => {
        return {
          ...item.payload.doc.data()
        } 
      })
      this.delivery_location_list = temp.map(re=>re.name).join('|')
    })
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete()
  }

}
