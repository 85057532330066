import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { empty, from, iif, of, Subject, Subscription } from 'rxjs';
import { slideInOutAnimation } from 'src/app/shared/animations/slide-inout.animation';
import { WindowService } from 'src/app/shared/services/window.service';
import { environment } from 'src/environments/environment';
import firebaseApp from 'firebase/app';
//import firebase from 'firebase'
import 'firebase/auth'; 
import { BackendService } from 'src/app/shared/services/backend.service';
import { User } from 'src/app/shared/models/user.interface';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DialogButton } from 'src/app/shared/services/dialog.service';

firebaseApp.initializeApp(environment.firebaseConfig);

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // animations: [slideInOutAnimation],
  // host: { '[@slideInOutAnimation]': '' }
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

  windowRef: any;
  user: User = {};
  //recaptchaVerifier: firebase.auth.ApplicationVerifier | undefined;
  isLoading = false;

  private observers: Subscription[] = []
  private unSubscriptioNotifier = new Subject();
  public phoneNumberFormControl: FormControl
  public otpFormControl: FormControl
  public signupFormGroup: FormGroup;

  public swiperConfig: SwiperConfigInterface = {};
  @ViewChild(SwiperDirective, { static: false })
  swiperDirectiveRef!: SwiperDirective;

  constructor(public router: Router,
    private win: WindowService,
    private firebaseAuth: AngularFireAuth,
    private localAuth: LocalAuthService,
    private appMaterialComponent: AppMaterialDesignModule,
    private backend: BackendService) {
    this.phoneNumberFormControl = new FormControl('', [Validators.required])
    this.otpFormControl = new FormControl('', [Validators.required, Validators.maxLength(6), Validators.minLength(6)])
    this.signupFormGroup = new FormGroup({
      fullname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      mobileNumber: new FormControl('', [Validators.required,]),
      checked: new FormControl(false),
      // Validators.pattern(new RegExp("[0|233][0-9][{9}|{12}"))
      // acceptTNC: new FormControl(false, [Validators.required])
    });

    this.firebaseAuth.authState.pipe(take(1)).subscribe({
      next:(authUser)=>{
        if(authUser){
          this.router.navigate(['home'], { queryParams: {}, skipLocationChange: false });
        }
      }
    })
  }

  ngAfterViewInit(): void {
    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      callback:()=>{

      }
    });
    this.windowRef.recaptchaVerifier.render()
  }


  ngOnInit(): void {

    this.swiperConfig = {
      a11y: true,
      direction: 'horizontal',
      slidesPerView: 1,
      observer: true,
      threshold: 10,
      speed: 500,
      spaceBetween: 30,
      centeredSlides: true,
      grabCursor: true,
      keyboard: false,
      allowTouchMove: false,
      longSwipes: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: this.pagination,
      roundLengths: true,
    };
  }

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
    dynamicBullets: true,
    type: 'bullets'
  };

  public sendLoginCode(num: string) {
    this.isLoading = true;
    const appVerifier = this.windowRef.recaptchaVerifier;

    if (num.startsWith('0')) {
      num = num.slice(1, num.length)
      num = '+233' + num
    }

    if (num.startsWith('233')) {
      num = num.slice(3, num.length)
      num = '+233' + num
    }

    if (num.startsWith('+')) {
      num = num.slice(4, num.length)
      num = '+233' + num
    }
    //console.log('num :' + num)
    this.phoneNumberFormControl.setValue('')
    //appVerifier.verify();
    this.firebaseAuth.signInWithPhoneNumber(num, appVerifier)
      .then(result => {
        this.isLoading = false
        this.navigate(1)
        this.windowRef.confirmationResult = result;
      })
      .catch(error => {
        this.isLoading = false
        console.log(error)
      });
  }

  public verifyLoginCode() {
    this.isLoading = true;
    if (this.otpFormControl.value) {
      let verificationCode = this.otpFormControl.value;
      //console.log('verificationCode :' + verificationCode)
      this.otpFormControl.setValue('')
      this.windowRef.confirmationResult.confirm(verificationCode)
        .then((result) => {
          //console.log('result : ' + JSON.stringify(result.user))
          this.firebaseAuth.currentUser.then(authUser => {
            this.user['id'] = authUser?.uid
            this.user['mobileNumber'] = authUser?.phoneNumber
            //console.log('user  ' + JSON.stringify(this.user))
            this.saveUser(this.user);
          })
        })
        .catch((error: any) => {
          this.isLoading = false
          console.log(error, "Incorrect code entered?")
        });
    }
  }

  saveUser(user: User) {
    this.isLoading = true
    let tempUser = user;
    delete user["checked"]

    this.backend.getUser(user.id).pipe(switchMap((u) => {
      if (undefined == u){
        if (tempUser['checked']){ 
          this.setUserAppSettings()
        }
        return this.backend.createUser(user)
      }
      else {
        tempUser = { ...u };
        return this.backend.updateUser(user)
      }
    }), takeUntil(this.unSubscriptioNotifier)
    ).subscribe({
      next: () => {
        delete tempUser["checked"]
        this.localAuth.setUser(tempUser);
        this.firebaseAuth.currentUser.then(authUser => {
          //console.log('user  ' + JSON.stringify(authUser))
          if (user.fullname) {
            authUser?.updateProfile({
              displayName: user.fullname
            }).then(() => {
              this.isLoading = false
            }).catch((error) => {
              this.isLoading = false
              console.log(error, "failed to update user display name")
            });
          }
          if (user.email) {
            authUser?.updateEmail(user.email).then(() => {
              // authUser.sendEmailVerification().then(() => {
              //   console.log('Email verification sent!')
              // });
              //this.router.navigate(['home'], { queryParams: {}, skipLocationChange: false });
            }).catch((error) => {
              this.isLoading = false
              console.log(error, "failed to update user email")
            });
          }
        })
      },error:()=>{

      },complete:()=>{
        console.log('complete saveUser')
        this.unSubscriptioNotifier.next();
        this.unSubscriptioNotifier.complete();
        this.router.navigate(['home'], { queryParams: {}, skipLocationChange: false });
      }
    });
  }

  public signup() {
    this.isLoading = true;
    let formData = this.signupFormGroup.value
    this.user = formData
    let num = formData['mobileNumber']
    if (num.startsWith('0')) {
      num = num.slice(1, num.length)
      num = '+233' + num
    }

    if (num.startsWith('233')) {
      num = num.slice(3, num.length)
      num = '+233' + num
    }

    if (num.startsWith('+')) {
      num = num.slice(4, num.length)
      num = '+233' + num
    }   

    this.backend.getUserByMobileNumber(num)
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe({
        next: (actionArray) => {
          this.isLoading = false
          if (actionArray.length > 0) {
            let users = actionArray.map((item: { payload: { doc: { id: any; data: () => User; }; }; }) => {
              let data = item.payload.doc.data()
              delete data.id;
              return {
                id: item.payload.doc.id,
                ...data
              } as User
            })
            this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
              width: '500px',
              title: 'Caution',
              message: 'Your mobile number is already registered with us. Kindly Sign in instead.'
            }).subscribe({
                next: (result =>{
                  if (result.button === DialogButton.ok) {
                    this.phoneNumberFormControl = new FormControl(num, [Validators.required])
                    this.switchTab(1)
                  }
                })
              })
          }
          else {
            this.sendLoginCode(num);
          }
        }
      })
  }

  public tabIndex = 0;
  public switchTab(index) {
    this.tabIndex = index
  }

  public login() {
    this.isLoading = true;
    if (this.phoneNumberFormControl.value) {
      let num = this.phoneNumberFormControl.value

      if (num.startsWith('0')) {
        num = num.slice(1, num.length)
        num = '+233' + num
      }

      if (num.startsWith('233')) {
        num = num.slice(3, num.length)
        num = '+233' + num
      }

      if (num.startsWith('+')) {
        num = num.slice(4, num.length)
        num = '+233' + num
      }
      //console.log('num :' + num)
      this.user['mobileNumber'] = num
      this.backend.getUserByMobileNumber(num)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (result => {
            this.isLoading = false
            if (result.length === 0) {
              this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
                width: '500px',
                title: 'Caution',
                message: 'Your mobile number is not registered with us. Kindly Sign up instead.'
              }).pipe(takeUntil(this.unSubscriptioNotifier)).subscribe()
            } else {
              this.sendLoginCode(num);
            }
          })
        })
    }
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
    this.observers.forEach(o => {
      o.unsubscribe()
    })
  }

  public navigate(index: number) {
    this.swiperDirectiveRef.setIndex(index);
  }

  public onPageIndexChange(index: number) {

  }
  
  public setUserAppSettings() {
    let sett = {
      user_id: this.user.id,
      notification: true,
      isCancel: false
    }
    return this.backend.setUserAppSettings(sett).then(response => {
    }).catch((error: any) => {
      this.isLoading = false
      console.log(error, "unable to update user setting")
    })
  }
}


