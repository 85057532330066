<div class="container" style="height: 100vh;">
    <div class="d-flex justify-content-between mt-3 mb-3">
        <h3 class="title-text">Careers</h3>
        <!-- <h3>{{account?.name}}</h3>-->
        <button (click)="signOut()" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Sign Out</button>

    </div>
      <button style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-stroked-button (click)="openCareerDialog({})">Add job</button>

      <div class="row mt-2">
        <div class="col-md">
            <div class="table-responsive bg-white" style="max-height: 620px;">
                <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
                    <thead>
                        <tr>
                            <th></th>
                            <!-- <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                                Full name
                            </th> -->
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Job Title</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Description</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Salary</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Experience</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Availability</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Deadline</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="animate__animated animate__fadeIn" *ngFor="let job of careerList$ | async; let i=index;" style="border: .5px solid #EDEDED; font-size: 15px;">
                            <td></td>
                            <td class="align-middle">{{job?.name}}</td>
                            <td class="align-middle">{{job?.short_desc}}</td>
                            <td class="align-middle">{{job?.salary}}</td>
                            <td class="align-middle">{{job?.experience}}</td>
                            <td class="align-middle" *ngIf="job?.availability">Active</td>
                            <td class="align-middle" *ngIf="!job?.availability">Disabled</td>
                            <td class="align-middle">{{job?.deadline}}</td>
                            <td class="align-middle">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu  #menu="matMenu">
                                    <button mat-menu-item (click)="openCareerDialog(job, 'View Details')">
                                        <!-- <mat-icon>edit</mat-icon> -->
                                        <span>View details</span>
                                    </button>
                                    <button mat-menu-item (click)="openCareerDialog(job, 'Edit Job')">
                                        <!-- <mat-icon>edit</mat-icon> -->
                                        <span>Edit job</span>
                                    </button>                                   
                                    <button mat-menu-item (click)="backend.deleteCareer(job)">
                                        <!-- <mat-icon>edit</mat-icon> -->
                                        <span>Delete job</span>
                                    </button>
                                </mat-menu>
                                <!-- <mat-menu  #accountStatusMenu="matMenu">
                                    <button  mat-menu-item >
                                        <span>Activate</span>
                                    </button>
                                    <button  mat-menu-item >
                                        <span>Disable</span>
                                    </button>
                                </mat-menu> -->
                            </td>
                           
                        </tr>
                    </tbody>
                    
                </table>
            </div>
        </div>
     </div>
</div>    

