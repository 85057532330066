<div class="container" style="height: 100vh;">
    <div class="d-flex justify-content-between mt-3 mb-3">
        <h3 class="title-text">Manage Staff</h3>
        <!-- <h3>{{account?.name}}</h3> -->
        <button (click)="signOut()" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Sign Out</button>
    </div>
    <button style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-stroked-button (click)="openAccountDialog({})">Add new staff</button>
    <div class="row mt-2">
        <div class="col-md">
            <div class="table-responsive bg-white" style="max-height: 620px;">
                <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                                Full name
                            </th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Username</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Mobile number</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Account type</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Account Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="animate__animated animate__fadeIn" *ngFor="let account of accountList$ | async; let i=index;" style="border: .5px solid #EDEDED; font-size: 15px;">
                            <td></td>
                            <td class="align-middle">{{account?.name}}</td>
                            <td class="align-middle">{{account?.username}}</td>
                            <td class="align-middle">{{account.mobileNumber}}</td>
                            <td class="align-middle">{{account.type}}</td>
                            <td class="align-middle">{{account.status}}</td>
                            <td class="align-middle">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item>
                                        <!-- <mat-icon>edit</mat-icon> -->
                                        <span>View details</span>
                                    </button>
                                    <button mat-menu-item (click)="openAccountDialog(account)">
                                        <!-- <mat-icon>edit</mat-icon> -->
                                        <span>Edit account</span>
                                        
                                    </button>
                                    <button mat-menu-item [matMenuTriggerFor]="accountStatusMenu">
                                        <!-- <mat-icon>edit</mat-icon> -->
                                        <span>Account status</span>
                                    </button>
                                    <button mat-menu-item (click)="deleteAccount(account)">
                                        <!-- <mat-icon>edit</mat-icon> -->
                                        <span>Delete account</span>
                                    </button>
                                </mat-menu>
                                <mat-menu #accountStatusMenu="matMenu">
                                    <button *ngIf="account.status=='DISABLED'" mat-menu-item (click)="changeAccoutStatus(account.id,'ACTIVE')">
                                        <span>Activate</span>
                                    </button>
                                    <button *ngIf="account.status=='ACTIVE'" mat-menu-item (click)="changeAccoutStatus(account.id,'DISABLED')">
                                        <span>Disable</span>
                                    </button>
                                </mat-menu>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-12 d-flex text-center justify-content-center mt-5" *ngIf="accountList.length < 1">
            <p style="font-family: avenir-roman; font-weight: 300; font-size: 20px;">No accounts added yet</p>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
            </mat-spinner>
        </div>
    </div>
</div>