import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AssignDispatchDialogComponent } from 'src/app/shared/components/assign-dispatch-dialog/assign-dispatch-dialog.component';
import { OrderItemDetailDialogComponent } from 'src/app/shared/components/order-item-detail-dialog/order-item-detail-dialog.component';
import { Account } from 'src/app/shared/models/account.interface';
import { OrderItem } from 'src/app/shared/models/order-item';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  private unSubscriptioNotifier = new Subject();
  public isLoading = false;
  public orderId = '';
  public account: Account = {}

  public orderItemList = new Array<OrderItem>();
  public orderItemList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public orderItemListBehaviour: BehaviorSubject<OrderItem[]>;

  public orderItemProcessingList = new Array<OrderItem>();
  public orderItemProcessingList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public orderItemProcessingListBehaviour: BehaviorSubject<OrderItem[]>;

  public orderItemDispatchedList = new Array<OrderItem>();
  public orderItemDispatchedList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public orderItemDispatchedListBehaviour: BehaviorSubject<OrderItem[]>;

  public orderItemReadyToDispatchList = new Array<OrderItem>();
  public orderItemReadyToDispatchList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public orderItemReadyToDispatchListBehaviour: BehaviorSubject<OrderItem[]>;

  constructor(private router: Router,
    private backend: BackendService,
    private firebaseAuth: AngularFireAuth,
    private localAuthService: LocalAuthService,
    private appMaterialComponent: AppMaterialDesignModule,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public dataSource: DataService) {

    this.matIconRegistry.addSvgIcon(
      'delivery_man',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/delivery_man.svg')
    );

    this.orderItemListBehaviour = new BehaviorSubject([])
    this.orderItemList$ = this.orderItemListBehaviour.asObservable();

    this.orderItemProcessingListBehaviour = new BehaviorSubject([])
    this.orderItemProcessingList$ = this.orderItemProcessingListBehaviour.asObservable();

    this.orderItemDispatchedListBehaviour = new BehaviorSubject([])
    this.orderItemDispatchedList$ = this.orderItemDispatchedListBehaviour.asObservable();

    this.orderItemReadyToDispatchListBehaviour = new BehaviorSubject([])
    this.orderItemReadyToDispatchList$ = this.orderItemReadyToDispatchListBehaviour.asObservable();
  }

  ngOnInit(): void {
    this.account = JSON.parse(localStorage.getItem('account'))
    this.getNewOrdersFromQueue();
    this.updateDispatchedOrders();
  }

  public getNewOrdersFromQueue() {
    this.isLoading = true;
    this.backend.getKitchentOrders(this.account?.id)
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe(actionArray => {
        this.isLoading = false
        let tempOrderItems = actionArray.map((item: { payload: { doc: { id: any; data: () => OrderItem; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as OrderItem
        })

        this.orderItemList = tempOrderItems.filter(i => {
          return i.status == 'new'
        })
        this.orderItemListBehaviour.next(this.orderItemList);

        this.orderItemProcessingList = tempOrderItems.filter(i => {
          return i.status == 'Processing'
        });
        this.orderItemProcessingListBehaviour.next(this.orderItemProcessingList);

        this.orderItemDispatchedList = tempOrderItems.filter(i => {
          return i.status == 'Dispatched'
        });
        this.orderItemDispatchedListBehaviour.next(this.orderItemDispatchedList);

        this.orderItemReadyToDispatchList = tempOrderItems.filter(i => {
          return ['ReadyToDispatch', 'Dispatched', 'Enroute'].includes(i.status);
        });
        this.orderItemReadyToDispatchListBehaviour.next(this.orderItemReadyToDispatchList);
      })
  }


  public updateDispatchedOrders(){
    this.backend.getDispatchedOrders()
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe(actionArray => {
        let orderItemDispatchList = actionArray.map((item: { payload: { doc: { id: any; data: () => OrderItem; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as OrderItem
        })
        // console.log(orderItemDispatchList)
        orderItemDispatchList.forEach(o => {
          let q = this.orderItemReadyToDispatchList.find(i => i.id === o.id && i.status !== 'Dispatched')
          if(undefined !== q){
            // console.log(o, 'readyToDispatch:', q)
            this.updateOrderStatus('Dispatched', q.id)
          }
        })
      })
  }


  public updateOrderStatus(status: string, orderId: string) {
    this.backend.updateOrderStatus(orderId, this.account.id, status)
  }
  public bulkUpdateOrderStatus(orderItemList, status: string) {
    orderItemList.forEach(o => {
      if (o.checked) {
        this.backend.updateOrderStatus(o.id, this.account.id, status)
      }
    })
  }

  public openOrderItemDetailDialog(orderId: string) {
    this.dataSource.setData(orderId)
    this.appMaterialComponent.openDialog(OrderItemDetailDialogComponent, {
      width: '700px',
      title: 'Order Detail',
      message: 'Order Detail'
    }).pipe(switchMap(result => {
      if (result.text == 'process') {
        return this.backend.updateOrderStatus(orderId, this.account.id, 'Processing')
      } else if (result.text == 'cancel') {
        return this.backend.updateOrderStatus(orderId, this.account.id, 'Cancelled')
      }
    }), takeUntil(this.unSubscriptioNotifier),
    ).subscribe()
  }d


  public openAssignToDispatchDialog(orderItem) {
    this.appMaterialComponent.openDialog(AssignDispatchDialogComponent, {
      width: '700',
      title: 'Dispatch Riders',
      message: 'Dispatch Riders'
    }).pipe(switchMap(result => {
      if (result.dispatchRider) {
        orderItem.status = 'Enroute';
        this.updateOrderStatus('Enroute', orderItem.id);
        return this.backend.assignOrderToDispatch(result.dispatchRider, orderItem, this.account.id)
      }
    }), take(1),
    ).subscribe()
  }

  public openAssignBulkToDispatchDialog(orderItems) {
    this.appMaterialComponent.openDialog(AssignDispatchDialogComponent, {
      width: '700',
      title: 'Dispatch Riders',
      message: 'Dispatch Riders'
    }).pipe(take(1),
    ).subscribe({
      next: (result) => {
        if (result.dispatchRider) {
          orderItems.forEach(o => {
            o.status = 'Enroute';
            this.backend.assignOrderToDispatch(result.dispatchRider, o, this.account.id);
            this.updateOrderStatus('Enroute', o.id);
          });
        }
      }
    })
  }

  public printReceipt(orderItem) {
    this.dataSource.setData(orderItem.id)
    this.appMaterialComponent.openDialog(OrderItemDetailDialogComponent, {
      width: '700px',
      title: 'Order Detail',
      message: 'Order Detail'
    })
  }

  public checkAllOrderItemListCheckBox(ev) {
    this.orderItemList.forEach(x => x.checked = ev.target.checked)
  }

  public isAllOrderItemListCheckBoxChecked() {
    return this.orderItemList.every(p => p.checked);
  }

  public checkAllOrderItemProcessingListCheckBox(ev) {
    this.orderItemProcessingList.forEach(x => x.checked = ev.target.checked)
  }

  public isAllOrderItemProcessingListCheckBoxChecked() {
    return this.orderItemProcessingList.every(p => p.checked);
  }

  public checkAllOrderItemDispatchedListCheckBox(ev) {
    this.orderItemDispatchedList.forEach(x => x.checked = ev.target.checked)
  }

  public isAllOrderItemDispatchedListCheckBoxChecked() {
    return this.orderItemDispatchedList.every(p => p.checked);
  }

  public checkAllOrderItemReadyToDispatchListCheckBox(ev) {
    this.orderItemReadyToDispatchList.forEach(x => x.checked = ev.target.checked)
  }

  public isAllOrderItemReadyToDispatchListCheckBoxChecked() {
    return this.orderItemReadyToDispatchList.every(p => p.checked);
  }

  public isSomeCheckBoxChecked() {
    return this.orderItemList.some(p => p.checked)
      || this.orderItemProcessingList.some(p => p.checked)
      || this.orderItemDispatchedList.some(p => p.checked)
      || this.orderItemReadyToDispatchList.some(p => p.checked);
  }


  public signOut() {
    localStorage.setItem('account', null)
    //this.firebaseAuth.signOut().then(r => { });
    this.router.navigate([`account/login`], { queryParams: {}, skipLocationChange: false });
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }

}
