import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, from, Observable, of, pipe, Subject, Subscription, } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take, takeUntil } from 'rxjs/operators'
import { fadeInAnimation } from 'src/app/shared/animations/fadein.animation';
import { User } from 'src/app/shared/models/user.interface';
import { DeliveryLocation } from 'src/app/shared/models/location.interface';
import { BackendService } from 'src/app/shared/services/backend.service';
import { Food } from 'src/app/shared/models/food.interface';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SwiperConfigInterface, SwiperPaginationInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { OrderItem } from 'src/app/shared/models/order-item';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { DialogButton } from 'src/app/shared/services/dialog.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Setting } from 'src/app/shared/models/setting';
import { DataService } from 'src/app/shared/services/data.service';
import { Cart } from '../food-item-details/food-item-details.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  public isLoading = false;
  public isCancel = false;
  public user: User
  public userAppSetting: Setting
  private observers: Subscription[] = []
  public deliveryLocations: DeliveryLocation[] = []
  public locationList$: Observable<DeliveryLocation[]> = new Observable<DeliveryLocation[]>();
  public locationListBehaviour: BehaviorSubject<DeliveryLocation[]>;
  public swiperConfig: SwiperConfigInterface = {};
  

  private unSubscriptioNotifier = new Subject();

  public foodList$: Observable<Food[]> = new Observable<Food[]>();
  public foodListBehaviour: BehaviorSubject<Food[]>;

  public orderItemList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public orderItemListBehaviour: BehaviorSubject<OrderItem[]>;

  public activeOrderItemList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public activeOrderListBehaviour: BehaviorSubject<OrderItem[]>;

  public pastOrderItemList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public pastOrderListBehaviour: BehaviorSubject<OrderItem[]>;

  public locationFormControl: FormControl
  public notificationToggleFormControl: FormControl

  public updateProfileFormGroup: FormGroup;

  constructor(private router: Router,
    private activateRoute: ActivatedRoute,
    private backend: BackendService,
    private firebaseAuth: AngularFireAuth,
    private localAuthService: LocalAuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private afMessaging: AngularFireMessaging,
    private appMaterialComponent: AppMaterialDesignModule,
    private dataSource: DataService) {

    this.matIconRegistry.addSvgIcon(
        'active-menu-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/active-menu-icon.svg')
      );
    this.matIconRegistry.addSvgIcon(
      'inactive-menu-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/inactive-menu-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'setting',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/setting.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'active-orders',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/inactive-orders-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'inactive-orders',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/inactive-orders-icon.svg')
    );

    this.user = localAuthService.getAuthUser();
    this.userAppSetting = {
      user_id: this.user.id,
      notification: false,
      isCancel: false
    }

    this.updateProfileFormGroup = new FormGroup({
      id: new FormControl(this.user.id),
      fullname: new FormControl(this.user.fullname, [Validators.required]),
      email: new FormControl(this.user.email, [Validators.required, Validators.email]),
      mobileNumber: new FormControl({ value: this.user.mobileNumber, disabled: true }, Validators.required)
    });

    this.locationFormControl = new FormControl('', Validators.required);
    this.notificationToggleFormControl = new FormControl(this.userAppSetting.notification);
    this.locationListBehaviour = new BehaviorSubject([{}])
    this.locationList$ = this.locationListBehaviour.asObservable();

    this.foodListBehaviour = new BehaviorSubject([{}])
    this.foodList$ = this.foodListBehaviour.asObservable();

    this.orderItemListBehaviour = new BehaviorSubject([])
    this.orderItemList$ = this.orderItemListBehaviour.asObservable();

    this.activeOrderListBehaviour = new BehaviorSubject([])
    this.activeOrderItemList$ = this.activeOrderListBehaviour.asObservable();

    this.pastOrderListBehaviour = new BehaviorSubject([])
    this.pastOrderItemList$ = this.pastOrderListBehaviour.asObservable();

    this.getLocations()
    this.getFoodList()

    // console.log(this.userAppSetting)
  }
  ngAfterViewInit(): void {
    this.activateRoute.queryParams.subscribe(params => {
      let tab = params.tab || '0';
      this.onNavigationMenuClick(tab)
    });
  }

  @ViewChild(SwiperDirective, { static: false })
  swiperDirectiveRef!: SwiperDirective;

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
    dynamicBullets: true,
    type: 'bullets'
  };

  ngOnInit(): void {
    this.swiperConfig = {
      a11y: true,
      direction: 'horizontal',
      slidesPerView: 1,
      observer: true,
      threshold: 10,
      speed: 500,
      spaceBetween: 30,
      centeredSlides: true,
      grabCursor: true,
      keyboard: false,
      allowTouchMove: true,
      longSwipes: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: this.pagination,
      roundLengths: true,
    };

    this.notificationToggleFormControl.valueChanges.pipe(
      debounceTime(1500),
      distinctUntilChanged(),
      switchMap((value => {
        let sett = {
          user_id: this.user.id,
          notification: value,
        }
        if(value){
          this.requestPermission()
        }
        return from(this.updateUserAppSettings(sett));
      })), takeUntil(this.unSubscriptioNotifier)
    ).subscribe();

    this.getUserAppSettings();
    this.getOrderItemList()
  }

  getLocations() {
    this.backend.getDeliveryLocations()
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe(actionArray => {
        this.deliveryLocations = actionArray.map((item: { payload: { doc: { id: any; data: () => DeliveryLocation; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data()
          } as DeliveryLocation
        })

        this.locationFormControl.setValue(this.deliveryLocations[0].name)

        this.locationListBehaviour.next(
          this.deliveryLocations
        );
      })
  }

  getFoodList() {
    this.isLoading = true;
    this.backend.getFoodList()
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe(actionArray => {
        this.isLoading = false
        let tempFood = actionArray.map((item: { payload: { doc: { id: any; data: () => Food; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as Food
        })
        this.foodListBehaviour.next(tempFood);
      })
  }

  public getOrderItemList() {
    this.isLoading = true;
    this.backend.getOrderItemList(this.user?.id || '')
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe(actionArray => {
        this.isLoading = false
        let tempOrderItems = actionArray.map((item: { payload: { doc: { id: any; data: () => OrderItem; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as OrderItem
        })
        this.activeOrderListBehaviour.next(tempOrderItems.filter(i => {
          return ['new','Enroute','Processing','Dispatched'].includes(i.status)
          
          //i.status == 'Enroute' || i.status == 'In Progress'
        }));

        this.pastOrderListBehaviour.next(tempOrderItems.filter(i => {
          return !['new','Enroute','Processing'].includes(i.status)
          //i.status != 'Enroute' && i.status != 'In Progress'
        }
        ));
      })
  }

  public gotoFoodItemDetails(food: Food) {
    if(!food.availability){
      this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Food Menu',
        message: 'We are sorry this meal is not available yet. Please check back later.'
      }).pipe(take(1)).subscribe();
      return;
    }

    if (this.locationFormControl.valid) {
      let cart: Cart = {
        foodItem: food,
        delivery_location: this.deliveryLocations.find(l => l.name == this.locationFormControl.value),
        quantity: 1,
        total_cost: food.cost,
      }
      localStorage.setItem('cart', JSON.stringify(cart))
      this.dataSource.setData(cart)
      this.router.navigate([`food-item/${food.id}`], { queryParams: {}, skipLocationChange: false });
    }
  }

  ngOnDestroy(): void {
    this.observers.forEach(o => {
      o.unsubscribe()
    })

    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
  }

  public onNavigationMenuClick(index: number) {
    this.swiperDirectiveRef.setIndex(index);
  }


  public onPageIndexChange(index: number) {
    switch (index) {
      case 0:
        //this.getFoodList()
        //console.log("Menu")
        break;
      case 1:
        break;
      case 2:

        break;
      default:
        break;
    }
  }

  public deleteAccount() {

  }
  public signOut() {
    this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
      width: '400px',
      title: 'Caution',
      message: 'Are you sure you want to sign out?'

    }).pipe(switchMap(result => {
      if (result.button === DialogButton.ok) {
        this.isLoading = true;
        this.appMaterialComponent.showProgressDialog('Signing out... please wait');
        return this.localAuthService.signOut()
      }
    }), takeUntil(this.unSubscriptioNotifier)
    ).subscribe({
      next: (response) => {
        //this.firebaseAuth.signOut().then(r=>{})
        localStorage.setItem('authUser',null)
        this.appMaterialComponent.hideProgressDialog();
        this.isLoading = false;
        this.router.navigate(['login'], { queryParams: {}, skipLocationChange: false });
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    })
  }

  public getUserAppSettings() {
    let display = false
    this.observers.push(this.backend.getUserAppSettings(this.user.id || '')
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe((setting: Setting) => {
        console.log("user setting logging")
        this.userAppSetting = setting
        this.notificationToggleFormControl.setValue(setting?.notification)    
        if(!display){
          if(!setting.isCancel){
            if(!setting.notification){
              this.notify()
            }
          }
          display=true
        }
        
      })
    )   
  }

  public setUserAppSettings(setting: Setting) {
    return this.backend.setUserAppSettings(setting).then(response => {
    }).catch((error: any) => {
      this.isLoading = false
      console.log(error, "unable to update user setting")
    })
    // return of(true)
  }

  public updateUserAppSettings(setting: Setting) {
    return this.backend.updateUserAppSettings(setting).then(response => {
    }).catch((error: any) => {
      this.isLoading = false
      console.log(error, "unable to update user setting")
    })
    // return of(true)
  }

  public updateProfile() {
    if (this.updateProfileFormGroup.valid && !this.isLoading) {
      this.isLoading = true;
      this.user = { ...this.updateProfileFormGroup.value }
      this.backend.updateUser(this.updateProfileFormGroup.value)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: () => {
            this.localAuthService.setUser(this.user);
            this.firebaseAuth.currentUser.then(authUser => {
              if (this.user.fullname) {
                authUser?.updateProfile({
                  displayName: this.user.fullname
                }).then(() => {
                  this.isLoading = false
                }).catch((error) => {
                  this.isLoading = false
                  console.log(error, "failed to update user display name")
                });
              }

              if (this.user.email) {
                // authUser?.updateEmail(this.user.email).then(() => {
                //   authUser.sendEmailVerification().then(() => {
                //     console.log('Email verification sent!')
                //   });
                // }).catch((error) => {
                //   this.isLoading = false
                //   console.log(error, "failed to update user email")
                // });
              }
            })
            localStorage.setItem('authUser', JSON.stringify(this.user));
          }
        })
    }
  }

  public requestPermission() {
    this.afMessaging.requestToken
  .subscribe(
      (token) => { 
        let userToken = {
          id: this.user.id,
          token: token
        }
        // console.log(userToken)
        this.backend.addNotificationToken(userToken)
        let sett = {
          ...this.userAppSetting,
          notification: true,
        }
        this.updateUserAppSettings(sett)
       },
      (error) => { console.error(error); },
    );    
  }

  public cancel() {
    let sett = {
      ...this.userAppSetting,
      isCancel: true
    }
    return from(this.updateUserAppSettings(sett))
  }

  public notify() {
    this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
      width: '400px',
      title: 'Notification',
      message: 'Get notified when the food ready?'

    }).pipe(switchMap(result => {
      if (result.button === DialogButton.ok) {
        this.isLoading = true;
        // this.appMaterialComponent.showProgressDialog('Please wait...');
        let sett ={
          user_id: this.user.id,
          notification: true,
          isCancel: false
        }
        return this.setUserAppSettings(sett)
      }
      else{
        return this.cancel()
      }
    }), takeUntil(this.unSubscriptioNotifier)
    ).subscribe({
      next: (response) => {        
        // this.appMaterialComponent.hideProgressDialog();
        this.isLoading = false;
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    })
  }

}
