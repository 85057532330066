import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {


  notification$: BehaviorSubject<Notification> = new BehaviorSubject<Notification>(null);

  constructor(
    private backend: BackendService
  ) { 
  }
  

  setNotification(notification: Notification) {
    this.notification$.next(notification);
  }

  getNotification() {
    return this.notification$.asObservable();
  }

  sendNotificationToWeb(n?: NotificationInterface) {
    this.backend.getNotificationTokens()
      .subscribe(actionArray => {
        let reqTokens = actionArray.map((item: { payload: { doc: { id: any; data: () => Notification; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as Notification
        })
        let reqTokenList = [...reqTokens.map(i => i.token)]
          this.backend.sendNotificationToWeb(n, reqTokenList)
    })
  }

  sendNotification(n:NotificationInterface){
    // this.backend.sendNotificationToAndroid(n)
    // this.backend.sendNotificationToIphone(n)
    return this.sendNotificationToWeb(n)
  }

}

interface  NotificationInterface{
  id?: string
  image?: string
  display_image?: string
  title?: string
  content?:string
  date?: string
  status?: string
}