<div class="container-fluid primary-bg-color" style="height: 100vh;">
    <div class="row">
        <div class="d-flex align-items-start p-2 mt-2 ml-3">
            <button (click)="onBackPressed('/login')" mat-icon-button color="primary">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="col-12 d-flex justify-content-center mt-5">
            <div class="mt-5 p-3">
                <p class="title-text text-center title-text-color" style="font-size: 22px;">Authentication</p>
                <p class="subtitle-text text-center subtitle-text-color" style="font-size: 18px;">A One Time Password has been sent to your phone number. Enter it below.</p>
                <div class="form-group">
                    <input pattern="[0-9]+" maxlength="6" placeholder="Enter One Time Password" [formControl]="otpFormControl" type="text" class="form-control text-center" id="otpFormControl" name="otpFormControl" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':otpFormControl.hasError(
    'required') }" aria-describedby="otpFormControlHint">
                </div>
                <button (click)="verify()" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block mt-2" mat-flat-button color="primary">Login</button>
            </div>
        </div>
    </div>
</div>