<div class="d-flex justify-content-between mb-3">
    <h4 mat-dialog-title>{{dialogOptions.title}}</h4>
    <button mat-icon-button (click)="cancel()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <form [formGroup]="foodMenuFormGroup" #foodMenuForm="ngForm" name="foodMenuFormGroup" (ngSubmit)="save()">
        <div class="row">
            <div class="col">
                <div class="d-flex align-items-center" style="border: 1px dashed #cacaca; border-radius: 8px; width: 250px; height: 250px; background-color: #f0f0f0; vertical-align:middle; text-align: center;">
                    <div>
                        <button mat-icon-button (click)="cancel()">
                            <mat-icon svgIcon="upload-photo-icon"></mat-icon>
                        </button>
                        <p style="font-size: 12px;" class="sub-text">Click here to upload a picture or drag and drop a picture here.</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="name">name</p> -->
                    <input placeholder="Meal name" formControlName="name" type="text" class="form-control" id="name" name="name" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':foodMenuForm.submitted && foodMenuFormGroup.get('name')?.hasError('required') }"
                        aria-describedby="nameHint">
                    <div *ngIf="foodMenuForm.submitted && foodMenuFormGroup.get('name')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="foodMenuFormGroup.get('name')?.hasError('required')" id="nameHint" class="form-text">name
                            is
                            required</small>
                        <small *ngIf="foodMenuFormGroup.get('name')?.hasError('minlength')" id="nameHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="foodMenuFormGroup.get('name')?.hasError('name')">Please provide a valid
                            name</small>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="cost">cost</p> -->
                    <input placeholder="Unit price" formControlName="cost" type="text" class="form-control" id="cost" name="cost" required autofocus autocomplete="off" [ngClass]="{ 'is-invalid':foodMenuForm.submitted && foodMenuFormGroup.get('cost')?.hasError('required') }"
                        aria-describedby="costHint">
                    <div *ngIf="foodMenuForm.submitted && foodMenuFormGroup.get('cost')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="foodMenuFormGroup.get('cost')?.hasError('required')" id="costHint" class="form-text">cost
                            is
                            required</small>
                        <small *ngIf="foodMenuFormGroup.get('cost')?.hasError('minlength')" id="costHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="foodMenuFormGroup.get('cost')?.hasError('cost')">Please provide a valid
                            cost</small>
                    </div>
                </div>
                <div class="form-group">
                    <textarea placeholder="Description" class="form-control" formControlName="description" id="description" rows="4" style="padding: 12px; font-size: 15px;"></textarea>
                </div>
                <button [disabled]="!foodMenuForm.valid || isLoading" type="submit" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block" mat-flat-button color="primary">Save</button>
                <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                    <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                    </mat-spinner>
                </div>
            </div>
        </div>

    </form>
</div>