<div class="container-fluid p-0 m-0" style="height: 100vh; background-color: white; max-width: 100%; overflow-x: hidden;">
    <div class="row">
        <div class="swiper-container pr-4 pl-4 col-md-4 offset-md-4" [swiper]="swiperConfig" (indexChange)="onPageIndexChange($event)">
            <div class="swiper-wrapper">
                <div class="swiper-slide" [attr.data-swiper-slide-index]="1">
                    <div class="row page-header">
                        <div class="col-4 mt-3 align-items-">
                            <a routerLink="/food-item/{{cart?.foodItem?.id}}" mat-icon-button color="primary">
                                <mat-icon>arrow_back</mat-icon>
                            </a>
                        </div>
                        <div class="col-8 mt-4">
                            <h4 class="col-6 mt-2 title-text" style="font-size: 20px;">Checkout</h4>
                        </div>
                    </div>
                    <!-- <mat-divider></mat-divider> -->
                    <div class="pl-3 pr-3 mt-3">
                        <h5 class="title-text subtitle-text-color">Delivery address</h5>
                        <p class="mt-2 mb-1 sub-text">{{delivery_location}}</p>
                        <p (click)="changeDeliveryLocation()" class="subtitle-text title-text-color" style="text-decoration: underline;">change location
                        </p>

                        <p class="mt-5 mb-1 sub-text">{{'Please describe which part of '+delivery_location+' you are at.'}}</p>
                        <div class="form-group">
                            <textarea placeholder="Type your description here…" class="form-control" [formControl]="orderDescriptionFormControl" id="orderDescriptionFormControl" rows="4" style="padding: 12px; font-size: 15px;"></textarea>
                        </div>

                        <h5 class="title-text subtitle-text-color mt-5">Contact address</h5>
                        <p class="mt-2 mb-1 sub-text">{{mobileNumber}}</p>
                        <p (click)="changeDeliveryMobileNumber()" class="subtitle-text title-text-color" style="text-decoration: underline;">change number</p>
                        
                        <button [disabled]="!orderDescriptionFormControl.valid || isLoading" (click)="placeOrder()" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block mt-3 mb-5" mat-flat-button color="primary">Place Order</button>

                        <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                            </mat-spinner>
                        </div>
                    </div>
                </div>
                <div style="background-color: #155264;" class="swiper-slide" [attr.data-swiper-slide-index]="1">

                </div>
            </div>
        </div>
    </div>
</div>