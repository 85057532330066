import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { BackendService } from '../../services/backend.service';
import { DialogButton, DialogOptions, DialogService } from '../../services/dialog.service';
import { Notification } from '../../models/notification.interface';
import { AssignDispatchDialogComponent } from '../assign-dispatch-dialog/assign-dispatch-dialog.component';
import { debounce, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit, OnDestroy {
  public isLoading = false;
  private unSubscriptioNotifier = new Subject();
  public notificationFormGroup: FormGroup;
  public notification: Notification;

  constructor(public dialogRef: MatDialogRef<AssignDispatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions,
    private backend: BackendService,
    private dialogService: DialogService,
    private matIconRegistry: MatIconRegistry,
    private notificationService: NotificationService,
    private domSanitizer: DomSanitizer,) { 

    this.matIconRegistry.addSvgIcon(
      'upload-photo-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/upload-photo-icon.svg')
    );
    
    this.notification = dialogOptions.data || {};
    this.notificationFormGroup = new FormGroup({
      id: new FormControl(this.notification.id, [Validators.nullValidator]),
      title: new FormControl(this.notification.title, [Validators.required]),
      image: new FormControl(this.notification.image),
      content: new FormControl(this.notification.content, [Validators.required])
    });

    }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.isLoading = false;
    this.dialogService.sendMessage({ text: 'cancel button clicked', button: DialogButton.cancel });
    this.dialogRef.close();
  }

  public done(){
    this.dialogService.sendMessage({ text: 'ok button clicked', button: DialogButton.ok });
    this.dialogRef.close();
  }

  public save(){
    // TODO: send the image to firestore from local device
    this.isLoading = true;
    let notification = this.notificationFormGroup.value;
    // notification.status = 'Default'
    notification.date = new Date().toDateString()
    notification.display_image = notification.image

    // console.log('notification:', notification)
    this.backend.addNotification(notification);
    this.notificationService.sendNotification(notification);
    this.done()
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
  }


}
