import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AccountDialogComponent } from 'src/app/shared/components/account-dialog/account-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Account } from 'src/app/shared/models/account.interface';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { DialogButton } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-manage-staff',
  templateUrl: './manage-staff.component.html',
  styleUrls: ['./manage-staff.component.scss']
})
export class ManageStaffComponent implements OnInit,OnDestroy {

  private unSubscriptioNotifier = new Subject();

  public accountList = new Array<Account>();
  public accountList$: Observable<Account[]> = new Observable<Account[]>();
  public accountListBehaviour: BehaviorSubject<Account[]>;

  public isLoading = false;
  constructor(private router: Router,
    private backend: BackendService,
    private firebaseAuth: AngularFireAuth,
    private appMaterialComponent: AppMaterialDesignModule,
    public dataSource: DataService) {
    this.accountListBehaviour = new BehaviorSubject([{}])
    this.accountList$ = this.accountListBehaviour.asObservable();
  }
  ngOnInit(): void {
    this.getAccountList();
  }

  public openAccountDialog(account: Account) {
    this.dataSource.setData(account)
    this.appMaterialComponent.openDialog(AccountDialogComponent, {
      width: '750px',
      title: 'Create Account',
      message: 'Create Account',
      data: account
    }).pipe(takeUntil(this.unSubscriptioNotifier),
    ).subscribe({
      next: (result) => {
        if (result.button == DialogButton.ok) {
          this.getAccountList()
        }
      }
    })
  }
  getAccountList() {
    this.isLoading = true;
    this.backend.getAccountList()
    .pipe(takeUntil(this.unSubscriptioNotifier))
    .subscribe({
      next: (actionArray) => {
        this.isLoading = false
        if (actionArray.length > 0) {
          let accounts = actionArray.map((item: { payload: { doc: { id: any; data: () => Account; }; }; }) => {
            let data = item.payload.doc.data()
            delete data.id;
            return {
              id: item.payload.doc.id,
              ...data
            } as Account
          })
          this.accountList = accounts;
          this.accountListBehaviour.next(accounts);
        }
      }
    })
  }

  public signOut() {
    localStorage.setItem('account', null)
    //this.firebaseAuth.signOut().then(r => { });
    this.router.navigate([`account/login`], { queryParams: {}, skipLocationChange: false });
  }

  public deleteAccount(account: Account){
    let t = this;
    this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
      width: '400px',
      title: 'Caution',
      message: 'Are you sure you want to delete this account?'
    }).pipe(switchMap(result => {
      if (result.button === DialogButton.ok) {
        t.isLoading = true;
        t.appMaterialComponent.showProgressDialog('Deleting... please wait');
        return t.backend.deleteAccount(account)
      }
    }), takeUntil(t.unSubscriptioNotifier)
    ).subscribe({
      next: (response) => {
        console.log('response came')
        t.appMaterialComponent.hideProgressDialog();
        t.isLoading = false;
        t.getAccountList();
      },
      error: (err: any) => {
        t.isLoading = false;
      },
      complete: () => {
        t.isLoading = false;
      }
    })
  }

  public changeAccoutStatus(id:string,status:string) {
    this.appMaterialComponent.showProgressDialog('Changing account status... please wait');
    this.backend.changeAcccountStatus(id,status)
    .pipe(takeUntil(this.unSubscriptioNotifier))
    .subscribe({
      next: (response) => {
        this.appMaterialComponent.hideProgressDialog();
        this.isLoading = false;
        this.getAccountList();
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }

}
