import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Chart,ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { Sales } from 'src/app/shared/models/sales.interface';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy {
  public isLoading = false;
  private unSubscriptioNotifier = new Subject();   

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType:ChartType = 'line';
  lineChartColors: Color[] = [
    { 
      backgroundColor: 'rgba(255,255,255,0)',
      borderColor: '#155264',
    },
    { 
      backgroundColor: 'rgba(255,255,255,0)', 
      borderColor: '#FFD36A',
    }
  ];
  
  lineChartData: ChartDataSets[] = [];
  lineChartOptions: ChartOptions = { responsive: true };
  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June','July', 'August', 'September', 'October', 'November', 'December'];

 
  constructor(private backend: BackendService,    
    public dataSource: DataService
  ) {

  }

  ngOnInit(): void {      
    this.getExpensesAndSalesList()

  }


  public getExpensesAndSalesList() {
    this.isLoading = true
    let observers: Observable<any> = combineLatest([
      this.backend.getExpensesList(),
      this.backend.getSalesList()
    ])
    
    observers.pipe(takeUntil(this.unSubscriptioNotifier))
    .subscribe(actionArray => {
      this.isLoading = false
      let  tempExpensesItems= actionArray[0].map((item: { payload: { doc: { id: any; data: () => IENS; }; }; }) => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data(),
        } as IENS
      })      

      let tempSalesItems = actionArray[1].map((item: { payload: { doc: { id: any; data: () => Sales; }; }; }) => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data(),
        } as Sales
      })
     
      this.lineChartData = [       
      {            
        data: this.sumByMonth(tempSalesItems).map(item => item),  
        label: 'Sales'
      },
      {            
        data: this.sumByMonth(tempExpensesItems).map(item => item),  
        label: 'Expenses'
      },
    ]
    })
  }

  public sumByMonth(ls:IENS[]){
    let arr:number[] =[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] 
    for(let i=0; i < ls.length; i++){
      let m = new Date(ls[i].date).getMonth() 
      if (m !== undefined) { 
        arr[m] += ls[i].amount
      }
    }

    return arr    
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }

}

interface IENS {
  id?:string
  reference?: string
  status?: string
  amount?: number
  method?: string,
  currency?: string
  transaction_fees?: number
  customer_email?: string
  date?:  string
  type?: string
  description?:string   
}
