import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InputDialogComponent } from '../shared/components/input-dialog/input-dialog.component';
import { AuthGuard } from '../shared/utils/guards/auth.guard';
import { AccountComponent } from './pages/account/account.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { CoreComponent } from './pages/core/core.component';
import { FoodItemDetailsComponent } from './pages/food-item-details/food-item-details.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginComponent as AccountLoginComponent } from './pages/account/login/login.component';
import { OrderItemDetailsComponent } from './pages/order-item-details/order-item-details.component';
import { OtpComponent } from './pages/otp/otp.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { DashboardComponent } from './pages/account/dashboard/dashboard.component';
import { KitchenComponent } from './pages/account/dashboard/kitchen/kitchen.component';
import { DispatchComponent } from './pages/account/dashboard/dispatch/dispatch.component';
import { OrdersComponent } from './pages/account/dashboard/orders/orders.component';
import { FoodMenuComponent } from './pages/account/dashboard/food-menu/food-menu.component';
import { SalesComponent } from './pages/account/dashboard/sales/sales.component';
import { NotificationsComponent } from './pages/account/dashboard/notifications/notifications.component';
import { DispatchRidersComponent } from './pages/account/dashboard/dispatch-riders/dispatch-riders.component';
import { ManageStaffComponent } from './pages/account/dashboard/manage-staff/manage-staff.component';
import { CareersComponent } from './pages/account/dashboard/careers/careers.component';

export const routes: Routes = [
  {
      path: '', component: CoreComponent, children: [
      {
        path: 'home', component: HomeComponent, canActivate: [AuthGuard]
      },
      {
        path: '', component: HomeComponent, canActivate: [AuthGuard]
      },
      {
        path: 'login', component: LoginComponent,
      },
      {
        path: 'food-item/:id', component: FoodItemDetailsComponent, canActivate: [AuthGuard]
      },

      {
        path: 'order-item/:id', component: OrderItemDetailsComponent, canActivate: [AuthGuard]
      },

      {
        path: 'order/checkout', component: CheckoutComponent, canActivate: [AuthGuard]
      },

      {
        path: 'order/payment', component: PaymentComponent, canActivate: [AuthGuard]
      },
      { path: 'account',   redirectTo: 'account/login', pathMatch: 'full'},
      {
        path: 'account', component: AccountComponent, children: [
          {
            path: "login",
            component: AccountLoginComponent,
          },
          {
            path: "dashboard",
            component: DashboardComponent, children: [
              {
                path: "kitchen",
                component: KitchenComponent, children: [
                  {
                    path: "orders",
                    component: OrdersComponent,
                  },
                  {
                    path: "food-menu",
                    component: FoodMenuComponent,
                  },
                  {
                    path: "sales",
                    component: SalesComponent,
                  },
                  {
                    path: "dispatch",
                    component: DispatchRidersComponent,
                  }, 
                  {
                    path: "notification",
                    component: NotificationsComponent,
                  }, 
                  {
                    path: "manage-staff",
                    component: ManageStaffComponent,
                  },
                  {
                    path: "careers",
                    component: CareersComponent,
                  }

                ]
              },
              {
                path: "dispatch",
                component: DispatchComponent,
              },
            ]
          },
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
export const CoreRoutingComponents = [
  CoreComponent,
  HomeComponent,
  LoginComponent,
  OtpComponent,
  FoodItemDetailsComponent,
  CheckoutComponent,
  PaymentComponent,
  OrderItemDetailsComponent,
  AccountComponent,
  AccountLoginComponent,
  DashboardComponent,
  KitchenComponent,
  DispatchComponent,
  OrdersComponent,
  FoodMenuComponent,
  SalesComponent,
  NotificationsComponent,
  DispatchRidersComponent,
  ManageStaffComponent,
  CareersComponent,
]
