import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { FoodMenuDialogComponent } from 'src/app/shared/components/food-menu-dialog/food-menu-dialog.component';
import { Food } from 'src/app/shared/models/food.interface';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { DialogButton } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-food-menu',
  templateUrl: './food-menu.component.html',
  styleUrls: ['./food-menu.component.scss']
})
export class FoodMenuComponent implements OnInit, OnDestroy {
  private unSubscriptioNotifier = new Subject();

  public foodList = new Array<Food>();
  public foodList$: Observable<Food[]> = new Observable<Food[]>();
  public foodListBehaviour: BehaviorSubject<Food[]>;

  public isLoading = false;
  constructor(private router: Router,
    private backend: BackendService,
    private firebaseAuth: AngularFireAuth,
    private appMaterialComponent: AppMaterialDesignModule,
    public dataSource: DataService) {
    this.foodListBehaviour = new BehaviorSubject([{}])
    this.foodList$ = this.foodListBehaviour.asObservable();
  }

  ngOnInit(): void {
    this.getFoodList();
  }

  getFoodList() {
    this.isLoading = true;
    this.backend.getFoodList()
      .pipe(take(1))
      .subscribe(actionArray => {
        this.isLoading = false
        let tempFood = actionArray.map((item: { payload: { doc: { id: any; data: () => Food; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as Food
        })
        this.foodList = tempFood;
        this.foodListBehaviour.next(tempFood);
      })
  }

  public onChangeFoodAvailability(food) {
    this.backend.setFoodAvailability(food);
  }

  public openFoodMenuDialog(food: Food) {
    this.dataSource.setData(food)
    this.appMaterialComponent.openDialog(FoodMenuDialogComponent, {
      width: '750px',
      title: 'Food Menu',
      message: 'Food Menu',
      data: food
    }).pipe(takeUntil(this.unSubscriptioNotifier),
    ).subscribe({
      next: (result) => {
        if (result.button == DialogButton.ok) {
          this.getFoodList()
        }
      }
    })
  }

  public signOut() {
    localStorage.setItem('account', null)
    //this.firebaseAuth.signOut().then(r => { });
    this.router.navigate([`account/login`], { queryParams: {}, skipLocationChange: false });
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }

}
