import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SalesComponent } from 'src/app/core/pages/account/dashboard/sales/sales.component';
import { Sales } from '../../models/sales.interface';
import { BackendService } from '../../services/backend.service';
import { DialogButton, DialogOptions, DialogService } from '../../services/dialog.service';
import { AssignDispatchDialogComponent } from '../assign-dispatch-dialog/assign-dispatch-dialog.component';

@Component({
  selector: 'app-expenses-dialog',
  templateUrl: './expenses-dialog.component.html',
  styleUrls: ['./expenses-dialog.component.scss']
})
export class ExpensesDialogComponent implements OnInit, OnDestroy {

  public isLoading = false;
  private unSubscriptioNotifier = new Subject();
  public expensesFormGroup: FormGroup;
  public expenses: Sales;

  constructor(public dialogRef: MatDialogRef<AssignDispatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions,
    private backend: BackendService,
    private dialogService: DialogService,
    private matIconRegistry: MatIconRegistry,) { 

    
    this.expenses = dialogOptions.data || {};
    this.expensesFormGroup = new FormGroup({
      // id: new FormControl(this.expenses.id, [Validators.nullValidator]),
      amount: new FormControl(this.expenses.amount, [Validators.required]),
      description: new FormControl(this.expenses.description, [Validators.required]),
      // date: new FormControl(this.expenses.date, [Validators.required]),
    });
    }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.isLoading = false;
    this.dialogService.sendMessage({ text: 'cancel button clicked', button: DialogButton.cancel });
    this.dialogRef.close();
  }

  public done(){
    this.dialogService.sendMessage({ text: 'ok button clicked', button: DialogButton.ok });
    this.dialogRef.close();
  }

  public save(){
    this.isLoading = true;
    let expenses = this.expensesFormGroup.value;
    expenses['date'] = new Date().toString();
    expenses['amount'] = parseInt(expenses['amount']);
    this.backend.addExpenses(expenses)
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe({
        next: (response) => {
          this.done()
          this.isLoading = false
        }, error: (err: any) => {
          console.log('An error occurred:', err.error.message);
          this.isLoading = false
        }, complete: () => {
          this.isLoading = false;
          console.log('on complete addExpenses');
        }
      })     
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
  }

}
