import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from, Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { Account } from 'src/app/shared/models/account.interface';
import { BackendService } from 'src/app/shared/services/backend.service';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';
import * as bcrypt from 'bcryptjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginFormGroup: FormGroup;
  public isLoading = false;
  private observers: Subscription[] = []
  private unSubscriptioNotifier = new Subject();
  constructor(public router: Router,
    private appMaterialComponent: AppMaterialDesignModule,
    private firebaseAuth: AngularFireAuth,
    private localAuth: LocalAuthService,
    private backend: BackendService,
    private dataSource: DataService) {

    this.loginFormGroup = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    let account = JSON.parse(localStorage.getItem('account'))
    if (undefined != account) {
      if (account.type === 'dispatch') {
        console.log(account)
        this.router.navigate([`account/dashboard/dispatch/`], { queryParams: {}, skipLocationChange: false });
      } else{
        this.router.navigate([`account/dashboard/kitchen/orders`], { queryParams: {}, skipLocationChange: false });
      }
      
    } 
  }

  public login() {
    this.isLoading = true;
    let formData = this.loginFormGroup.value
    let t = this;
    let anony = from(this.firebaseAuth.signInAnonymously());
    anony.pipe(
      switchMap((user) => {
        
        return this.backend.getAccountByUsername(formData['username'].trim());
      }),
      takeUntil(t.unSubscriptioNotifier)
    ).subscribe({
      next: (actionArray) => {
        this.isLoading = false
        if (actionArray.length > 0) {
          let accounts = actionArray.map((item: { payload: { doc: { id: any; data: () => Account; }; }; }) => {
            return {
              id: item.payload.doc.id,
              ...item.payload.doc.data()
            } as Account
          })
          
          let account = accounts[0];
          // console.log(account)
          bcrypt.compare(formData['password'].trim(), account['password'], function (err, res) {
            // res === true
            if (res === true) {
              delete account['password'];
              localStorage.setItem('account', JSON.stringify(account))
              t.dataSource.setData(account)
              if (account.type === 'dispatch') {
                t.router.navigate([`account/dashboard/dispatch/`], { queryParams: {}, skipLocationChange: false });
              } else {
                t.router.navigate([`account/dashboard/kitchen/orders`], { queryParams: {}, skipLocationChange: false });
              }
            } else {
              console.log('Invalid username or password');
              this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
                width: '500px',
                title: 'Caution',
                message: 'Invalid username or password'
              }).pipe(takeUntil(this.unSubscriptioNotifier)).subscribe()
            }
          });
        } else {
          console.log('Invalid username or password');
          this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
            width: '500px',
            title: 'Caution',
            message: 'Invalid username or password'
          }).pipe(takeUntil(this.unSubscriptioNotifier)).subscribe()
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          //A client-side or network error occurred.
          console.log("An error occurred:", err.error.message);
        } else {
          //Backend returns unsuccessful response codes such as 404, 500 etc.
          //console.log("Backend returned status code: ", err.status);
          //console.log("Response body:", err.error);
        }
        this.isLoading = false;
        //show error page
      }
    });

    // .then(user => {

    // }).catch((error) => {
    //   this.isLoading = false
    //   console.log(error, "failed to sign in anonymously")
    // });


    /*bcrypt.hash(formData['password'].trim(), saltRounds, function (err, hash) {
      account.password = hash
      console.log(`hash ${hash}`)
      t.backend.createAccount(account)
        .pipe(takeUntil(t.unSubscriptioNotifier))
        .subscribe({
          next: (result) => {
            t.isLoading = false

          }, error: (err: any) => {
            if (err.error instanceof Error) {
              //A client-side or network error occurred.
              console.log("An error occurred:", err.error.message);
            } else {
              //Backend returns unsuccessful response codes such as 404, 500 etc.
              //console.log("Backend returned status code: ", err.status);
              //console.log("Response body:", err.error);
            }
            this.isLoading = false;
            //show error page
          },
        })
    });*/
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
    this.observers.forEach(o => {
      o.unsubscribe()
    })
  }

}
