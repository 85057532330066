import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Account } from '../../models/account.interface';
import { BackendService } from '../../services/backend.service';
import { DialogButton, DialogOptions, DialogService } from '../../services/dialog.service';
import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss']
})
export class AccountDialogComponent implements OnInit, OnDestroy {
  public isLoading = false;
  private unSubscriptioNotifier = new Subject();
  public accountFormGroup: FormGroup;
  public account: Account;
  constructor(public dialogRef: MatDialogRef<AccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions,
    private backend: BackendService,
    private dialogService: DialogService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,) {

    this.account = dialogOptions.data || {};
    console.log('this.account.id : '+this.account.id)
    this.accountFormGroup = new FormGroup({
      id: new FormControl(this.account.id, [Validators.nullValidator]),
      name: new FormControl(this.account.name, [Validators.required]),
      username: new FormControl(this.account.username, [Validators.required]),
      password: new FormControl(this.account.password, [Validators.required]),
      type: new FormControl(this.account.type, [Validators.required]),
      mobileNumber: new FormControl(this.account.mobileNumber, [Validators.required]),
      idType: new FormControl(this.account.idType, [Validators.required]),
      idNumber: new FormControl(this.account.idNumber, [Validators.required]),
      nameOfKin: new FormControl(this.account.nameOfKin, [Validators.required]),
      mobileNumberOfKin: new FormControl(this.account.mobileNumberOfKin, [Validators.required]),
    });
  }


  ngOnInit(): void {
  }

  public cancel(): void {
    this.isLoading = false;
    this.dialogService.sendMessage({ text: 'cancel button clicked', button: DialogButton.cancel });
    this.dialogRef.close();
  }

  public done() {
    this.dialogService.sendMessage({ text: 'ok button clicked', button: DialogButton.ok });
    this.dialogRef.close();
  }

  public save() {
    this.isLoading = true;
    let t = this;
    let acc = this.accountFormGroup.value;
    
    if (this.account.id != undefined || null != this.account.id) {
      //update
      delete acc['password']
      this.backend.updateAccount(acc)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (response) => {
            t.isLoading = false
            t.done()
          }, error: (err: any) => {
            console.log('An error occurred:', err.error.message);
            t.isLoading = false
          }, complete: () => {
            t.isLoading = false;
            console.log('on complete updateAccount');
          }
        })
    } else {
      //add
      acc['dateCreated'] = new Date().getTime();
      acc['status'] = 'ACTIVE';
      acc['softDeleted'] = false;
      bcrypt.hash(acc['password'].trim(), 10, function (err, hash) {
        acc['password'] = hash
        console.log(`hash ${hash}`)
        t.backend.createAccount(acc)
          .pipe(takeUntil(t.unSubscriptioNotifier))
          .subscribe({
            next: (result) => {
              t.done()
              t.isLoading = false
            }, error: (err: any) => {
              console.log('An error occurred:', err.error.message);
              t.isLoading = false
            }, complete: () => {
              t.isLoading = false;
              console.log('on complete createAccount');
            }
          })
      });
    }
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
  }
}
