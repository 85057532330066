import { Component } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { NotificationService } from './shared/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bite-web';
  
  constructor(
    private afMessaging: AngularFireMessaging,
    private notificationService: NotificationService) { }

  ngOnInit() {   
    this.listen();
  }

  requestPermission() {
    this.afMessaging.requestToken
    .subscribe(
      (token) => { 
        console.log('Permission granted! Save to the server! \n', token);
       },
      (error) => { console.error(error); },  
    );
  }

  listen() {
    console.log("Listen....")
    this.afMessaging.messages
      .subscribe((message) => { 
        console.log("message is loading");
        console.log(message);   

        // this.notificationService.setNotification({
        //   body: message.notification.body,
        //   title: message.notification.title,
        //   // image: message.notification
        //   // isVisible: true,
        // })
      },
      (error) => {console.error(error)});
  }
}