import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { slideInOutAnimation } from 'src/app/shared/animations/slide-inout.animation';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
  //animations: [slideInOutAnimation],
  //host: { '[@slideInOutAnimation]': '' }
})
export class OtpComponent implements OnInit,OnDestroy {

  private observers : Subscription[] = []
  public otpFormControl: FormControl

  constructor(public router: Router) { 
    this.otpFormControl = new FormControl('',[Validators.required,Validators.maxLength(6),Validators.minLength(6)])
  }

  ngOnInit(): void {
  }

  onBackPressed(path:string){
    this.router.navigate([path], { queryParams: {}, skipLocationChange: true });
  }

  public verify(){

  }

  ngOnDestroy(): void {
    this.observers.forEach(o=>{
      o.unsubscribe()
    })
  }

}
