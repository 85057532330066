<div class="d-flex justify-content-between mb-3">
    <h4 mat-dialog-title>{{dialogOptions.title}}</h4>
    <button mat-icon-button (click)="cancel()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <form [formGroup]="careerFormGroup" matFormField #careerForm="ngForm" name="careerFormGroup" (ngSubmit)="save()">
        <div class="row">
            <div class="col-12">
                <p class="title-text">Job Information</p>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="name">name</p> -->
                    <input placeholder="Job Title" formControlName="name" type="text" class="form-control" id="name" name="name" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':careerForm.submitted && careerFormGroup.get('name')?.hasError('required') }"
                        aria-describedby="nameHint">
                    <div *ngIf="careerForm.submitted && careerFormGroup.get('name')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="careerFormGroup.get('name')?.hasError('required')" id="nameHint" class="form-text">name
                        is required</small>
                        <small *ngIf="careerFormGroup.get('name')?.hasError('minlength')" id="nameHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="careerFormGroup.get('name')?.hasError('name')">Please provide a valid
                            name</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="name">name</p> -->
                    <input placeholder="Job Type" formControlName="job_type" type="text" class="form-control" id="job_type" name="job_type" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':careerForm.submitted && careerFormGroup.get('job_type')?.hasError('required') }"
                        aria-describedby="job_typeHint">
                    <div *ngIf="careerForm.submitted && careerFormGroup.get('job_type')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="careerFormGroup.get('job_type')?.hasError('required')" id="mobileNumberHint" class="form-text">mobile number
                            is
                            required</small>
                        <small *ngIf="careerFormGroup.get('job_type')?.hasError('minlength')" id="job_type'Hint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="careerFormGroup.get('job_type')?.hasError('job_type')">Please provide a
                            invalid job types
                        </small>
                    </div>
                </div>
            </div> 
            <div class="col-6">
                <div class="form-group">
                    <input placeholder="Salary in GHS" formControlName="salary" type="number" class="form-control" id="salary" name="salary" required autofocus minlength="1" autocomplete="off" [ngClass]="{ 'is-invalid':careerForm.submitted && careerFormGroup.get('short_desc')?.hasError('required') }"
                    aria-describedby="salaryHint">
                    <div *ngIf="careerForm.submitted && careerFormGroup.get('salary')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="careerFormGroup.get('salary')?.hasError('required')" id="salaryHint" class="form-text">salary
                            is
                            required</small>
                        <small *ngIf="careerFormGroup.get('salary')?.hasError('minlength')" id="salaryHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="careerFormGroup.get('salary')?.hasError('salary')">Please provide a valid
                            salary</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <input placeholder="Experience in years" formControlName="experience" type="number" class="form-control" id="experience" name="experience" required autofocus minlength="1" autocomplete="off" [ngClass]="{ 'is-invalid':careerForm.submitted && careerFormGroup.get('short_desc')?.hasError('required') }"
                    aria-describedby="experienceHint">
                    <div *ngIf="careerForm.submitted && careerFormGroup.get('experience')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="careerFormGroup.get('experience')?.hasError('required')" id="experienceHint" class="form-text">experience
                            is
                            required</small>
                        <small *ngIf="careerFormGroup.get('experience')?.hasError('minlength')" id="experienceHint" class="form-text">Please
                            provide a minimum length of 1 characters</small>
                        <small *ngIf="careerFormGroup.get('experiende')?.hasError('experience')">Please provide a valid
                            experience</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group form-control pb-5">   
                      <!-- TODO: Style later -->
                        <!-- <mat-form-field class="example-full-width "  style="border: none; width: 100%;"> -->
                            <!-- <mat-label style="font-size: 14px;">Choose a date</mat-label> -->
                            <div class="row mx-0" >
                                <input formControlName="deadline" class="form-control col-8 m-0" placeholder="Choose a date" matInput [matDatepicker]="picker" style="border: none; ">
                                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker  class="col-4"></mat-datepicker>
                            </div>
                        <!-- </mat-form-field>                         -->
                    </div>
             </div>            
            <div class="col-6">
                <div class="form-group">
                    <mat-select class="form-control" placeholder="Choose availability" formControlName="availability" [(value)]="selected">
                        <mat-option value="true" >
                            Available
                        </mat-option>
                        <mat-option value="false">
                            Not Available
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <input placeholder="Short description " formControlName="short_desc" type="text" class="form-control" id="short_desc" name="short_desc" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':careerForm.submitted && careerFormGroup.get('short_desc')?.hasError('required') }"
                        aria-describedby="short_descHint">
                    <div *ngIf="careerForm.submitted && careerFormGroup.get('short_desc')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="careerFormGroup.get('short_desc')?.hasError('required')" id="short_descHint" class="form-text">ID number
                            is
                            required</small>
                        <small *ngIf="careerFormGroup.get('short_desc')?.hasError('minlength')" id="short_descHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="careerFormGroup.get('short_desc')?.hasError('short_desc')">Please provide a valid
                            short desc </small>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <angular-editor id="long_desc" formControlName="long_desc" [config]="editorConfig"></angular-editor>
            </div>
            <div class="col-12">
                <angular-editor  id="requirements" [placeholder]="'Enter job requirements'" formControlName="requirements" [config]="editorConfig"></angular-editor>               
            </div>
            <div class="col-12 mb-3">
                <angular-editor  id="responsibilities" [placeholder]="'Enter job responsibilities'" formControlName="responsibilities" [config]="editorConfig"></angular-editor>
            </div>
            <div class="col-6">
                <button [disabled]="!careerForm.valid || isLoading" type="submit" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block" mat-flat-button color="primary">Save</button>
            </div>
            <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                </mat-spinner>
            </div>
        </div>
    </form>
</div>
