import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { OrderItem } from 'src/app/shared/models/order-item';
import { OrderItemDetailDialogComponent } from 'src/app/shared/components/order-item-detail-dialog/order-item-detail-dialog.component';
import { Account } from 'src/app/shared/models/account.interface';

@Component({
  selector: 'app-dispatch-riders',
  templateUrl: './dispatch-riders.component.html',
  styleUrls: ['./dispatch-riders.component.scss']
}) 
export class DispatchRidersComponent implements OnInit,OnDestroy {
  private unSubscriptioNotifier = new Subject();
  public isLoading = false;
  public orderId = '';
  public account: Account = {}

  public orderItemDispatchList = new Array<OrderItem>();
  public orderItemDispatchList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public orderItemDispatchListBehaviour: BehaviorSubject<OrderItem[]>;

  constructor(private router: Router,
    private backend: BackendService,
    private appMaterialComponent: AppMaterialDesignModule,
    public dataSource: DataService) { 
      this.orderItemDispatchListBehaviour = new BehaviorSubject([])
      this.orderItemDispatchList$ = this.orderItemDispatchListBehaviour.asObservable();
      let t = this.orderItemDispatchList$
      console.log( )
    }

  ngOnInit(): void {
    this.account = JSON.parse(localStorage.getItem('account'))
    this.getDeliveredOrders();
  }

  public getDeliveredOrders() {
    this.isLoading = true;
    this.backend.getDispatchKitchentOrders(this.account.id)
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe(actionArray => {
        this.isLoading = false
        let tempOrderItems = actionArray.map((item: { payload: { doc: { id: any; data: () => OrderItem; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as OrderItem
        })

        this.orderItemDispatchList = tempOrderItems.filter(i => {
          return ['Dispatched', 'Delivered'].includes(i.status);  
        });
        this.orderItemDispatchListBehaviour.next(this.orderItemDispatchList);
      })
  }
  
  public openOrderItemDetailDialog(orderId: string) {
    this.dataSource.setData(orderId)
    this.appMaterialComponent.openDialog(OrderItemDetailDialogComponent, {
      width: '700px',
      title: 'Order Detail',
      message: 'Order Detail'
    }).pipe(switchMap(result => {
      if (result.text == 'process') {
        return this.backend.updateOrderStatus(orderId, this.account.id, 'Processing')
      } else if (result.text == 'cancel') {
        return this.backend.updateOrderStatus(orderId, this.account.id, 'Cancelled')
      }
    }), takeUntil(this.unSubscriptioNotifier),
    ).subscribe()
  }

  public signOut() {
    localStorage.setItem('account', null)
    //this.firebaseAuth.signOut().then(r => { });
    this.router.navigate([`account/login`], { queryParams: {}, skipLocationChange: false });
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }

}
