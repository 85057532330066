import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { Career } from 'src/app/shared/models/career.interface';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { DialogButton } from 'src/app/shared/services/dialog.service';
import { CareersDialogComponent } from 'src/app/shared/components/careers-dialog/careers-dialog.component'

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit, OnDestroy {
  private unSubscriptioNotifier = new Subject();
  public isLoading = false;

  public careerList = new Array<Career>();
  public careerList$: Observable<Career[]> = new Observable<Career[]>();
  public careerListBehaviour: BehaviorSubject<Career[]>;

  constructor(
    private router: Router,
    private backend: BackendService,
    private firebaseAuth: AngularFireAuth,
    private appMaterialComponent: AppMaterialDesignModule,
    public dataSource: DataService
    )

    {
      this.careerListBehaviour = new BehaviorSubject([{}]);
      this.careerList$ = this.careerListBehaviour.asObservable();
    }

  ngOnInit(): void {
    this.getCareerList();
  }

  public signOut() {
    localStorage.setItem('account', null)
    //this.firebaseAuth.signOut().then(r => { });
    this.router.navigate([`account/login`], { queryParams: {}, skipLocationChange: false });
  }

  public openCareerDialog(career: Career, title='Create Job' ) {
    this.dataSource.setData(career)
    this.appMaterialComponent.openDialog(CareersDialogComponent, {
      width: '750px',
      title: title,
      message: title,
      data: career
    }).pipe(takeUntil(this.unSubscriptioNotifier),
    ).subscribe({
      next: (result) => {
        if (result.button == DialogButton.ok) {
          this.getCareerList()
        }
      }
    })
  }

  getCareerList(){
    this.isLoading = true;
    this.backend.getCareerList()
    .pipe(takeUntil(this.unSubscriptioNotifier))
    .subscribe({
      next: (actionArray) => {
        this.isLoading = false
        if (actionArray.length > 0) {
          let jobs = actionArray.map((item: { payload: { doc: { id: any; data: () => Career;  }; }; }) => {         
            let data = item.payload.doc.data()
            delete data.id;
            return {
              id: item.payload.doc.id,
              ...data,
             } as Career         
          })
          // console.log(jobs)
          this.careerList = jobs;
          this.careerListBehaviour.next(jobs);
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
  }
}
