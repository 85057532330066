import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { OrderItem } from 'src/app/shared/models/order-item';
import { PaymentInfo } from 'src/app/shared/models/payment-info';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';

@Component({
  selector: 'app-order-item-details',
  templateUrl: './order-item-details.component.html',
  styleUrls: ['./order-item-details.component.scss']
})
export class OrderItemDetailsComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public orderItem: OrderItem = {}
  public paymentInfo: PaymentInfo = {}
  private orderId: string
  private unSubscriptioNotifier = new Subject();

  constructor(private router: Router,
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private appMaterialComponent: AppMaterialDesignModule,
    private _route: ActivatedRoute,
    private dataSource: DataService) { }

  ngOnInit(): void {
    this.isLoading = true;
    let h = this._route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.orderId = params.get('id')
        return combineLatest(
          [
            this.backend.getOrderItem(this.orderId),
            this.backend.getPaymentInfo(this.orderId)
          ]
        ).pipe(takeUntil(this.unSubscriptioNotifier))
      }));
    h.subscribe({
        next: (orderAndPayment) => {
          this.isLoading = false
          console.log('order : ' + JSON.stringify(orderAndPayment))
          if(null==orderAndPayment[0]){
            this.router.navigate(['home'], { queryParams: {tab:1}, skipLocationChange: false });
          }else{
            this.orderItem = orderAndPayment[0];
            this.paymentInfo = orderAndPayment[1];
          }
          
        }, error: (error) => {
          this.isLoading = false
        }
      })
  }

  public getOrderItem() {
    this.backend.getOrderItem(this.orderId).subscribe({
      next: (order) => {
        console.log('order : ' + JSON.stringify(order))
      }
    })
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
  }

}
