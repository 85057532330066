import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { DispatchRider } from '../../models/dispatch-rider.interface';
import { BackendService } from '../../services/backend.service';
import { DialogButton, DialogOptions, DialogService } from '../../services/dialog.service';
import { AssignDispatchDialogComponent } from '../assign-dispatch-dialog/assign-dispatch-dialog.component';

@Component({
  selector: 'app-dispatch-rider-dialog',
  templateUrl: './dispatch-rider-dialog.component.html',
  styleUrls: ['./dispatch-rider-dialog.component.scss']
})
export class DispatchRiderDialogComponent implements OnInit, OnDestroy {
  public isLoading = false;
  private unSubscriptioNotifier = new Subject();
  public dispatchRiderFormGroup: FormGroup;
  public dispatchRider: DispatchRider;

  constructor(public dialogRef: MatDialogRef<AssignDispatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions,
    private backend: BackendService,
    private dialogService: DialogService,
    private matIconRegistry: MatIconRegistry,) { 

    this.dispatchRider = dialogOptions.data || {};
    this.dispatchRiderFormGroup = new FormGroup({
      id: new FormControl(this.dispatchRider.id, [Validators.nullValidator]),
      name: new FormControl(this.dispatchRider.name, [Validators.required,Validators.minLength(5)]),
      idType: new FormControl(this.dispatchRider.idType, [Validators.required]),
      idNumber: new FormControl(this.dispatchRider.idNumber, [Validators.required,Validators.maxLength(14), Validators.minLength(10)]),
      mobileNumber: new FormControl(this.dispatchRider.mobileNumber, [Validators.required,Validators.maxLength(13), Validators.minLength(10)]),
      nameOfKin: new FormControl(this.dispatchRider.nameOfKin, [Validators.required]),
      mobileNumberOfKin: new FormControl(this.dispatchRider.mobileNumberOfKin, [Validators.required,Validators.maxLength(13), Validators.minLength(10)])
    });

  }
  ngOnInit(): void {
  }

  public cancel(): void {
    this.isLoading = false;
    this.dialogService.sendMessage({ text: 'cancel button clicked', button: DialogButton.cancel });
    this.dialogRef.close();
  }

  public done(){
    this.dialogService.sendMessage({ text: 'ok button clicked', button: DialogButton.ok });
    this.dialogRef.close();
  }

  public save(){
    // let num;
    // if (num.startsWith('0')) {
    //   num = num.slice(1, num.length)
    //   num = '+233' + num
    // }

    // if (num.startsWith('233')) {
    //   num = num.slice(3, num.length)
    //   num = '+233' + num
    // }

    // if (num.startsWith('+')) {
    //   num = num.slice(4, num.length)
    //   num = '+233' + num
    // }
    
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
  }

}
