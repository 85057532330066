<div class="d-flex justify-content-between mb-3">
    <h4 mat-dialog-title>{{dialogOptions.title}}</h4>
    <button mat-icon-button (click)="cancel()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <form [formGroup]="notificationFormGroup" #notificationForm="ngForm" name="notificationFormGroup" (ngSubmit)="save()">
        <div class="row">
            <div class="col">
                <div class="d-flex align-items-center" style="border: 1px dashed #cacaca; border-radius: 8px; width: 250px; height: 250px; background-color: #f0f0f0; vertical-align:middle; text-align: center;">
                    <div> 
                        <!-- TODO: reimplement this using input -->
                        <!-- <button mat-icon-button >
                            <mat-icon svgIcon="upload-photo-icon" style="width: 100%px !important; height: 100px !important;"></mat-icon>
                        </button> -->
                        <p style="font-size: 12px;" class="sub-text">Click here to upload a picture or drag and drop a picture here.</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="title">name</p> -->
                    <input placeholder="Title" formControlName="title" type="text" class="form-control" id="title" name="title" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':notificationForm.submitted && notificationFormGroup.get('title')?.hasError('required') }"
                        aria-describedby="titleHint">
                    <div *ngIf="notificationForm.submitted && notificationFormGroup.get('title')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="notificationFormGroup.get('title')?.hasError('required')" id="titleHint" class="form-text">title
                            is required</small>
                        <!-- <small *ngIf="notificationFormGroup.get('title')?.hasError('minlength')" id="titleHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="notificationFormGroup.get('title')?.hasError('title')">Please provide a valid
                            title</small> -->
                    </div>
                </div>      
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="title">name</p> -->
                    <input placeholder="Image url" formControlName="image" type="text" class="form-control" id="image" name="image" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':notificationForm.submitted && notificationFormGroup.get('image')?.hasError('required') }"
                        aria-describedby="titleHint">
                    <div *ngIf="notificationForm.submitted && notificationFormGroup.get('image')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="notificationFormGroup.get('image')?.hasError('required')" id="titleHint" class="form-text">image url
                            is required</small>
                        <!-- <small *ngIf="notificationFormGroup.get('title')?.hasError('minlength')" id="titleHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="notificationFormGroup.get('title')?.hasError('title')">Please provide a valid
                            title</small> -->
                    </div>
                </div>    
                <div class="form-group">
                    <textarea placeholder="Content" class="form-control" formControlName="content" id="content" rows="4" style="padding: 12px; font-size: 15px;"></textarea>
                </div>
                <button [disabled]="!notificationForm.valid || isLoading" type="submit" style="font-size: 18px; font-family: avenir-roman;" class="btn float-right" mat-flat-button color="primary">Send</button>
                <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                    <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                    </mat-spinner>
                </div>
            </div>
        </div>

    </form>
</div>