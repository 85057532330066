<div class="d-flex justify-content-between mb-3">
    <h4 mat-dialog-amount style="font-family: avenir-roman;">New expenses</h4>
    <button mat-icon-button (click)="cancel()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <form [formGroup]="expensesFormGroup" #expensesForm="ngForm" name="expensesFormGroup" (ngSubmit)="save()">            
            <div class="col">
                <div class="form-group">
                    <!-- <p class="subamount-text" for="amount">name</p> -->
                    <div class="input-group">
                        <div class="input-group-text " style="background-color: white; border-right: 0px; border-radius:0.25rem 0 0 0.25rem; font-family: avenir-roman;">GHS</div>
                        <input placeholder="Enter amount" formControlName="amount" type="number" min="0" class="form-control" id="amount autoSizingInputGroup" name="amount" required autofocus minlength="2" autocomplete="off" [ngClass]="{ 'is-invalid':expensesForm.submitted && expensesFormGroup.get('amount')?.hasError('required') }"
                            aria-describedby="amountHint" style="border-left: 0px;">
                    </div>
                    <div *ngIf="expensesForm.submitted && expensesFormGroup.get('amount')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="expensesFormGroup.get('amount')?.hasError('required')" id="amountHint" class="form-text">amount
                            is required</small>
                        <small *ngIf="expensesFormGroup.get('amount')?.hasError('minlength')" id="amountHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="expensesFormGroup.get('amount')?.hasError('amount')">Please provide a valid
                            amount</small> 
                    </div>
                </div>               
                <div class="form-group">
                    <textarea placeholder="Description" class="form-control" formControlName="description" id="description" rows="4" style="padding: 12px; font-size: 15px;"></textarea>
                    <div *ngIf="expensesForm.submitted && expensesFormGroup.get('amount')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="expensesFormGroup.get('description')?.hasError('required')" id="descriptionHint" class="form-text">description
                            is required</small>
                        <small *ngIf="expensesFormGroup.get('description')?.hasError('minlength')" id="descriptionHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="expensesFormGroup.get('description')?.hasError('description')">Please provide a valid
                            description</small>
                    </div>
                </div>
                <button [disabled]="!expensesForm.valid || isLoading" type="submit" style="font-size: 18px; font-family: avenir-roman;" class="btn float-right" mat-flat-button color="primary">Save</button>
                <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                    <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                    </mat-spinner>
                </div>
            </div>
    </form>
</div>