<div class="container" style="height: 100vh;">
    <h3 class="text-center title-text pt-5">Sign in to view your dashboard.</h3>
    <div class="row align-items-center h-100">
        <div class="col-md-4 offset-md-4 mx-auto">
            <div class="d-flex justify-content-center mb-5" style="margin-top: -200px;">
                <img src="/assets/icons/app_logo.svg" height="100" width="100" alt="233bit logo">
            </div>
            <form [formGroup]="loginFormGroup" #loginForm="ngForm" name="loginFormGroup" (ngSubmit)="loginForm.valid && login()">
                <div class="form-group">
                    <input placeholder="username" formControlName="username" type="text" class="form-control" id="username" name="username" required autofocus minlength="4" autocomplete="off" [ngClass]="{ 'is-invalid':loginForm.submitted && loginFormGroup.get('username')?.hasError('required') }"
                        aria-describedby="usernameHint">
                    <div *ngIf="loginForm.submitted && loginFormGroup.get('username')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="loginFormGroup.get('username')?.hasError('required')" id="usernameHint" class="form-text">username
                            is
                            required</small>
                        <small *ngIf="loginFormGroup.get('username')?.hasError('minlength')" id="usernameHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="loginFormGroup.get('username')?.hasError('username')">Please provide a valid
                            username</small>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="password">password</p> -->
                    <input placeholder="password" formControlName="password" type="password" class="form-control" id="password" name="password" required autofocus minlength="4" autocomplete="off" [ngClass]="{ 'is-invalid':loginForm.submitted && loginFormGroup.get('password')?.hasError('required') }"
                        aria-describedby="passwordHint">
                    <div *ngIf="loginForm.submitted && loginFormGroup.get('password')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="loginFormGroup.get('password')?.hasError('required')" id="passwordHint" class="form-text">password
                            is
                            required</small>
                        <small *ngIf="loginFormGroup.get('password')?.hasError('minlength')" id="passwordHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="loginFormGroup.get('password')?.hasError('password')">Please provide a valid password</small>
                    </div>
                </div>
                <!-- <div id="recaptcha-container"></div> -->
                <button type="submit" [disabled]="isLoading || !loginForm.valid" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block mt-2" mat-flat-button color="primary">Login</button>
                <div class="d-flex justify-content-center mt-2 mb-3">
                    <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                    </mat-spinner>
                </div>
            </form>
        </div>
    </div>
</div>