<div class="container-fluid p-0 m-0" style="height: 100vh; background-color: white; max-width: 100%; overflow-x: hidden;">
    <div class="row">
        <div class="swiper-container pr-3 pl-3 col-md-4 offset-md-4" [swiper]="swiperConfig" (indexChange)="onPageIndexChange($event)">
            <div class="swiper-wrapper">
                <div class="swiper-slide" [attr.data-swiper-slide-index]="1">
                    <div class="food-item-banner mt-0">
                        <img class="food-item-banner-mage img-fluid" src="{{cart.foodItem?.display_image_banner}}">
                        <div style="position: absolute; top: 0;" class="col-4 mt-3 align-items-start">
                            <a routerLink="/home" mat-icon-button>
                                <mat-icon style="color:white">arrow_back_outline</mat-icon>
                            </a>
                        </div>
                    </div>
                    <div class="p-4">
                        <h3 class="title-text title-text-color">{{cart.foodItem?.name}}</h3>
                        <h4 class="title-text title-text-color">{{'GHS '+ cart.foodItem?.cost+' '}}<small class="subtitle-text subtitle-text-color">per pack</small></h4>
                        <p class="mt-0 mb-1 subtitle-text">{{cart.foodItem?.description}}</p>
                    </div>
                    <mat-divider class="pt-2 pb-1"></mat-divider>
                    <div class="p-4">
                        <div class="mr-5 ml-5 d-flex justify-content-between">
                            <button (click)="removeQuantity();" mat-icon-button color="primary">
                                <mat-icon style="font-size: 38px; margin-right: 13px;" color="primary">
                                    remove_circle_outline</mat-icon>
                            </button>
                            <h1 class="title-text title-text-color">{{cart.quantity}}</h1>
                            <button (click)="addQuantity();" mat-icon-button color="primary">
                                <mat-icon style="font-size: 38px; margin-right: 13px;" color="primary">
                                    add_circle_outline</mat-icon>
                            </button>
                        </div>
                        <p class="mt-3 mb-1 text-center subtitle-text">{{'Total cost is GHS '+cart?.total_cost}}</p>
                        <button (click)="gotoCheckout()" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block mt-3" mat-flat-button color="primary">Proceed To Checkout</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>