<div class="container" style="height: 100vh; ">
    <div class="d-flex justify-content-between pt-2 pb-2">
        <h3 class="title-text">Sales</h3>
        <!-- <h3>{{account?.name}}</h3> -->
        <div>
            <button (click)="openExpensesDialog({})" style="font-size: 15px; font-family: avenir-roman; background-color: #FFD36A;" class="btn" mat-flat-button>New expense</button>
            <button (click)="signOut()" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Sign Out</button>
        </div>
       
    </div>
    
    <div>

        <div class="col d-flex justify-content-between mt-3 mb-5 " style="max-height: 250px; font-size: 15px; font-family: avenir-roman;">
            <div class="mr-1 center bg-white p-3" style="border-radius: 8px; width: 20%;">
                <h6 class="mb-2 p-2">All time sales</h6>
                <h4 class="title-text">GHS {{ formatAmount(totalSales) }} </h4>
            </div>
            <div class="mr-1 center bg-white p-3"style="border-radius: 8px; ">    
                <div class="d-flex justify-content-between" > 
                    <h6 class="mr-2 pt-2 align-items-center" style="font-family: avenir-roman;">Sales  </h6>
                    <div class="form-group align-items-center" style="width: 125px;">
                       <!-- <span><mat-label >Filter by: </mat-label> </span>  -->
                        <mat-select [(value)]="selectedSales" class="form-control">
                            <mat-option *ngFor="let option of salesOptions" [value]="option.value">
                                {{option.viewValue}}
                              </mat-option>
                        </mat-select>
                    </div>
                </div> 
                <h4 class="title-text">GHS {{ formatAmount(totalSales) }} </h4>
                <p> <mat-icon style="color: green; position: relative; top: 10px;">arrow_drop_up</mat-icon> 5% (GHS {{ formatAmount(totalSales * 0.05) }})</p>
            </div>
            <div class="mr-1 center bg-white p-3" style="border-radius: 8px; "> 
                <h6 class="mb-2 p-2">All time expenses</h6>
                <h4 class="title-text">GHS {{ formatAmount(totalExpenses) }}</h4>
            </div>
            <div class="mr-1 center bg-white p-3 " style="border-radius: 8px;">
                <div class="d-flex justify-content-between align-items-center mb-2" style="padding: 2px;">
                    <h6 class="mr-4" style="font-family: avenir-roman;">Expenses </h6>
                    <div class="form-group align-items-center" style="width: 125px;">
                        <!-- <span><mat-label >Filter by: </mat-label> </span>  -->
                         <mat-select [(value)]="selectedExpense" class="form-control">
                            <mat-option *ngFor="let option of salesOptions" [value]="option.value">
                                {{option.viewValue}}
                              </mat-option>
                         </mat-select>
                     </div>
                </div>
                <h4 class="title-text">GHS {{ formatAmount(totalExpenses) }}</h4>
                <p> <span class=""> <mat-icon style="color: red; position: relative; top: 5px;">arrow_drop_down</mat-icon> </span>  5% (GHS {{ formatAmount(totalExpenses * 0.05) }})</p>
            </div> 
        </div>
        <div class="d-block justify-content-between mt-2 mb-5" >
            <h3 class="title-text">Sales vs Expenses</h3>
            <div class=" mt-2 mb-5 bg-white" style="max-height: 500px;">
                <app-chart> </app-chart>                        
            </div>           
        </div>

        <div class="row mt-2">
            <div class="col-md">
                    
                <div class="col d-flex justify-content-between align-items-center mb-2 pt-2">
                    <h3 class="title-text">Recent transactions</h3> 

                    <div class="d-flex" > 
                        <span class="mr-2 pt-2 align-items-center" style="font-family: avenir-roman;">Filter by </span>
                        <div class="form-group align-items-center" style="width: 200px;">
                           <!-- <span><mat-label >Filter by: </mat-label> </span>  -->
                            <mat-select [(value)]="selected" (selectionChange)="filterHandler($event)" class="form-control">
                                <mat-option *ngFor="let option of options" [value]="option.value">
                                    {{option.viewValue}}
                                  </mat-option>
                            </mat-select>
                        </div>
                    </div>                   
                </div>
                
                <div class="table-responsive bg-white" style="max-height: 620px; ">
                    <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm" >
                        <thead>
                            <tr>                                
                                <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                                Type </th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Description</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Amount</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="animate__animated animate__fadeIn" *ngFor="let item of itemList$ | async; let i=index;" style="border: .5px solid #EDEDED; font-size: 15px;">                        
                                <td *ngIf="item.currency" class="align-middle pt-2" >
                                    <mat-icon style="background-color: rgba(165, 247, 165, 0.5); border-radius: 50%; color: green;" class="align-middle">arrow_upward</mat-icon>
                                    <span class="pl-2 center">  &nbsp; Sales </span>
                                </td>
                                <td *ngIf="!item.currency" class="align-middle pt-2" >
                                    <mat-icon style="background-color: rgba(247, 99, 99, 0.4); border-radius: 50%; color: red;" class="align-middle">arrow_downward</mat-icon>
                                    <span class="pl-2"> &nbsp; Expenses</span> 
                                </td>

                                <td *ngIf='item.description' class="align-middle">{{ item.description }}</td>
                                <td *ngIf='!item.description' class="align-middle">Payment</td>
                                <td class="align-middle">{{ formatAmount(item.amount) }} </td>
                                <td class="align-middle">{{ formatDate(item.date) }}</td>                                                                    
                            </tr >
                        </tbody>
                    </table>
                </div>                               
            </div>
        </div>
    </div>        
    <div class="col-md-12 d-flex justify-content-center mt-5 align-middle" *ngIf="itemList.length < 1"> 
        <p style="font-family: avenir-roman; font-weight: 300; font-size: 20px;">No sales and expenses made yet</p>
    </div>
    <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
        <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
        </mat-spinner>
    </div>
</div>

