<div class="container-fluid p-0 m-0" style="height: 100vh; background-color: white; max-width: 100%; overflow-x: hidden;" [ngClass]="{ 'bg-pre':tab==0,'bg-white':tab==1, 'bg-pr':tab==2 }">
    <div class="row">
        <div class="swiper-container pr-4 pl-4 col-md-4 offset-md-4" [swiper]="swiperConfig" (indexChange)="onPageIndexChange($event)">
            <div class="swiper-wrapper">
                <div [ngClass]="{ 'bg-pre':tab==0,'bg-white':tab==1}" class="swiper-slide" [attr.data-swiper-slide-index]="3">
                    <div class="row page-header">
                        <div class="col-4 mt-3 align-items-">
                            <a [routerLink]="['/home']" [queryParams]="{tab: '1'}" (click)="clearCart();" mat-icon-button color="primary">
                                <mat-icon style="color: white;">close</mat-icon>
                            </a>
                        </div>
                        <div class="col-8 mt-4">
                            <h4 class="col-6 mt-2 title-text color-white" style="font-size: 20px;">Error</h4>
                        </div>
                    </div>
                    <div class="mt-3 col-md-12 d-flex justify-content-center mt-3 mb-3">
                        <div style="height: 70px; width: 70px; color: white; border-radius: 35px; border: .4px solid #e9c05a; background-color: #e9c05a;">
                            <mat-icon style="font-size: 48px; margin-top: 8px; margin-left: 8px;">close</mat-icon>
                        </div>
                    </div>
                    <p class="title-text text-center color-white">Payment Unsuccessful</p>

                    <p class="mt-5 title-text text-center color-white">Order Number</p>
                    <p class="sub-text text-center color-white">{{cart?.reference}}</p>

                    <p class="sub-text text-center color-white">{{'Please try another payment method'}}</p>
                </div>
                <div class="swiper-slide" [attr.data-swiper-slide-index]="1">
                    <div class="row page-header">
                        <div class="col-4 mt-3 align-items-">
                            <a routerLink="/order/checkout" mat-icon-button color="primary">
                                <mat-icon>arrow_back</mat-icon>
                            </a>
                        </div>
                        <div class="col-8 mt-4">
                            <h4 class="col-6 mt-2 title-text" style="font-size: 20px;">Payment</h4>
                        </div>
                    </div>
                    <!-- <mat-divider></mat-divider> -->
                    <div class="pl-3 pr-3 mt-3">
                        <h5 class="title-text subtitle-text-color">Payment Info</h5>
                        <!-- <p class="subtitle-text">Email</p> -->
                        <form [formGroup]="paymentInfoFormGroup" #paymentInfoForm="ngForm" name="paymentInfoFormGroup">
                            <div class="form-group">
                                <!-- <p class="subtitle-text" for="fullname">Fullname</p> -->
                                <input placeholder="Fullname" formControlName="fullname" type="text" class="form-control" id="fullname" name="fullname" required minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':paymentInfoForm.submitted && paymentInfoFormGroup.get('fullname')?.hasError('required') }"
                                    aria-describedby="fullnameHint">
                                <div *ngIf="paymentInfoForm.submitted && paymentInfoFormGroup.get('fullname')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="paymentInfoFormGroup.get('fullname')?.hasError('required')" id="fullnameHint" class="form-text">fullname
                                        is
                                        required</small>
                                    <small *ngIf="paymentInfoFormGroup.get('fullname')?.hasError('minlength')" id="fullnameHint" class="form-text">Please
                                        provide a minimum length of 5 characters</small>
                                    <small *ngIf="paymentInfoFormGroup.get('fullname')?.hasError('fullname')">Please
                                        provide a valid fullname</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- <p class="subtitle-text" for="email">email</p> -->
                                <input placeholder="Email" formControlName="email" type="text" class="form-control" id="email" name="email" required minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':paymentInfoForm.submitted && paymentInfoFormGroup.get('email')?.hasError('required') }"
                                    aria-describedby="emailHint">
                                <div *ngIf="paymentInfoForm.submitted && paymentInfoFormGroup.get('email')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="paymentInfoFormGroup.get('email')?.hasError('required')" id="emailHint" class="form-text">email
                                        is
                                        required</small>
                                    <small *ngIf="paymentInfoFormGroup.get('email')?.hasError('minlength')" id="emailHint" class="form-text">Please
                                        provide a minimum length of 5 characters</small>
                                    <small *ngIf="paymentInfoFormGroup.get('email')?.hasError('email')">Please provide a
                                        valid email</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- <p class="subtitle-text" for="mobileNumber">mobileNumber</p> -->
                                <input placeholder="+233 Enter your phone number" formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber" name="mobileNumber" required minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':paymentInfoForm.submitted && paymentInfoFormGroup.get('mobileNumber')?.hasError('required') }"
                                    aria-describedby="mobileNumberHint">
                                <div *ngIf="paymentInfoForm.submitted && paymentInfoFormGroup.get('mobileNumber')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="paymentInfoFormGroup.get('mobileNumber')?.hasError('required')" id="mobileNumberHint" class="form-text">mobileNumber
                                        is
                                        required</small>
                                    <small *ngIf="paymentInfoFormGroup.get('mobileNumber')?.hasError('minlength')" id="mobileNumberHint" class="form-text">Please
                                        provide a minimum length of 5 characters</small>
                                    <small *ngIf="paymentInfoFormGroup.get('mobileNumber')?.hasError('mobileNumber')">Please
                                        provide a valid mobileNumber</small>
                                </div>
                            </div>
                            <!-- <button [disabled]="!paymentInfoForm.valid || isLoading" type="submit" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block" mat-flat-button color="primary">Save</button> -->
                        </form>

                        <h5 class="title-text subtitle-text-color mt-3">Payment</h5>
                        <p class="mt-2 mb-1 sub-text">Choose your preferred payment method</p>

                       <mat-radio-group class="radio-group" [formControl]="paymentMethodFormControl">
                            <mat-radio-button class="radio-button" value="Mobile Money">
                                Mobile Money
                            </mat-radio-button>
                            <mat-radio-button class="radio-button" value="Card">
                                Debit/Credit Card
                            </mat-radio-button>
                        </mat-radio-group>
                        <!-- <button [disabled]="!paymentMethodFormControl.valid || isLoading" (click)="placeOrder()" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block mt-3 mb-5" mat-flat-button color="primary">Place Order</button> -->

                        <button angular4-paystack [email]="paymentInfoFormGroup.get('email').value" [amount]="cart.total_cost*100" [ref]="cart.reference" [currency]="'GHS'" class="btn btn-block" mat-flat-button color="primary" (paymentInit)="paymentInit()" (onClose)="paymentCancel()"
                            (callback)="paymentDone($event)" [metadata]="paymentMeta">
                            {{'Pay GHS '+dataSource.formatAmount(cart.total_cost)}}
                        </button>
                        <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                            </mat-spinner>
                            <small class="sub-text text-center mt-3">Powered by <strong>Paystack</strong></small>
                        </div>
                    </div>
                </div>
                <div style="background-color: #155264;" class="swiper-slide" [attr.data-swiper-slide-index]="2">
                    <div class="row page-header">
                        <div class="col-4 mt-3 align-items-">
                            <a [routerLink]="['/home']" [queryParams]="{tab: '1'}" (click)="clearCart();" mat-icon-button color="primary">
                                <mat-icon style="color: white;">close</mat-icon>
                            </a>
                        </div>
                        <div class="col-8 mt-4">
                            <h4 class="col-6 mt-2 title-text color-white" style="font-size: 20px;">Success</h4>
                        </div>
                    </div>
                    <div class="mt-3 col-md-12 d-flex justify-content-center mt-3 mb-3">
                        <div style="height: 70px; width: 70px; color: white; border-radius: 35px; border: .4px solid #0C4758; background-color: #0C4758;">
                            <mat-icon style="font-size: 48px; margin-top: 8px; margin-left: 8px;">check</mat-icon>
                        </div>
                    </div>
                    <p class="title-text text-center color-white">Order Successfully Placed</p>

                    <p class="mt-5 title-text text-center color-white">Order Number</p>
                    <p class="sub-text text-center color-white">{{cart?.reference}}</p>

                    <p class=" mt-5 title-text text-center color-white">Order Date</p>
                    <p class="sub-text text-center color-white">{{cart?.orderItem?.created_date | date}}</p>
                </div>
            </div>
        </div>
    </div>
</div>