<div>
    <canvas baseChart 
        [datasets]="lineChartData" 
        [labels]="lineChartLabels" 
        [options]="lineChartOptions"
        [colors]="lineChartColors" 
        [legend]="lineChartLegend" 
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins"
    >
    </canvas>
</div>