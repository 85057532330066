<div class="container-fluid primary-bg-color" style="height: 100vh;">
    <div class="row">
        <div id="recaptcha-container"></div>
        <div class="swiper-container pr-3 pl-3 col-md-4 offset-md-4" [swiper]="swiperConfig">
            <div class="swiper-wrapper mb-1">
                <div class="swiper-slide" [attr.data-swiper-slide-index]="1">
                    <div class="d-flex justify-content-center" style="margin-top: 40px;">
                        <img src="/assets/icons/app_logo.svg" height="100" width="100" alt="233bit logo">
                    </div>
                    <div class="mt-4">
                        <mat-tab-group mat-align-tabs="start" mat-align-tabs="center" class="remove-border-bottom" [selectedIndex]="tabIndex">
                            <mat-tab (click)="switchTab(0)" label="Sign up" style="font-family: avenir-roman;">
                                <div class="mt-2 p-3">
                                    <p class="subtitle-text text-center" style="font-size: 18px;">Sign up 2 easy steps. Provide the details below to sign up.</p>
                                    <form [formGroup]="signupFormGroup" #signupForm="ngForm" name="signupFormGroup" (ngSubmit)="signup()">
                                        <div class="form-group">
                                            <!-- <p class="subtitle-text" for="fullname">Fullname</p> -->
                                            <input placeholder="Full name" formControlName="fullname" type="text" class="form-control" id="fullname" name="fullname" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':signupForm.submitted && signupFormGroup.get('fullname')?.hasError('required') }"
                                                aria-describedby="fullnameHint">
                                            <div *ngIf="signupForm.submitted && signupFormGroup.get('fullname')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                                                <small *ngIf="signupFormGroup.get('fullname')?.hasError('required')" id="fullnameHint" class="form-text">fullname
                                                    is
                                                    required</small>
                                                <small *ngIf="signupFormGroup.get('fullname')?.hasError('minlength')" id="fullnameHint" class="form-text">Please
                                                    provide a minimum length of 5 characters</small>
                                                <small *ngIf="signupFormGroup.get('fullname')?.hasError('fullname')">Please provide a valid fullname</small>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <!-- <p class="subtitle-text" for="email">email</p> -->
                                            <input placeholder="Email" formControlName="email" type="text" class="form-control" id="email" name="email" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':signupForm.submitted && signupFormGroup.get('email')?.hasError('required') }"
                                                aria-describedby="emailHint">
                                            <div *ngIf="signupForm.submitted && signupFormGroup.get('email')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                                                <small *ngIf="signupFormGroup.get('email')?.hasError('required')" id="emailHint" class="form-text">email
                                                    is
                                                    required</small>
                                                <small *ngIf="signupFormGroup.get('email')?.hasError('minlength')" id="emailHint" class="form-text">Please
                                                    provide a minimum length of 5 characters</small>
                                                <small *ngIf="signupFormGroup.get('email')?.hasError('email')">Please provide a valid email</small>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <!-- <p class="subtitle-text" for="mobileNumber">mobileNumber</p> -->
                                            <input placeholder="+233 Enter your phone number" formControlName="mobileNumber" type="tel" class="form-control" id="mobileNumber" name="mobileNumber" required autofocus minlength="10" autocomplete="off" [ngClass]="{ 'is-invalid':signupForm.submitted && signupFormGroup.get('mobileNumber')?.hasError('required') }"
                                                aria-describedby="mobileNumberHint">
                                            <div *ngIf="signupForm.submitted && signupFormGroup.get('mobileNumber')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                                                <small *ngIf="signupFormGroup.get('mobileNumber')?.hasError('required')" id="mobileNumberHint" class="form-text">mobileNumber
                                                    is
                                                    required</small>
                                                <small *ngIf="signupFormGroup.get('mobileNumber')?.hasError('minlength')" id="mobileNumberHint" class="form-text">Please
                                                    provide a minimum length of 5 characters</small>
                                                <small *ngIf="signupFormGroup.get('mobileNumber')?.hasError('mobileNumber')">Please provide a valid mobileNumber</small>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input class="ml-2 "  type="checkbox"formControlName="checked" name="notification" id="notification" > 
                                            <span class="ml-2 subtitle-text text-center checkmark" style="font-size: 1.1rem;">To be kept  up to date on very thing</span>
                                        </div>
                                        <button [disabled]="!signupForm.valid || isLoading" type="submit" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block" mat-flat-button color="primary">Sign up</button>
                                        <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                                            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                                            </mat-spinner>
                                        </div>
                                    </form>
                                </div>
                            </mat-tab>
                            <mat-tab (click)="switchTab(0)" label="Sign in" style="font-family: avenir-roman;">
                                <div class="mt-3 p-3">
                                    <p class="subtitle-text text-center" style="font-size: 18px;">Welcome back.<br/>Enter your phone number to log in.</p>
                                    <div class="form-group">
                                        <input placeholder="+233 Enter your phone number" [formControl]="phoneNumberFormControl" type="text" class="form-control text-center" id="phoneNumber" name="phoneNumber" required minlength="10" autocomplete="off" [ngClass]="{ 'is-invalid':phoneNumberFormControl.hasError(
                                        'required')}" aria-describedby="phoneNumberHint">
                                    </div>
                                    <button (click)="login()" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block mt-2" mat-flat-button color="primary">Sign in</button>
                                </div>
                                <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                                    <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                                    </mat-spinner>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
                <div class="swiper-slide" [attr.data-swiper-slide-index]="1">
                    <div class="row">
                        <div class="d-flex align-items-start p-2 mt-2 ml-3">
                            <button (click)="navigate(0)" mat-icon-button color="primary">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <div class="col-12 d-flex justify-content-center mt-5">
                            <div class="mt-5 p-3">
                                <p class="title-text text-center title-text-color" style="font-size: 22px;">Authentication</p>
                                <p class="subtitle-text text-center subtitle-text-color" style="font-size: 18px;">A One Time Password has been sent to your phone number. Enter it below.</p>
                                <div class="form-group">
                                    <input pattern="[0-9]+" maxlength="6" placeholder="Enter One Time Password" [formControl]="otpFormControl" type="text" class="form-control text-center" id="otpFormControl" name="otpFormControl" required minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':otpFormControl.hasError(
                    'required') }" aria-describedby="otpFormControlHint">
                                </div>
                                <button (click)="verifyLoginCode()" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block mt-2" mat-flat-button color="primary">Sign in</button>
                            </div>
                        </div>
                        <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                            </mat-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>