
<div class="container-fluid" style="height: 100vh; background-color: white;">
    <div class="row pr-1 pl-1 pb-5">
        <div class="swiper-container pr-3 pl-3 col-md-4 offset-md-4" [swiper]="swiperConfig" (indexChange)="onPageIndexChange($event)">
            <div class="swiper-wrapper mb-4"> 
                <div class="swiper-slide" [attr.data-swiper-slide-index]="0">                    
                    <div class="mt-4">
                        <p class="m-0 p-0 title-text title-text-color" style="font-size: 22px;">Hi {{user.fullname}}</p>
                    </div>
                    <div class="mt-3 pb-5">
                        <div class="card-1 boarder-all-15 pl-3 pr-3" style=" background-color: #155264;">
                            <mat-form-field class="mt-1 mr-1 ml-1" hideRequiredMarker appearance="standard" style="width: 100%">
                                <mat-label style="color: #6C8A93 !important;" class="subtitle-text">Delivering To
                                </mat-label>
                                <mat-select panelClass="mat-select-panel" class="title-text" [formControl]="locationFormControl">
                                    <mat-option *ngFor="let location of locationList$ | async" [value]="location.name">
                                        <span class="color-span" style="color:#155264 !important">{{location.name}}</span>
                                    </mat-option>
                                </mat-select>
                                <mat-icon style="color: white; font-size: 28px; margin-right: 8px;" matSuffix>
                                    keyboard_arrow_down</mat-icon>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!isLoading">
                            <h4 class="mt-5 title-text text-center title-text-color" style="font-size: 22px;">What do you want to order?</h4>
                            <div class="mt-2">
                                <div matRipple *ngFor="let food of foodList$ | async;" (click)="gotoFoodItemDetails(food)" class="boarder-all-15 food-card mt-4">
                                    <div style="position: absolute; margin-top: 15%; z-index: 1000;" class="ml-3 mr-3">
                                        <p class="title-text color-white p-0 m-0" style="font-size: 24px;">{{food.name}}
                                        </p>
                                        <p class="sub-text color-white p-0 m-0" style="font-size: 15px;">
                                            {{food.description | slice:0:50}}
                                        </p>
                                    </div>
                                    <div>
                                        <img class="img-fluid" src="{{food.display_image}}" style="width: 100%; object-fit: cover;">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
                        <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                        </mat-spinner>
                    </div>
                </div>
                <div class="swiper-slide" [attr.data-swiper-slide-index]="1">
                    <h4 class="mt-4 title-text text-center" style="font-size: 22px;">My Orders</h4>
                    <mat-tab-group mat-align-tabs="start" mat-align-tabs="center" class="remove-border-bottom">
                        <mat-tab label="Active Orders" style="font-family: avenir-roman;">
                            <div matRipple *ngFor="let orderItem of activeOrderItemList$ | async;" class="boarder-all-15 order-item-card mt-3 animate__animated animate__slideInUp">
                                <a routerLink="/order-item/{{orderItem.id}}" class="media bg-white p-2" style="text-decoration: none !important;">
                                    <img class="mr-3 align-self-center" style="width: 80px; height:80px; border-radius: 5px; object-fit: cover; background-color: #155264;" src="{{orderItem.display_image}}">
                                    <div class="media-body">
                                        <h5 class="mt-0 title-text title-text-color">{{orderItem?.food_name}}</h5>
                                        <p class="mt-0 mb-1 sub-text subtitle-text-color">{{orderItem?.quantity +' packs'}}</p>
                                        <p class="mt-0 mb-1 subtitle-text title-text-color">{{'GHS '+orderItem?.total_cost}}</p>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <p class="subtitle-text subtitle-text-color mr-2">{{orderItem?.status}}</p>
                                    </div>
                                </a>
                            </div>
                            <div [hidden]="isLoading" *ngIf="(activeOrderItemList$ | async)?.length==0" class="justify-content-center text-center mt-5">
                                <img src="assets/icons/caution.svg" height="30" width="30">
                                <p class="subtitle-text">No Active Orders Yet</p>
                            </div>
                            <div class="col-md-12 d-flex justify-content-center mt-4 mb-3">
                                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                                </mat-spinner>
                            </div>
                        </mat-tab>
                        <mat-tab label="Past Orders" style="font-family: avenir-roman;">
                            <div matRipple *ngFor="let orderItem of pastOrderItemList$ | async;" class="boarder-all-15 order-item-card mt-3 animate__animated animate__slideInUp">
                                <a routerLink="/order-item/{{orderItem.id}}" class="media bg-white p-2" style="text-decoration: none !important;">
                                    <img class="mr-3 align-self-center" style="width: 80px; height:80px; border-radius: 5px; object-fit: cover; background-color: #155264;" src="{{orderItem.display_image}}">
                                    <div class="media-body">
                                        <h5 class="mt-0 title-text title-text-color">{{orderItem?.food_name}}</h5>
                                        <p class="mt-0 mb-1 sub-text subtitle-text-color">{{orderItem?.quantity +' packs'}}</p>
                                        <p class="mt-0 mb-1 subtitle-text title-text-color">{{'GHS '+orderItem?.total_cost}}</p>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <p class="subtitle-text subtitle-text-color mr-2">{{orderItem?.status}}</p>
                                    </div>
                                </a>
                            </div>
                            <div [hidden]="isLoading" *ngIf="(pastOrderItemList$ | async)?.length==0" class="justify-content-center text-center mt-5">
                                <img src="assets/icons/caution.svg" height="30" width="30">
                                <p class="subtitle-text">No Past Orders Yet</p>
                            </div>
                            <div class="col-md-12 d-flex justify-content-center mt-4 mb-3">
                                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                                </mat-spinner>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <div class="swiper-slide" [attr.data-swiper-slide-index]="2">
                    <h4 class="mt-4 title-text text-center" style="font-size: 22px;">Settings</h4>
                    <div class="boarder-all-15 p-3 settings-card mt-4 mb-4">
                        <section class="d-flex justify-content-between">
                            <p class="subtitle-text">Notification</p>
                            <mat-slide-toggle color="primary" [formControl]="notificationToggleFormControl"></mat-slide-toggle>
                        </section>
                        <p *ngIf="!notificationToggleFormControl.value" class="subtitle-text subtitle-text-color mt-3">Never show notification from this app.</p>
                        <p *ngIf="notificationToggleFormControl.value" class="subtitle-text subtitle-text-color mt-3">Show notification from this app.</p>
                    </div>
                    <div class="boarder-all-15 p-3 update-profile-card mt-2 mb-4">
                        <p class="subtitle-text">Update Profile</p>
                        <form [formGroup]="updateProfileFormGroup" #updateProfileForm="ngForm" name="updateProfileFormGroup" (ngSubmit)="updateProfile()">
                            <div class="form-group">
                                <!-- <p class="subtitle-text" for="fullname">Fullname</p> -->
                                <input placeholder="Fullname" formControlName="fullname" type="text" class="form-control" id="fullname" name="fullname" required minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':updateProfileForm.submitted && updateProfileFormGroup.get('fullname')?.hasError('required') }"
                                    aria-describedby="fullnameHint">
                                <div *ngIf="updateProfileForm.submitted && updateProfileFormGroup.get('fullname')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="updateProfileFormGroup.get('fullname')?.hasError('required')" id="fullnameHint" class="form-text">fullname
                                        is
                                        required</small>
                                    <small *ngIf="updateProfileFormGroup.get('fullname')?.hasError('minlength')" id="fullnameHint" class="form-text">Please
                                        provide a minimum length of 5 characters</small>
                                    <small *ngIf="updateProfileFormGroup.get('fullname')?.hasError('fullname')">Please provide a valid fullname</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- <p class="subtitle-text" for="email">email</p> -->
                                <input placeholder="Email" formControlName="email" type="text" class="form-control" id="email" name="email" required minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':updateProfileForm.submitted && updateProfileFormGroup.get('email')?.hasError('required') }"
                                    aria-describedby="emailHint">
                                <div *ngIf="updateProfileForm.submitted && updateProfileFormGroup.get('email')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="updateProfileFormGroup.get('email')?.hasError('required')" id="emailHint" class="form-text">email
                                        is
                                        required</small>
                                    <small *ngIf="updateProfileFormGroup.get('email')?.hasError('minlength')" id="emailHint" class="form-text">Please
                                        provide a minimum length of 5 characters</small>
                                    <small *ngIf="updateProfileFormGroup.get('email')?.hasError('email')">Please provide a valid email</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- <p class="subtitle-text" for="mobileNumber">mobileNumber</p> -->
                                <input placeholder="+233 Enter your phone number" formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber" name="mobileNumber" required minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':updateProfileForm.submitted && updateProfileFormGroup.get('mobileNumber')?.hasError('required') }"
                                    aria-describedby="mobileNumberHint">
                                <div *ngIf="updateProfileForm.submitted && updateProfileFormGroup.get('mobileNumber')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="updateProfileFormGroup.get('mobileNumber')?.hasError('required')" id="mobileNumberHint" class="form-text">mobileNumber
                                        is
                                        required</small>
                                    <small *ngIf="updateProfileFormGroup.get('mobileNumber')?.hasError('minlength')" id="mobileNumberHint" class="form-text">Please
                                        provide a minimum length of 5 characters</small>
                                    <small *ngIf="updateProfileFormGroup.get('mobileNumber')?.hasError('mobileNumber')">Please provide a valid mobileNumber</small>
                                </div>
                            </div>
                            <button [disabled]="!updateProfileForm.valid || isLoading" type="submit" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block" mat-flat-button color="primary">Save</button>
                            <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                                </mat-spinner>
                            </div>
                        </form>
                    </div>
                    <div class="boarder-all-15 p-3 update-profile-card mt-2 mb-2">
                        <button (click)="signOut()" style="font-size: 18px; font-family: avenir-roman; margin-left: 2px; margin-right: 2px;" class="btn btn-block mt-1" mat-flat-button color="primary">Sign Out</button>
                        <button (click)="deleteAccount()" style="font-size: 18px; font-family: avenir-roman; margin-left: 2px; margin-right: 2px; background-color: #b53f3f;" class="btn btn-block mt-4 color-white" mat-flat-button>Delete Account</button>
                    </div>

                    <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                        <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                        </mat-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <nav class="row navbar navbar-expand-md navbar-light bg-light fixed-bottom p-0 m-0">
        <div class="col-md-4 offset-md-4 border-top" style="background-color: white;">
            <ngx-bottom-nav>
                <button (click)="onNavigationMenuClick(0)" ngx-bottom-nav label="Menu" exact="true" style="margin-right: 20px; margin-left: 20px; ">
                    <mat-icon svgIcon="inactive-menu-icon" ngxBottomNavIcon></mat-icon>
                </button>
                <button (click)="onNavigationMenuClick(1);" ngx-bottom-nav label="Orders" style="margin-right: 20px; margin-left: 20px;">
                    <mat-icon style="transform: rotate(-0deg); margin-left: 5px" svgIcon="inactive-orders" ngxBottomNavIcon>
                    </mat-icon>
                </button>
                <button (click)="onNavigationMenuClick(2);" ngx-bottom-nav label="Settings" style="margin-right: 20px; margin-left: 20px;">
                    <mat-icon style="transform: rotate(-40deg); margin-left:-10px" svgIcon="setting" ngxBottomNavIcon>
                    </mat-icon>
                </button>
            </ngx-bottom-nav>
        </div>
    </nav>
</div>