<div class="container" style="height: 100vh;">
    <div class="d-flex justify-content-between mt-3 mb-3">
        <h3 class="title-text">Notifications</h3>
        <!-- <h3>{{account?.name}}</h3> -->
        <div>
            <button (click)="openNofiticationDialog({})" style="font-size: 15px; font-family: avenir-roman; background-color: #FFD36A;" class="btn" mat-flat-button>New Notification</button>
            <button (click)="signOut()" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Sign Out</button>
        </div>
       
    </div>
    <div class="row mt-2">
        <div class="col-md">
            <div class="table-responsive bg-white" style="max-height: 620px;">
                <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
                    <thead>
                        <tr>                                
                            <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                               Image
                            </th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Title</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Content</th>
                            <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="animate__animated animate__fadeIn" *ngFor="let notification of notificationList$ | async; let i=index;" style="border: .5px solid #EDEDED; font-size: 15px;">                        
                            <td class="align-middle">
                                <img src="{{notification.display_image}}" alt="" style="height: 25%; width: 25%;">
                            </td>
                            <td class="align-middle">{{notification.title}}</td>
                            <td class="align-middle">{{notification.content}}</td>
                            <td class="align-middle">{{notification.date}}</td>                            
                            <td class="align-middle">
                                <button (click)="sendNotification(notification)" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Send</button>                                  
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>                               
        </div>
        <div class="col-md-12 d-flex text-center justify-content-center mt-5" *ngIf="notificationList.length">
            <p style="font-family: avenir-roman; font-weight: 300; font-size: 20px;">No notifications sent yet</p>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
            </mat-spinner>
        </div>
    </div>
</div>