import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BackendService } from '../../services/backend.service';
import { DialogButton, DialogOptions, DialogService } from '../../services/dialog.service';
import { Career } from 'src/app/shared/models/career.interface'
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-careers-dialog',
  templateUrl: './careers-dialog.component.html',
  styleUrls: ['./careers-dialog.component.scss']
})
export class CareersDialogComponent implements OnInit, OnDestroy {
  public isLoading = false;
  private unSubscriptioNotifier = new Subject();
  public careerFormGroup: FormGroup;
  public career: Career;
  public selected = true;
  constructor(public dialogRef: MatDialogRef<CareersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions,
    private backend: BackendService,
    private dialogService: DialogService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,) {

    this.career = dialogOptions.data || {};
    // this.selected = String(this.career.availability) || 'false'
    console.log('this.career.id : '+this.career.id)
    this.careerFormGroup = new FormGroup({
      id: new FormControl(this.career.id, [Validators.nullValidator]),
      name: new FormControl(this.career.name, [Validators.required]),
      short_desc: new FormControl(this.career.short_desc, [Validators.required]),
      long_desc: new FormControl(this.career.long_desc, [Validators.required]),
      job_type: new FormControl(this.career.job_type, [Validators.required]),
      experience: new FormControl(this.career.experience, [Validators.required]),
      salary: new FormControl(this.career.salary, [Validators.required]),
      availability: new FormControl(this.career.availability, [Validators.required]),
      deadline: new FormControl(this.career.deadline, [Validators.required]),
      requirements: new FormControl(this.career.requirements, [Validators.required]),
      responsibilities: new FormControl(this.career.responsibilities, [Validators.required]),
    });
  }


  ngOnInit(): void {
  }

  public cancel(): void {
    this.isLoading = false;
    this.dialogService.sendMessage({ text: 'cancel button clicked', button: DialogButton.cancel });
    this.dialogRef.close();
  }

  public done() {
    this.dialogService.sendMessage({ text: 'ok button clicked', button: DialogButton.ok });
    this.dialogRef.close();
  }

  public save() {
    this.isLoading = true;
    let t = this;
    let job = this.careerFormGroup.value;
    console.log("Values:", job)
    if (this.career.id != undefined || null != this.career.id) {
      //update
      job.availability = Boolean(job.availability)
      job.deadline = new Date(job.deadline).toDateString()
      t.backend.updateCareer(job)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (response) => {
            t.isLoading = false
            t.done()
          }, error: (err: any) => {
            console.log('An error occurred:', err.error.message);
            t.isLoading = false
          }, complete: () => {
            t.isLoading = false;
            console.log('on complete update career');
          }
        })
    } else {
      //add 
      delete job.id
      job.availability = Boolean(job.availability)
      job.deadline = new Date(job.deadline).toDateString()
      console.log("Values on Add:", job)
      t.backend.createCareer(job)
        .pipe(takeUntil(t.unSubscriptioNotifier))
        .subscribe({
          next: (result) => {
            t.done()
            t.isLoading = false
          }, error: (err: any) => {
            console.log('An error occurred:', err.error.message);
            t.isLoading = false
          }, complete: () => {
            t.isLoading = false;
            console.log('on complete create career');
          }
        })      
    }
  }

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '100px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter description here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    //upload: (file: File) => { }
    //uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'outdent',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
  }
}
