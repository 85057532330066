import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { OrderItemDetailDialogComponent } from 'src/app/shared/components/order-item-detail-dialog/order-item-detail-dialog.component';
import { Account } from 'src/app/shared/models/account.interface';
import { OrderItem } from 'src/app/shared/models/order-item';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';

@Component({
  selector: 'app-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss']
})
export class DispatchComponent implements OnInit, OnDestroy{
  private unSubscriptioNotifier = new Subject();
  public isLoading = false;
  public orderId = '';
  public account: Account = {}
 
  public orderItemEnrouteList = new Array<OrderItem>();
  public orderItemEnrouteList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public orderItemEnrouteListBehaviour: BehaviorSubject<OrderItem[]>;
  
  public orderItemDispatchedList = new Array<OrderItem>();
  public orderItemDispatchedList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public orderItemDispatchedListBehaviour: BehaviorSubject<OrderItem[]>;

  public orderItemReadyToDispatchList = new Array<OrderItem>();
  public orderItemReadyToDispatchList$: Observable<OrderItem[]> = new Observable<OrderItem[]>();
  public orderItemReadyToDispatchListBehaviour: BehaviorSubject<OrderItem[]>;

  constructor(private router: Router,
    private backend: BackendService,   
    private localAuthService: LocalAuthService,
    private appMaterialComponent: AppMaterialDesignModule,   
    public dataSource: DataService) {   
      this.orderItemEnrouteListBehaviour = new BehaviorSubject([])
      this.orderItemEnrouteList$ = this.orderItemEnrouteListBehaviour.asObservable();
  
      this.orderItemDispatchedListBehaviour = new BehaviorSubject([])
      this.orderItemDispatchedList$ = this.orderItemDispatchedListBehaviour.asObservable();
  
      this.orderItemReadyToDispatchListBehaviour = new BehaviorSubject([])
      this.orderItemReadyToDispatchList$ = this.orderItemReadyToDispatchListBehaviour.asObservable();
  }

  ngOnInit(): void {
    this.account = JSON.parse(localStorage.getItem('account'))
    this.getDispatchOrders();
  }

  // TODO: add delivery location to the order item

  public getDispatchOrders() {
    this.isLoading = true;
    this.backend.getDispatchOrders(this.account?.id)
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe(actionArray => {
        this.isLoading = false
        let tempOrderItems = actionArray.map((item: { payload: { doc: { id: any; data: () => OrderItem; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as OrderItem
        })

        this.orderItemEnrouteList = tempOrderItems.filter(i => {
          return i.status == 'Enroute'
        });
        this.orderItemEnrouteListBehaviour.next(this.orderItemEnrouteList);

        this.orderItemDispatchedList = tempOrderItems.filter(i => {
          return ['Dispatched'].includes(i.status);
        });
        this.orderItemDispatchedListBehaviour.next(this.orderItemDispatchedList);

        this.orderItemReadyToDispatchList = tempOrderItems.filter(i => {
          return ['ReadyToDispatch', 'Enroute'].includes(i.status);
        });
        this.orderItemReadyToDispatchListBehaviour.next(this.orderItemReadyToDispatchList);
      })
  }
  
  public openOrderItemDetailDialog(orderId: string) {
    this.dataSource.setData(orderId)
    this.appMaterialComponent.openDialog(OrderItemDetailDialogComponent, {
      width: '700px',
      title: 'Order Detail',
      message: 'Order Detail'
    }).pipe(switchMap(result => {
      if (result.text == 'process') {
        return null // this.backend.updateOrderStatus(orderId, this.account.id, 'Processing')
      } else if (result.text == 'cancel') {
        return null // this.backend.updateOrderStatus(orderId, this.account.id, 'Cancelled')
      }
    }), takeUntil(this.unSubscriptioNotifier),
    ).subscribe()
  }


  public updateOrderStatus(status: string, orderId: string) {
    this.backend.updateDispatchOrderStatus(orderId, this.account.id, status)
  }

  public bulkUpdateOrderStatus(orderItemList, status: string) {
    orderItemList.forEach(o => {
      if (o.checked) {
        this.backend.updateDispatchOrderStatus(o.id, this.account.id, status)
      }
    })
  }

  
  public checkAllOrderItemEnrouteListCheckBox(ev) {
    this.orderItemEnrouteList.forEach(x => x.checked = ev.target.checked)
  }

  public isAllOrderItemEnrouteListCheckBoxChecked() {
    return this.orderItemEnrouteList.every(p => p.checked);
  }

  public checkAllOrderItemDispatchedListCheckBox(ev) {
    this.orderItemDispatchedList.forEach(x => x.checked = ev.target.checked)
  }

  public isAllOrderItemDispatchedListCheckBoxChecked() {
    return this.orderItemDispatchedList.every(p => p.checked);
  }

  public checkAllOrderItemReadyToDispatchListCheckBox(ev) {
    this.orderItemReadyToDispatchList.forEach(x => x.checked = ev.target.checked)
  }

  public isAllOrderItemReadyToDispatchListCheckBoxChecked() {
    return this.orderItemReadyToDispatchList.every(p => p.checked);
  }

  public isSomeCheckBoxChecked() {
    return this.orderItemEnrouteList.some(p => p.checked)
      || this.orderItemDispatchedList.some(p => p.checked)
      || this.orderItemReadyToDispatchList.some(p => p.checked);
  }

  public getLocation(orderId:string){
    return this.backend.getOrderItem(orderId)
  }

  public signOut() {
    localStorage.setItem('account', null)
    //this.firebaseAuth.signOut().then(r => { });
    this.router.navigate([`account/login`], { queryParams: {}, skipLocationChange: false });
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }

}
