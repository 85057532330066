<div class="container" style="height: 100vh;">
    <div class="d-flex justify-content-between mt-3 mb-3">
        <h3 class="title-text">Dispatch</h3>
        <!-- <h3>{{account?.name}}</h3> -->
        <button (click)="signOut()" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Sign Out</button>
    </div>
    <div class="row  mt-2">
        <mat-tab-group mat-align-tabs="start" class="remove-border-bottom col">
            <mat-tab label="Ready To Dispatch" style="font-family: avenir-roman;">
                <div class="table-responsive bg-white" style="max-height: 620px;">
                    <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
                        <thead>
                            <tr>
                                <th>
                                    <input class="ml-2" type="checkbox" [checked]="isAllOrderItemReadyToDispatchListCheckBoxChecked()" (change)="checkAllOrderItemReadyToDispatchListCheckBox($event)">
                                </th>
                                <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                                    Order number
                                </th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Customer name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Food name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Quantity</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Status</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Location</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="animate__animated animate__fadeIn" *ngFor="let orderItem of orderItemReadyToDispatchList$ | async; let i = index" style="border: .5px solid #EDEDED; font-size: 15px;">
                                <td class="align-middle">
                                    <!-- <div class="form-group"> -->
                                    <mat-checkbox class="ml-2" color="primary" style="font-family: avenir-roman;" value="{{orderItem.id}}" [(ngModel)]="orderItemReadyToDispatchList[i].checked">
                                    </mat-checkbox>
                                    <!-- </div> -->
                                </td>
                                <td class="align-middle">{{orderItem.id}}</td>
                                <td class="align-middle">{{orderItem.username}}</td>
                                <td class="align-middle">{{orderItem.food_name}}</td>
                                <td class="align-middle">{{orderItem.quantity}}</td>
                                <td class="align-middle">{{orderItem.status}}</td>
                                <td class="align-middle">{{orderItem.delivery_location}}</td>

                                <!-- <td class="align-middle">{{orderItem.total_cost}}</td> -->
                               
                                <td class="align-middle">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">                                       
                                        <button (click)="updateOrderStatus('Enroute',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Move to Enroute</span>
                                        </button>                                                                    
                                        <button (click)="updateOrderStatus('Dispatched',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Move to Delivered</span>
                                        </button>
                                        
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="isSomeCheckBoxChecked()" class="align-items-start bg-white pb-2 pl-2">
                    <button (click)="bulkUpdateOrderStatus(orderItemReadyToDispatchList,'Enroute')" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Bulk
                        to Enroute</button>
                        <button (click)="bulkUpdateOrderStatus(orderItemReadyToDispatchList,'Delivered')" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Bulk
                            to Delivered</button>
                </div>
            </mat-tab>
            <mat-tab label="Enroute" style="font-family: avenir-roman;">
                <div class="table-responsive bg-white" style="max-height: 620px;">
                    <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
                        <thead>
                            <tr>
                                <th>
                                    <input class="ml-2" type="checkbox" [checked]="isAllOrderItemEnrouteListCheckBoxChecked()" (change)="checkAllOrderItemEnrouteListCheckBox($event)">
                                </th>
                                <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                                    Order number
                                </th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Customer name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Food name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Quantity</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Location</th>
                                <!-- <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Amount</th> -->
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="animate__animated animate__fadeIn" *ngFor="let orderItem of orderItemEnrouteList$ | async; let i = index" style="border: .5px solid #EDEDED; font-size: 15px;">
                                <td class="align-middle">
                                    <!-- <div class="form-group"> -->
                                    <mat-checkbox class="ml-2" color="primary" style="font-family: avenir-roman;" value="{{orderItem.id}}" [(ngModel)]="orderItemEnrouteList[i].checked">
                                    </mat-checkbox>
                                    <!-- </div> -->
                                </td>
                                <td class="align-middle">{{orderItem.id}}</td>
                                <td class="align-middle">{{orderItem.username}}</td>
                                <td class="align-middle">{{orderItem.food_name}}</td>
                                <td class="align-middle">{{orderItem.quantity}}</td>
                                <td class="align-middle">{{orderItem. delivery_location}}</td>
                                <!-- <td class="align-middle">{{orderItem.total_cost}}</td> -->
                                
                                <td class="align-middle">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <!-- <button (click)="openAssignToDispatchDialog(orderItem)" mat-menu-item>
                                            <mat-icon>edit</mat-icon>
                                            <span>Assign to dispatch rider</span>
                                        </button> -->
                                        <button (click)="updateOrderStatus('Enroute',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Back to Enroute</span>
                                        </button>
                                        <button (click)="updateOrderStatus('Dispatched',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Move to Delivered</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="isSomeCheckBoxChecked()" class="align-items-start bg-white pb-2 pl-2">
                    <button (click)="bulkUpdateOrderStatus(orderItemEnrouteList,'ReadyToDispatch')" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Back
                        to Ready To Dispatch</button>
                        <button (click)="bulkUpdateOrderStatus(orderItemEnrouteList,'Dispatched')" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Bulk
                            to Delivered</button>
                </div>
            </mat-tab>
            <mat-tab label="Delivered" style="font-family: avenir-roman;">
                <div class="table-responsive bg-white" style="max-height: 620px;">
                    <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
                        <thead>
                            <tr>
                                <th>
                                    <input class="ml-2" type="checkbox" [checked]="isAllOrderItemEnrouteListCheckBoxChecked()" (change)="checkAllOrderItemEnrouteListCheckBox($event)">
                                </th>
                                <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                                    Order number
                                </th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Customer name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Food name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Quantity</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Location</th>
                                <!-- <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Amount</th> -->
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="animate__animated animate__fadeIn" *ngFor="let orderItem of orderItemDispatchedList$ | async; let i = index" style="border: .5px solid #EDEDED; font-size: 15px;">
                                <td class="align-middle">
                                    <!-- <div class="form-group"> -->
                                    <mat-checkbox class="ml-2" color="primary" style="font-family: avenir-roman;" value="{{orderItem.id}}" [(ngModel)]="orderItemDispatchedList[i].checked">
                                    </mat-checkbox>
                                    <!-- </div> -->
                                </td>
                                <td class="align-middle">{{orderItem.id}}</td>
                                <td class="align-middle">{{orderItem.username}}</td>
                                <td class="align-middle">{{orderItem.food_name}}</td>
                                <td class="align-middle">{{orderItem.quantity}}</td>
                                <td class="align-middle">{{orderItem.delivery_location}}</td>
                                <!-- <td class="align-middle">{{orderItem.total_cost}}</td> -->
                                
                                <td class="align-middle">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">                                        
                                        <button (click)="updateOrderStatus('Enroute',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Back to Enroute</span>
                                        </button>
                                        <!-- <button (click)="updateOrderStatus('Dispatched',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <mat-icon>edit</mat-icon>
                                            <span>Move to Delivered</span>
                                        </button> -->
                                    </mat-menu>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>                
            </mat-tab>
        </mat-tab-group>
    </div>
</div>