<div class="d-flex justify-content-between mb-3">
    <h4 mat-dialog-title>{{dialogOptions.title}}</h4>
    <button mat-icon-button (click)="cancel()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <form [formGroup]="dispatchRiderFormGroup" #dispatchRiderForm="ngForm" name="dispatchRiderFormGroup" (ngSubmit)="save()">
        <div class="row">
            <div class="col">
                <p class="subtitle-text" >Bio</p>
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="title">name</p> -->
                    <input placeholder="Name" formControlName="name" type="text" class="form-control" id="name" name="name" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':dispatchRiderForm.submitted && dispatchRiderFormGroup.get('name')?.hasError('required') }"
                        aria-describedby="nameHint">
                    <div *ngIf="dispatchRiderForm.submitted && dispatchRiderFormGroup.get('name')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="dispatchRiderFormGroup.get('name')?.hasError('required')" id="nameHint" class="form-text">name
                            is required</small>
                        <small *ngIf="dispatchRiderFormGroup.get('name')?.hasError('minlength')" id="nameHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="dispatchRiderFormGroup.get('name')?.hasError('name')">Please provide a valid
                            name</small>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="mobileNumber">Contact</p> -->
                    <input placeholder="Contact" formControlName="mobileNumber" type="tel" class="form-control" id="mobileNumber" name="mobileNumber" required autofocus minlength="10" autocomplete="off" [ngClass]="{ 'is-invalid':dispatchRiderForm.submitted && dispatchRiderFormGroup.get('mobileNumber')?.hasError('required') }"
                        aria-describedby="mobileNumberHint">
                    <div *ngIf="dispatchRiderForm.submitted && dispatchRiderFormGroup.get('mobileNumber')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="dispatchRiderFormGroup.get('mobileNumber')?.hasError('required')" id="mobileNumberHint" class="form-text">mobileNumber
                            is
                            required</small>
                        <small *ngIf="dispatchRiderFormGroup.get('mobileNumber')?.hasError('minlength')" id="mobileNumberHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="dispatchRiderFormGroup.get('mobileNumber')?.hasError('mobileNumber')">Please provide a valid mobileNumber</small>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="idType">ID Type</p> -->
                    <div class="select " style="padding: 2px;">
                        <select class="p-2 form-control">
                        <option value="" selected disabled>ID Type</option>
                        <option value="1">NIA</option>
                        <option value="2">NHIS</option>
                        <option value="3">Passport</option>
                        </select>
                    </div>
                    <!-- <input placeholder="ID Type" formControlName="idType" type="text" class="form-control" id="idType" name="idType" required autofocus autocomplete="off" [ngClass]="{ 'is-invalid':dispatchRiderForm.submitted && dispatchRiderFormGroup.get('idType')?.hasError('required') }"
                        aria-describedby="idTypeHint">
                    <div *ngIf="dispatchRiderForm.submitted && dispatchRiderFormGroup.get('idType')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="dispatchRiderFormGroup.get('idType')?.hasError('required')" id="idTypeHint" class="form-text">idType
                            is required</small>                                              
                    </div> -->
                </div>           
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="idType">ID Number</p> -->
                    <input placeholder="ID Number" formControlName="idNumber" type="text" class="form-control" id="idNumber" name="idNumber" required autofocus autocomplete="off" [ngClass]="{ 'is-invalid':dispatchRiderForm.submitted && dispatchRiderFormGroup.get('idNumber')?.hasError('required') }"
                        aria-describedby="idNumberHint">
                    <div *ngIf="dispatchRiderForm.submitted && dispatchRiderFormGroup.get('idNumber')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="dispatchRiderFormGroup.get('idNumber')?.hasError('required')" id="idNumberHint" class="form-text">idNumber
                            is required</small>      
                        <small *ngIf="dispatchRiderFormGroup.get('idNumber')?.hasError('minlength')" id="idNumberHint" class="form-text">Please
                            provide a minimum length of 10 characters</small>
                        <small *ngIf="dispatchRiderFormGroup.get('idNumber')?.hasError('idNumber')">Please provide a valid idNumber</small>                              
                    </div>
                </div>                
            </div>
            <div class="col">
                <p class="subtitle-text" >Next of Kin</p>
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="nameOfKin">Name</p> -->
                    <input placeholder="Name" formControlName="nameOfKin" type="text" class="form-control" id="nameOfKin" name="nameOfKin" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':dispatchRiderForm.submitted && dispatchRiderFormGroup.get('nameOfKin')?.hasError('required') }"
                        aria-describedby="nameOfKinHint">
                    <div *ngIf="dispatchRiderForm.submitted && dispatchRiderFormGroup.get('nameOfKin')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="dispatchRiderFormGroup.get('nameOfKin')?.hasError('required')" id="nameOfKinHint" class="form-text">nameOfKin
                            is required</small>
                        <small *ngIf="dispatchRiderFormGroup.get('nameOfKin')?.hasError('minlength')" id="nameOfKinHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="dispatchRiderFormGroup.get('nameOfKin')?.hasError('nameOfKin')">Please provide a valid
                            nameOfKin</small>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="mobileNumberOfKin">Contact</p> --> 
                    <input placeholder="Contact" formControlName="mobileNumberOfKin" type="text" class="form-control" id="mobileNumberOfKin" name="mobileNumberOfKin" required autofocus autocomplete="off" [ngClass]="{ 'is-invalid':dispatchRiderForm.submitted && dispatchRiderFormGroup.get('mobileNumberOfKin')?.hasError('required') }"
                        aria-describedby="mobileNumberOfKinHint">
                    <div *ngIf="dispatchRiderForm.submitted && dispatchRiderFormGroup.get('mobileNumberOfKin')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="dispatchRiderFormGroup.get('mobileNumberOfKin')?.hasError('required')" id="mobileNumberOfKinHint" class="form-text">mobileNumberOfKin
                            is required</small>       
                        <small *ngIf="dispatchRiderFormGroup.get('mobileNumberOfKin')?.hasError('minlength')" id="mobileNumberOfKinHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="dispatchRiderFormGroup.get('mobileNumberOfKin')?.hasError('mobileNumberOfKin')">Please provide a valid
                            mobileNumberOfKin</small>                 
                    </div>
                </div>
                <button [disabled]="!dispatchRiderForm.valid || isLoading" type="submit" style="font-size: 18px; font-family: avenir-roman;" class="btn float-right align-bottom" mat-flat-button color="primary">Save</button>
                <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                    <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                    </mat-spinner>
                </div>
            </div>
        </div>
    </form>
</div>