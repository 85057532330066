<div class="container" style="height: 100vh;">
    <div class="d-flex justify-content-between mt-3 mb-3">
        <h3 class="title-text">Orders</h3>
        <!-- <h3>{{account?.name}}</h3> -->
        <button (click)="signOut()" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Sign Out</button>
    </div>
    <div class="row mt-2">
        <mat-tab-group class="remove-border-bottom col" [selectedIndex]="0">
            <mat-tab label="Active Orders" class="subtitle-text" style="font-family: avenir-roman;">
                <div class="table-responsive bg-white" style="max-height: 620px;">
                    <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
                        <thead>
                            <tr>
                                <th>
                                    <input class="ml-2" type="checkbox" [checked]="isAllOrderItemListCheckBoxChecked()" (change)="checkAllOrderItemListCheckBox($event)">
                                </th>
                                <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                                    Order number
                                </th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Customer name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Food name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Quantity</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="animate__animated animate__fadeIn" *ngFor="let orderItem of orderItemList$ | async; let i=index;" style="border: .5px solid #EDEDED; font-size: 15px;">
                                <td class="align-middle">
                                    <!-- <div class="form-group"> -->
                                    <mat-checkbox class="ml-2" color="primary" style="font-family: avenir-roman;" value="{{orderItem.id}}" [(ngModel)]="orderItemList[i].checked">
                                    </mat-checkbox>
                                    <!-- </div> -->
                                </td>
                                <!-- <input type="checkbox" value="{{orderItem.id}}" [(ngModel)]="orderItemList[i].checked"></td> -->
                                <td class="align-middle">{{orderItem.id}}</td>
                                <td class="align-middle">{{orderItem.username}}</td>
                                <td class="align-middle">{{orderItem.food_name}}</td>
                                <td class="align-middle">{{orderItem.quantity}}</td>
                                <td class="align-middle">{{orderItem.total_cost}}</td>
                                <td class="align-middle">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button (click)="updateOrderStatus('Processing',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Process</span>
                                        </button>
                                        <button (click)="updateOrderStatus('ReadyToDispatch',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Ready for dispatch</span>
                                        </button>
                                        <button (click)="openOrderItemDetailDialog(orderItem.id);" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Order Details</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="isSomeCheckBoxChecked()" class="align-items-start bg-white pb-2 pl-2">
                    <button (click)="bulkUpdateOrderStatus(orderItemList,'Processing')" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Bulk
                        Process</button>
                    <button (click)="bulkUpdateOrderStatus(orderItemList,'ReadyToDispatch')" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Bulk
                        Ready For Dispatch</button>
                </div>
            </mat-tab>
            <mat-tab label="Processing" style="font-family: avenir-roman;">
                <div class="table-responsive bg-white" style="max-height: 620px;">
                    <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
                        <thead>
                            <tr>
                                <th>
                                    <input class="ml-2" type="checkbox" [checked]="isAllOrderItemProcessingListCheckBoxChecked()" (change)="checkAllOrderItemProcessingListCheckBox($event)">
                                </th>
                                <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                                    Order number
                                </th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Customer name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Food name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Quantity</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="animate__animated animate__fadeIn" *ngFor="let orderItem of orderItemProcessingList$ | async; let i = index;" style="border: .5px solid #EDEDED; font-size: 15px;">
                                <td class="align-middle">
                                    <!-- <div class="form-group"> -->
                                    <mat-checkbox class="ml-2" color="primary" style="font-family: avenir-roman;" value="{{orderItem.id}}" [(ngModel)]="orderItem.checked">
                                    </mat-checkbox>
                                    <!-- </div> -->
                                </td>
                                <td class="align-middle">{{orderItem.id}}</td>
                                <td class="align-middle">{{orderItem.username}}</td>
                                <td class="align-middle">{{orderItem.food_name}}</td>
                                <td class="align-middle">{{orderItem.quantity}}</td>
                                <td class="align-middle">{{orderItem.total_cost}}</td>
                                <td class="align-middle">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button (click)="updateOrderStatus('ReadyToDispatch',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Ready for dispatch</span>
                                        </button>
                                        <button (click)="updateOrderStatus('new',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Cancel</span>
                                        </button>
                                        <button (click)="openOrderItemDetailDialog(orderItem.id);" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Order Details</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="isSomeCheckBoxChecked()" class="align-items-start bg-white pb-2 pl-2">
                    <!-- <button (click)="bulkUpdateOrderStatus('new')" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Bulk Process</button> -->
                    <button (click)="bulkUpdateOrderStatus(orderItemProcessingList,'ReadyToDispatch')" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Bulk
                        Ready For Dispatch</button>
                </div>
            </mat-tab>
            <mat-tab label="Ready To Dispatch" style="font-family: avenir-roman;">
                <div class="table-responsive bg-white" style="max-height: 620px;">
                    <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
                        <thead>
                            <tr>
                                <th>
                                    <input class="ml-2" type="checkbox" [checked]="isAllOrderItemReadyToDispatchListCheckBoxChecked()" (change)="checkAllOrderItemReadyToDispatchListCheckBox($event)">
                                </th>
                                <th class="subtitle-text" style="font-family: avenir-roman; font-weight: 300;" scope="col">
                                    Order number
                                </th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Customer name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Food name</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Quantity</th>
                                <th style="font-family: avenir-roman; font-weight: 300;" scope="col">Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="animate__animated animate__fadeIn" *ngFor="let orderItem of orderItemReadyToDispatchList$ | async; let i = index" style="border: .5px solid #EDEDED; font-size: 15px;">
                                <td class="align-middle">
                                    <!-- <div class="form-group"> -->
                                    <mat-checkbox class="ml-2" color="primary" style="font-family: avenir-roman;" value="{{orderItem.id}}" [(ngModel)]="orderItemReadyToDispatchList[i].checked">
                                    </mat-checkbox>
                                    <!-- </div> -->
                                </td>
                                <td class="align-middle">{{orderItem.id}}</td>
                                <td class="align-middle">{{orderItem.username}}</td>
                                <td class="align-middle">{{orderItem.food_name}}</td>
                                <td class="align-middle">{{orderItem.quantity}}</td>
                                <td class="align-middle">{{orderItem.total_cost}}</td>
                                <td>
                                    <mat-icon *ngIf="orderItem.status=='Enroute'" svgIcon="delivery_man"></mat-icon>
                                </td>
                                <td class="align-middle">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button (click)="openAssignToDispatchDialog(orderItem)" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Assign to dispatch rider</span>
                                        </button>
                                        <button (click)="updateOrderStatus('Processing',orderItem.id);orderId = orderItem.id" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Back to processing</span>
                                        </button>
                                        <button (click)="openOrderItemDetailDialog(orderItem.id);" mat-menu-item>
                                            <!-- <mat-icon>edit</mat-icon> -->
                                            <span>Order Details</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="isSomeCheckBoxChecked()" class="align-items-start bg-white pb-2 pl-2">
                    <button (click)="openAssignBulkToDispatchDialog(orderItemReadyToDispatchList)" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Assign Dispatch</button>
                    <button (click)="bulkUpdateOrderStatus(orderItemReadyToDispatchList,'Processing')" style="font-size: 15px; font-family: avenir-roman;" class="btn" mat-button color="primary">Back
                        to Processing</button>
                </div>
            </mat-tab>
        </mat-tab-group>
        <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
            </mat-spinner>
        </div>
    </div>
</div>