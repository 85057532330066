import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routes } from './core/core-routing.module';

const coreRoutes: Routes = routes

@NgModule({
  imports: [RouterModule.forRoot(coreRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const AppRoutingComponents = [ 

  
]
