import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { fadeInAnimation } from 'src/app/shared/animations/fadein.animation';
import { Food } from 'src/app/shared/models/food.interface';
import { DeliveryLocation } from 'src/app/shared/models/location.interface';
import { OrderItem } from 'src/app/shared/models/order-item';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';

export interface Cart {
  quantity?: number
  foodItem?: Food
  total_cost?: number
  delivery_location?: DeliveryLocation
  reference?: string,
  orderItem?: OrderItem
}

@Component({
  selector: 'app-food-item-details',
  templateUrl: './food-item-details.component.html',
  styleUrls: ['./food-item-details.component.scss'],
  // animations: [fadeInAnimation],
  // host: { '[@fadeInAnimation]': '' }
})
export class FoodItemDetailsComponent implements OnInit, OnDestroy {

  public foodItem: Food = {}
  public cart: Cart = {}
  public swiperConfig: SwiperConfigInterface = {};
  private unSubscriptioNotifier = new Subject();
  constructor(private router: Router,
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private appMaterialComponent: AppMaterialDesignModule,
    private dataSource: DataService) {

    // this.foodItem = {
    //   id: '1',
    //   name: 'JOLLOF',
    //   description: 'Assorted Jollof Rice cooked in a delicious tomato sauce – Easy, flavourful, and perfectly cooked African classic dish. This comes with a mix of shredded beef, chicken, and chicken sausage and served with grilled chicken',
    //   cost: 50,
    //   display_image: 'assets/images/tempfoodimage.jpg',
    //   display_image_banner: 'assets/images/tempfoodimage.jpg'
    // }

    // this.cart.foodItem = this.foodItem;
    // this.cart.quantity = 1;
    // this.cart.total_cost = (this.cart.quantity * this.cart.foodItem.cost);
    // let delivery_location = {
    //   name:'Technical University'
    // }
    // this.cart.delivery_location = delivery_location

  }


  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next()
    this.unSubscriptioNotifier.complete()
  }

  @ViewChild(SwiperDirective, { static: false })
  swiperDirectiveRef!: SwiperDirective;

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
    dynamicBullets: true,
    type: 'bullets'
  };

  ngOnInit(): void {
    this.dataSource.currentdata
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe(data => {
        this.cart = data;
        if (this.cart.foodItem == undefined) {
          //this.router.navigate(['home'], { queryParams: {}, skipLocationChange: false })
          //return
          this.cart = JSON.parse(localStorage.getItem('cart'));
        }
      });


    this.swiperConfig = {
      a11y: true,
      direction: 'horizontal',
      slidesPerView: 1,
      observer: true,
      threshold: 10,
      speed: 500,
      spaceBetween: 30,
      centeredSlides: true,
      grabCursor: true,
      keyboard: false,
      allowTouchMove: false,
      longSwipes: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: this.pagination,
      roundLengths: true,
    };
  }

  public addQuantity() {
    if (this.cart.quantity >= 1) {
      this.cart.quantity++
    }
    this.cart.total_cost = (this.cart.quantity * this.cart.foodItem.cost);
  }

  public removeQuantity() {
    if (this.cart.quantity > 1) {
      this.cart.quantity--
    }
    this.cart.total_cost = (this.cart.quantity * this.cart.foodItem.cost);
  }

  public gotoCheckout() {
    let ref = `${Math.ceil(Math.random() * 10e14)}`
    this.cart.reference = ref;
    localStorage.setItem('cart', JSON.stringify(this.cart))
    this.dataSource.setData(this.cart)
    this.router.navigate([`order/checkout`], { queryParams: {}, skipLocationChange: false });
    //this.navigate(1)
  }

  public onPageIndexChange(index: number) {

  }

  public navigate(index: number) {
    this.swiperDirectiveRef.setIndex(index);
  }

}
