<div class="d-flex justify-content-between mb-3">
    <h4 mat-dialog-title>{{dialogOptions.title}}</h4>
    <button mat-icon-button (click)="cancel()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <form [formGroup]="accountFormGroup" #accountForm="ngForm" name="accountFormGroup" (ngSubmit)="save()">
        <div class="row">
            <div class="col-12">
                <p class="title-text">Personal Information</p>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="name">name</p> -->
                    <input placeholder="Fullname" formControlName="name" type="text" class="form-control" id="name" name="name" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':accountForm.submitted && accountFormGroup.get('name')?.hasError('required') }"
                        aria-describedby="nameHint">
                    <div *ngIf="accountForm.submitted && accountFormGroup.get('name')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="accountFormGroup.get('name')?.hasError('required')" id="nameHint" class="form-text">name
                            is
                            required</small>
                        <small *ngIf="accountFormGroup.get('name')?.hasError('minlength')" id="nameHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="accountFormGroup.get('name')?.hasError('name')">Please provide a valid
                            name</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="name">name</p> -->
                    <input placeholder="Mobile Number" formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber" name="mobileNumber" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':accountForm.submitted && accountFormGroup.get('mobileNumber')?.hasError('required') }"
                        aria-describedby="mobileNumberHint">
                    <div *ngIf="accountForm.submitted && accountFormGroup.get('mobileNumber')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="accountFormGroup.get('mobileNumber')?.hasError('required')" id="mobileNumberHint" class="form-text">mobile number
                            is
                            required</small>
                        <small *ngIf="accountFormGroup.get('mobileNumber')?.hasError('minlength')" id="mobileNumberHint" class="form-text">Please
                            provide a minimum length of 10 characters</small>
                        <small *ngIf="accountFormGroup.get('mobileNumber')?.hasError('mobileNumber')">Please provide a
                            valid
                            mobile number</small>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <mat-label>ID Type</mat-label>
                    <mat-select class="form-control" formControlName="idType">
                        <mat-option value="NATIONAL_ID">
                            National ID
                        </mat-option>
                        <mat-option value="PASSPORT">
                            Passport
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="name">name</p> -->
                    <input placeholder="ID Number" formControlName="idNumber" type="text" class="form-control" id="idNumber" name="idNumber" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':accountForm.submitted && accountFormGroup.get('idNumber')?.hasError('required') }"
                        aria-describedby="idNumberHint">
                    <div *ngIf="accountForm.submitted && accountFormGroup.get('idNumber')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="accountFormGroup.get('idNumber')?.hasError('required')" id="idNumberHint" class="form-text">ID number
                            is
                            required</small>
                        <small *ngIf="accountFormGroup.get('idNumber')?.hasError('minlength')" id="idNumberHint" class="form-text">Please
                            provide a minimum length of 10 characters</small>
                        <small *ngIf="accountFormGroup.get('idNumber')?.hasError('idNumber')">Please provide a valid
                            ID number</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="name">name</p> -->
                    <input placeholder="Next of kin name" formControlName="nameOfKin" type="text" class="form-control" id="nameOfKin" name="nameOfKin" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':accountForm.submitted && accountFormGroup.get('nameOfKin')?.hasError('required') }"
                        aria-describedby="nameOfKinHint">
                    <div *ngIf="accountForm.submitted && accountFormGroup.get('nameOfKin')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="accountFormGroup.get('nameOfKin')?.hasError('required')" id="nameOfKinHint" class="form-text">name of kin
                            is
                            required</small>
                        <small *ngIf="accountFormGroup.get('nameOfKin')?.hasError('minlength')" id="nameOfKinHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="accountFormGroup.get('nameOfKin')?.hasError('nameOfKin')">Please provide a valid
                            name</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="name">name</p> -->
                    <input placeholder="Next of kin mobile number" formControlName="mobileNumberOfKin" type="text" class="form-control" id="mobileNumberOfKin" name="mobileNumberOfKin" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':accountForm.submitted && accountFormGroup.get('mobileNumberOfKin')?.hasError('required') }"
                        aria-describedby="mobileNumberOfKinHint">
                    <div *ngIf="accountForm.submitted && accountFormGroup.get('mobileNumberOfKin')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="accountFormGroup.get('mobileNumberOfKin')?.hasError('required')" id="nameHint" class="form-text">Next of kin mobile number
                            is
                            required</small>
                        <small *ngIf="accountFormGroup.get('mobileNumberOfKin')?.hasError('minlength')" id="mobileNumberOfKinHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="accountFormGroup.get('mobileNumberOfKin')?.hasError('mobileNumberOfKin')">Please
                            provide a valid
                            name</small>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <p class="title-text">Account Information</p>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="name">name</p> -->
                    <input placeholder="Username" formControlName="username" type="text" class="form-control" id="username" name="username" required autofocus minlength="4" autocomplete="off" [ngClass]="{ 'is-invalid':accountForm.submitted && accountFormGroup.get('username')?.hasError('required') }"
                        aria-describedby="usernameHint">
                    <div *ngIf="accountForm.submitted && accountFormGroup.get('username')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="accountFormGroup.get('username')?.hasError('required')" id="usernameHint" class="form-text">username
                            is
                            required</small>
                        <small *ngIf="accountFormGroup.get('username')?.hasError('minlength')" id="usernameHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="accountFormGroup.get('username')?.hasError('username')">Please provide a valid
                            username</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <!-- <p class="subtitle-text" for="name">name</p> -->
                    <input  placeholder="Password" formControlName="password" type="password" class="form-control" id="password" name="password" required autofocus minlength="5" autocomplete="off" [ngClass]="{ 'is-invalid':accountForm.submitted && accountFormGroup.get('password')?.hasError('required') }"
                        aria-describedby="passwordHint">
                    <div *ngIf="accountForm.submitted && accountFormGroup.get('password')?.hasError('required')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="accountFormGroup.get('password')?.hasError('required')" id="passwordHint" class="form-text">password
                            is
                            required</small>
                        <small *ngIf="accountFormGroup.get('password')?.hasError('minlength')" id="passwordHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="accountFormGroup.get('password')?.hasError('password')">Please provide a valid
                            password</small>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <mat-label style="font-size: 14px;">Account Type</mat-label>
                    <mat-select class="form-control" formControlName="type">
                        <mat-option value="dispatch">
                            Dispatch
                        </mat-option>
                        <mat-option value="kitchen">
                            Kitchen
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="col-6">
                <button [disabled]="!accountForm.valid || isLoading" type="submit" style="font-size: 18px; font-family: avenir-roman;" class="btn btn-block" mat-flat-button color="primary">Save</button>
            </div>
            <div class="col-md-12 d-flex justify-content-center mt-2 mb-3">
                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                </mat-spinner>
            </div>
        </div>
    </form>
</div>
