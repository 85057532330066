import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { forkJoin, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { PaymentInfo } from 'src/app/shared/models/payment-info';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { LocalAuthService } from 'src/app/shared/services/local-auth.service';
import { environment } from 'src/environments/environment';
import { Cart } from '../food-item-details/food-item-details.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy, AfterViewInit {

  public isLoading = false;
  public swiperConfig: SwiperConfigInterface = {};
  public cart: Cart = {}
  public paymentInfo: PaymentInfo = {}
  private unSubscriptioNotifier = new Subject();
  public paymentInfoFormGroup: FormGroup;
  public paymentMethodFormControl: FormControl;
  public paymentMeta = {}
  public tab = 1;

  constructor(private router: Router,
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private appMaterialComponent: AppMaterialDesignModule,
    public dataSource: DataService) {

    this.paymentMethodFormControl = new FormControl('Mobile Money', [Validators.required])
  }
  ngAfterViewInit(): void {
    this.navigate(1)
  }


  @ViewChild(SwiperDirective, { static: false })
  swiperDirectiveRef!: SwiperDirective;

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
    dynamicBullets: true,
    type: 'bullets'
  };
  ngOnInit(): void {
    this.dataSource.currentdata
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe(data => {
        this.cart = data;
        if (this.cart.foodItem == undefined) {
          //this.router.navigate(['home'], { queryParams: {}, skipLocationChange: false })
          //return
          this.cart = JSON.parse(localStorage.getItem('cart'))
        }

        this.paymentInfo.amount = this.cart.total_cost
        this.paymentInfo.reference = this.cart.reference
        this.paymentInfo.currency = 'GHS'
        this.paymentInfo.status = 'pending'

        this.paymentMeta = {
          cart_id: this.cart.reference,
          invoice_id: this.cart.reference,
          cancel_action: environment.paystack.cancel_action,
          custom_fields: [{
            display_name: "Customer Name",
            variable_name: "customer_name",
            value: this.user.fullname
          }, {
            display_name: "Order Item",
            variable_name: "order_item",
            value: this.cart.foodItem.name
          },
          {
            display_name: "Order Quantity",
            variable_name: "order_quantity",
            value: this.cart.quantity
          },
          {
            display_name: "Amount Due",
            variable_name: "amount_due",
            value: this.cart.total_cost
          }
          ]
        }

        this.paymentInfoFormGroup = new FormGroup({
          id: new FormControl(this.user.id),
          fullname: new FormControl(this.user.fullname, [Validators.required]),
          email: new FormControl(this.user.email, [Validators.required, Validators.email]),
          mobileNumber: new FormControl({ value: this.cart.orderItem.mobileNumber, disabled: true }, Validators.required)
        });
      });

    this.swiperConfig = {
      a11y: true,
      direction: 'horizontal',
      slidesPerView: 1,
      observer: true,
      threshold: 10,
      speed: 500,
      spaceBetween: 30,
      centeredSlides: true,
      grabCursor: true,
      keyboard: false,
      allowTouchMove: false,
      longSwipes: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: this.pagination,
      roundLengths: true,
    };

  }

  public get user() {
    return this.localAuthService.getAuthUser();
  }

  public onPageIndexChange(index: number) {
    //this.tab = index
  }

  public navigate(index: number) {
    this.swiperDirectiveRef.setIndex(index);
  }

  public paymentInit() {
    console.log('Payment initialized');
  }

  public paymentDone(ref: any) {
    console.log('payment response', ref);
    this.paymentInfo.status = ref.status
    this.paymentInfo.customer_email = this.paymentInfoFormGroup.get('email').value
    this.backend.createPaymentInfo(this.paymentInfo).pipe(
      switchMap((result) => {
        let orderItem = this.cart.orderItem;
        orderItem.status = 'In Progress'
        return this.backend.updateOrder(orderItem)
      }),
      takeUntil(this.unSubscriptioNotifier)
    ).subscribe({
      next: (response) => {
        if (this.paymentInfo.status == 'success') {
          this.tab = 2
          this.navigate(2);
        } else {
          this.tab = 0
          this.navigate(0);
        }

      }
    })
  }

  public clearCart() {
    localStorage.removeItem('cart');
  }

  public paymentCancel() {
    console.log('payment cancel');
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }



}
