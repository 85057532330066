import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { BackendService } from 'src/app/shared/services/backend.service';
import { DataService } from 'src/app/shared/services/data.service';
import { DialogButton } from 'src/app/shared/services/dialog.service';
import { NotificationDialogComponent } from 'src/app/shared/components/notification-dialog/notification-dialog.component';
import { Notification } from 'src/app/shared/models/notification.interface'
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit,OnDestroy {
  private unSubscriptioNotifier = new Subject();
  public isLoading = false;

  public notificationList =new Array<Notification>();
  public notificationList$: Observable<Notification[]> = new Observable<Notification[]>();
  public notificationListBehaviour: BehaviorSubject<Notification[]>;

  constructor(private router: Router,
    private backend: BackendService,
    private firebaseAuth: AngularFireAuth,
    private appMaterialComponent: AppMaterialDesignModule,
    private notification: NotificationService,
    public dataSource: DataService) {
      this.notificationListBehaviour = new BehaviorSubject([])
      this.notificationList$ = this.notificationListBehaviour.asObservable();
    }

  ngOnInit(): void {
    this.getNotifications()
  }

  public openNofiticationDialog(notification: Notification) {
    this.dataSource.setData(notification)
    this.appMaterialComponent.openDialog(NotificationDialogComponent, {
      width: '750px',
      title: 'Add Notification',
      message: 'Add Notification',
      data: notification
    }).pipe(takeUntil(this.unSubscriptioNotifier),
    ).subscribe({
      next: (result) => {
        if (result.button == DialogButton.ok) {
          this.getNotifications() 
        }
      }
    })
  }

  public getNotifications() {
    this.isLoading = true;
    this.backend.getNotifications()
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe(actionArray => {
        this.isLoading = false
        let notificationList = actionArray.map((item: { payload: { doc: { id: any; data: () => Notification; }; }; }) => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data(),
          } as Notification
        })
        this.notificationListBehaviour.next(notificationList);
      })
  }

  public sendNotification(n:Notification){
    return this.notification.sendNotification(n)     
  }

  public signOut() {
    localStorage.setItem('account', null)
    //this.firebaseAuth.signOut().then(r => { });
    this.router.navigate([`account/login`], { queryParams: {}, skipLocationChange: false });
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }

}
