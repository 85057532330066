import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireStorage } from '@angular/fire/storage';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { catchError, combineAll, take } from 'rxjs/operators';
import { Account } from '../models/account.interface';
import { OrderItem } from '../models/order-item';
import { PaymentInfo } from '../models/payment-info';
import { Sales } from '../models/sales.interface';
import { Setting } from '../models/setting';
import { User } from '../models/user.interface';
import { Notification } from '../models/notification.interface'
import { Career } from '../models/career.interface';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':'application/json',
    })
  }

  private url: string = "https://fcm.googleapis.com/fcm/send"
  
  constructor(private httpClient: HttpClient,
    private firestore: AngularFirestore, 
    private storage: AngularFireStorage,
    // private handleError: HttpErrorResponse,
    private fmessaging: AngularFireMessaging) { }

  getDeliveryLocations(): Observable<any> {
    return this.firestore.collection(`locations`, ref => ref.limit(15)).snapshotChanges().pipe(take(1));
  }

  getFoodList(): Observable<any> {
    return this.firestore.collection(`food_store`, ref => ref.limit(15)).snapshotChanges().pipe(take(1));
  }

  getFoodItemImage(filePath: string) {
    const gsReference = this.storage.refFromURL(filePath);
    //const storageRef = this.storage.ref('foods/');
    return gsReference.getDownloadURL();
  }

  getOrderItemList(userId: string): Observable<any> {
    return this.firestore.collection(`orders`, ref => ref.limit(15)
      .where(
        'user_id', '==', userId
      )//.where(
      //   'softDelete', '==', false
      // )
    ).snapshotChanges();
  }

  getOrderItem(orderId: string): Observable<any> {
    return this.firestore.doc(`/orders/${orderId}`).valueChanges();
  }

  createUser(user: User): Observable<any> {
    return from(this.firestore.doc(`/users/${user.id}`).set({ ...user }));
  }

  updateUser(user: User): Observable<any> {
    return from(this.firestore.doc(`/users/${user.id}`).update({ ...user })).pipe(take(1));
  }

  getUser(user_id: string): Observable<any> {
    return this.firestore.doc(`/users/${user_id}`).valueChanges().pipe(take(1));
  }

  getUserByMobileNumber(mobileNumber: string): Observable<any> {
    return this.firestore.collection(`users`, ref => ref.limit(15)
      .where(
        'mobileNumber', '==', mobileNumber
      )
    ).snapshotChanges().pipe(take(1));
  }

  setUserAppSettings(setting: Setting): Promise<any> {
    //console.log('setUserAppSettings')
    return this.firestore.doc(`/settings/${setting.user_id}`).set({ ...setting });
  }

  updateUserAppSettings(setting: Setting): Promise<any> {
    //console.log('setUserAppSettings')
    return this.firestore.doc(`/settings/${setting.user_id}`).update({ ...setting });
  }

  getUserAppSettings(user_id: string): Observable<any> {
    //console.log('hhhrrerer')
    return this.firestore.doc(`/settings/${user_id}`).valueChanges();
  }

  createOrder(orderItem: OrderItem): Observable<any> {
    return from(this.firestore.doc(`/orders/${orderItem.id}`).set({ ...orderItem }));
  }

  updateOrder(orderItem: OrderItem): Promise<any> {
    return this.firestore.doc(`/orders/${orderItem.id}`).update({ ...orderItem });
  }

  deleteOrder(orderItem: OrderItem): Promise<any> {
    return this.firestore.doc(`/orders/${orderItem.id}`).update({
      softDeleted: true
    });
  }

  createPaymentInfo(paymentInfo: PaymentInfo): Observable<any> {
    return from(this.firestore.doc(`/payments/${paymentInfo.reference}`).set({ ...paymentInfo }));
  }

  updatePaymentInfo(paymentInfo: PaymentInfo): Observable<any> {
    return from(this.firestore.doc(`/payments/${paymentInfo.id}`).update({ ...paymentInfo }));
  }

  getPaymentInfo(orderId: string): Observable<any> {
    return this.firestore.doc(`/payments/${orderId}`).valueChanges();
  }

  getAccountByUsername(username: string): Observable<any> {
    return this.firestore.collection(`accounts`, ref => ref.limit(15)
      .where(
        'username', '==', username
      ).where(
        'softDeleted', '==', false
      )
    ).snapshotChanges().pipe(take(1));
  }

  changeAcccountStatus(accountId: string, status: string): Observable<any> {
    return from(this.firestore.doc(`/accounts/${accountId}`).update({
      status: status
    }));
  }

  updateAccount(account: Account): Observable<any> {
    return from(this.firestore.doc(`/accounts/${account.id}`)
      .update({ ...account }));
  }

  createAccount(account: Account): Observable<any> {
    return from(this.firestore.collection(`/accounts`).add({ ...account }));
  }
 // add to the database
  createCareer(career: Career): Observable<any> {
    return from(this.firestore.collection(`/jobs`).add({ ...career }));
  }

  updateOrderStatus(orderId: string, kitchenId: string, status: string): Observable<any> {
    this.firestore.doc(`/orders/${orderId}`).update({
      status: status
    });

    return from(this.firestore.doc(`/kitchen/${kitchenId}/orders/${orderId}`).update({
      status: status
    }));

  }

  getKitchentOrders(kitchenId: string): Observable<any> {
    return this.firestore.collection(`kitchen/${kitchenId}/orders`, ref => ref
      .limit(15)
      .where(
        'status', '!=', 'cancelled'
      )
    )
      .snapshotChanges();
  }

  getDispatchOrders(dispatchRiderId: string): Observable<any> {
    return this.firestore.collection(`dispatch/${dispatchRiderId}/orders`, ref => ref
      .limit(15)
      .where(
        'status', '!=', 'cancelled'
      )
    )
      .snapshotChanges();
  }

  getDispatchedOrders(): Observable<any> {
    return this.firestore.collection(`orders/`, ref => ref
      .limit(15)
      .where(
        'status', '==', 'Dispatched'
      )
    )
      .snapshotChanges();
  }

  getDispatchKitchentOrders(kitchenId: string): Observable<any> {
    return this.firestore.collection(`kitchen/${kitchenId}/orders`, ref => ref
      .limit(15)
      .where(
        'status', '==', 'Dispatched'
      )
    )
      .snapshotChanges();
  }

  updateDispatchOrderStatus(orderId: string, dispatchRiderId: string, status: string): Observable<any> {
    this.firestore.doc(`orders/${orderId}/`).update({
      status: status
    });

    return from(this.firestore.doc(`/dispatch/${dispatchRiderId}/orders/${orderId}`).update({
      status: status
    }));

  }
  
  getAccountListByType(type: string): Observable<any> {
    return this.firestore.collection(`accounts`, ref => ref.limit(15)
      .where(
        'type', '==', type,
      ).where(
        'softDeleted', '!=', true
      )
    ).snapshotChanges().pipe(take(1));
  }

  getAccountList(): Observable<any> {
    return this.firestore.collection(`accounts`, ref => ref.limit(25)
      .where(
        'softDeleted', '!=', true
      )
    ).snapshotChanges().pipe(take(1));
  }

// getting from database to dashboard
  getCareerList(): Observable<any> {
    return this.firestore.collection(`jobs`, ref => ref.limit(25)    
    ).snapshotChanges().pipe(take(1));
  }

  updateCareer(career: Career): Observable<any> {
    return from(this.firestore.doc(`/jobs/${career.id}`).update({ ...career }));
  }

  deleteCareer(career: Career): Observable<any> {
    career.availability = false;
    return this.updateCareer(career)
  }

  deleteAccount(account: Account): Observable<any> {
    // return from(this.firestore.doc(`/accounts/${account.id}`).update({
    //   softDeleted: true
    // }));
    account.softDeleted = true;
    return from(this.firestore.doc(`/accounts/${account.id}`)
      .update({ ...account }));
  }

  assignOrderToDispatch(dispatchRider: any, orderItem: any, kitchenId:string): Observable<any> {
    this.firestore.doc(`/kitchen/${kitchenId}/orders/${orderItem.id}`).update({
      dispatch_rider: dispatchRider.name
    });    

    // this.firestore.doc(`orders/${orderItem.id}/`).update({
    //   dispatch_rider: dispatchRider.name
    // });

    return from(this.firestore.doc(`/dispatch/${dispatchRider.id}/orders/${orderItem.id}`).set({ ...orderItem }));
  }

  setFoodAvailability(food): Observable<any> {
    return from(this.firestore.doc(`/food_store/${food.id}`).update({
      availability: food.availability
    }));
  }

  addFoodMenu(food): Observable<any> {
    return from(this.firestore.collection(`/food_store`).add({ ...food }));
  }

  updateFoodMenu(food): Observable<any> {
    return from(this.firestore.doc(`/food_store/${food.id}`).update({ ...food }));
  }

  getDispatchRiders() {
    return this.getAccountListByType('dispatch');
  }

  getExpensesList(): Observable<any>{
    return this.firestore.collection(`expenses`, ref => ref.limit(15)).snapshotChanges()
  }

  getExpensesListByDuration(startAt: string, endAt: string): Observable<any>{
    return this.firestore.collection(`expenses`, ref => ref.limit(15)
    // .where('date', '>=', startAt)
    .where('date', '>=', endAt))
    .snapshotChanges()
  }

  addExpenses(expenses): Observable<any>{
    return from(this.firestore.collection(`/expenses`).add({...expenses}));
  }
  
  getSalesList(): Observable<any>{
    return this.firestore.collection(`payments`, ref => ref.limit(15)
      .where(
        'status', 'in', ['success','successful']
      ))
    .snapshotChanges()
  }

  getSalesListByDuration(startAt: string, endAt: string): Observable<any>{
    let startAt$ = Number(new Date(startAt))
    let endAt$ = Number(new Date(endAt))

    return this.firestore.collection(`payments`, ref => ref.limit(15)
      .where(
        'status', 'in', ['success','successful']
      )
      .where('date', '>=', startAt$)
      // .where('date', '>=', endAt$)
      )
    .snapshotChanges()
  }

  // TODO: deploy notification to heroku for testing
  sendNotificationToWeb(notification: Notification, tokens: string[]){
    let url = "https://bite-node.herokuapp.com/batch/" //"http://localhost:8080/batch/"
    return this.httpClient.post<Notification>(url, JSON.stringify({notification: notification, tokens: tokens}),this.httpOptions)
    .subscribe(
      (response) => {
        console.log("Successful ", response);
      },
      error => {
        console.log('An error occurred:', error);
      },
      () => {
        console.log("on complete send notification to Web");
      });  
  }

  sendNotificationToAndroid(notification:Notification){
    let  data = {
      message: {       
        notification:{
          body: notification.content,
          title: notification.title,
          image: notification.image,//'https://firebasestorage.googleapis.com/v0/b/bite-89df9.appspot.com/o/foodbanner%2Fjol.png?alt=media&token=58632890-d827-469c-8c0f-50c63adb5a02',
        },
        android: {
          notification: {
            icon: 'stock_ticker_update',
            color: '#155264'
          }
        },
        topic: 'FoodReadyNotice',    
        direct_boot_ok: true       
      }    
    }    
    
  return this.httpClient.post<Notification>(this.url, JSON.stringify(data), this.httpOptions)
  .subscribe(
    (response) => {
      console.log("Successful for Andriod ", response);
    },
    error => {
      console.log('An error occurred for Andriod:', error);
    },
    () => {
      console.log("on complete send notification to Android");
    });  
  }

  sendNotificationToIphone(notification){
    let data = {
      message: {       
        notification:{
          body: notification.content,
          title: notification.title,
          image: notification.image,//'https://firebasestorage.googleapis.com/v0/b/bite-89df9.appspot.com/o/foodbanner%2Fjol.png?alt=media&token=58632890-d827-469c-8c0f-50c63adb5a02',
        },
        android: {
          notification: {
            icon: 'stock_ticker_update',
            color: '#155264'
          }
        },
        topic: 'FoodReadyNotice',    
        direct_boot_ok: true       
      }    
    }
    
  return this.httpClient.post<Notification>(this.url, JSON.stringify(data), this.httpOptions)
  .subscribe(
    (response) => {
      // console.log("Successful ", response);
    },
    error => {
      console.log('An error occurred:', error);
    },
    () => {
      console.log("on complete send notification to iPhone");
    });  
  }

  addNotification(notification):Observable<any> {
    return from(this.firestore.collection(`/notifications/messages/notifications`).add({...notification}));
  }

  getNotifications():Observable<any> {
    return this.firestore.collection(`/notifications/messages/notifications`, ref => ref.limit(15)).snapshotChanges()
  }

  addNotificationToken(userToken):Observable<any> {
    return from(this.firestore.doc(`/notifications/request_tokens/tokens/${userToken.id}`).set({...userToken}));
  }
  
  getNotificationTokens():Observable<any> {
    return this.firestore.collection(`/notifications/request_tokens/tokens`, ref => ref.limit(100)).snapshotChanges()
  }

}
